import { ActionIcon, Box, Button, Divider, Flex, MantineTheme, NumberInput, Select, Text, TextInput, Title } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import FileUploadButton from '../../../../components/buttons/FileButton'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { getSelectPropsForCurrency } from '../../../../components/common/currencyDropdownHelper'
import { IconArrowLeft, IconArrowRight, IconCloseCross, IconDocChecked, IconTickMarkYes, IconViewInfoEye } from '../../../../components/icons'
import { PhoneNumberInput } from '../../../../components/inputs/PhoneNumber'
import { LogoLeikur, LogoSepa } from '../../../../components/logos'
import { Beneficiary as BeneficiaryInterface, IDefaultPaymentDocuments, PaymentType } from '../../../../utils/interfaces'
import useSupportedCurrencyList from '../../../../utils/useSupportedCurrencyList'
import dayjs from 'dayjs'
import { getSelectPropsForCountry } from '../../../../components/inputs/helper'
import { colorAliases } from '../../../../theme/getMantineTheme'

type Props = {
  back: () => void
  next: () => void
  editMode: boolean
  beneficiaryForm: UseFormReturnType<BeneficiaryInterface>
}

const BeneficiaryForm: React.FC<Props> = ({ back, next, editMode, beneficiaryForm }) => {
  const navigate = useNavigate()

  const supportedCurrencyList = useSupportedCurrencyList()

  const handleViewDocument = (documentId: string) => {
    const documentURL = beneficiaryForm.values.default_payment?.documents?.find((document) => document.document_id === documentId)?.document_url

    if (documentURL) {
      window.open(documentURL, '_blank')
    }
  }

  const handleFileChange = (file: File | null) => {
    if (!file) {
      return
    }

    const existingDocuments = beneficiaryForm.values.default_payment.documents || []
    const newDocument: IDefaultPaymentDocuments = {
      document_id: crypto.randomUUID(),
      document_name: `${beneficiaryForm.values?.alias || 'user'}-${file?.name}`,
      document_url: '/dummy/dummy-contract.pdf',
      uploaded_at: dayjs().valueOf(),
    }

    const updatedDocuments = [...existingDocuments, newDocument]
    beneficiaryForm.setFieldValue('default_payment.documents', updatedDocuments)
  }

  const handleRemoveFile = (documentId: string) => {
    const currentDocuments = beneficiaryForm.values.default_payment.documents || []

    const documentToRemove = currentDocuments.find((document) => document.document_id === documentId)
    if (documentToRemove) {
      const updatedDocuments = currentDocuments.filter((document) => document.document_id !== documentId)
      beneficiaryForm.setFieldValue('default_payment.documents', updatedDocuments)
    }
  }

  const checkFormAndProcess = () => {
    beneficiaryForm.validate()
    if (beneficiaryForm.isValid()) {
      if (!beneficiaryForm.values.default_payment.amount || beneficiaryForm.values.default_payment.amount === 0) {
        beneficiaryForm.setFieldValue('default_payment.amount', 100)
      }
      next()
    }
  }

  const readOnlyStyles = (theme: MantineTheme) => ({
    ['input, .mantine-Select-label, .mantine-TextInput-label,	.mantine-NumberInput-label']: editMode ? { color: theme.fn.themeColor('muted.9') } : undefined,
  })

  return (
    <form>
      <CSSGrid gap='xl' columnGap='2xl' pos='relative' templateColumns={{ base: '1fr', desktop: '1fr 1fr' }}>
        <Select
          label='Beneficiary type'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='Select an option'
          description='Is the beneficiary a corporate or an individual?'
          variant='filled'
          readOnly={editMode}
          sx={readOnlyStyles}
          data={[
            { value: 'individual', label: 'Individual' },
            { value: 'corporate', label: 'Corporate' },
          ]}
          {...beneficiaryForm.getInputProps('entity_type')}
        />
        <TextInput
          type='email'
          label='Email address'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='Email address'
          description='Email address linked to their Leikur account'
          variant='filled'
          readOnly={editMode}
          sx={readOnlyStyles}
          {...beneficiaryForm.getInputProps('email')}
        />
        <TextInput
          type='text'
          label='Full name'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='Name'
          description='Enter the name of the beneficiary'
          variant='filled'
          readOnly={editMode}
          sx={readOnlyStyles}
          {...beneficiaryForm.getInputProps('name')}
        />
        <TextInput
          type='text'
          label='Alias'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='Nickname'
          description='Enter a unique alias you will easily remember'
          variant='filled'
          readOnly={editMode}
          sx={readOnlyStyles}
          {...beneficiaryForm.getInputProps('alias')}
        />
        {beneficiaryForm.values.payment_type == PaymentType.SEPA && (
          <Select
            searchable
            label='Country'
            labelProps={{ c: 'secondary', fw: 200 }}
            placeholder='Select country'
            description='Country where you will be sending funds'
            variant='filled'
            readOnly={editMode}
            sx={readOnlyStyles}
            {...getSelectPropsForCountry(beneficiaryForm.values.country)}
            {...beneficiaryForm.getInputProps('country')}
          />
        )}
        <TextInput
          type='text'
          label='IBAN'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='Enter IBAN details'
          description='International bank account number'
          variant='filled'
          readOnly={editMode}
          sx={readOnlyStyles}
          {...beneficiaryForm.getInputProps('account_details.iban')}
        />
        <Select
          searchable
          label='Currency'
          labelProps={{ c: 'secondary', fw: 200 }}
          description='Currency that you will use to pay this beneficiary'
          disabled={editMode ? true : false}
          variant='filled'
          sx={readOnlyStyles}
          {...getSelectPropsForCurrency(supportedCurrencyList, beneficiaryForm.values.currency)}
          {...beneficiaryForm.getInputProps('currency')}
        />
      </CSSGrid>

      <Divider my='xl' size='xs' color='secondary' />
      <Title order={3} fz='sm' color='secondary' mb='xl' fw={500}>
        All the fields below are all optional. You will be able to set or change them when making a transaction.
      </Title>
      <CSSGrid gap='xl' columnGap='2xl' templateColumns={{ base: '1fr', desktop: '1fr 1fr' }}>
        <TextInput
          variant='filled'
          type='text'
          label='Reference'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='A standard reference for this beneficiary'
          description='Reference can be easily updated when you transact'
          {...beneficiaryForm.getInputProps('reference')}
        />

        <NumberInput
          hideControls
          precision={2}
          variant='filled'
          type='text'
          label='Payment Amount'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='100.00 (default)'
          description='Easily change this for each transaction'
          {...beneficiaryForm.getInputProps('default_payment.amount')}
        />

        <TextInput
          variant='filled'
          type='text'
          label='Proof of payment - Email'
          labelProps={{ c: 'secondary', fw: 200 }}
          placeholder='Email address'
          description='An email is sent to this address when you transact'
          {...beneficiaryForm.getInputProps('default_payment.popEmail')}
        />

        <PhoneNumberInput
          label='Proof of payment - Mobile'
          labelProps={{ c: 'secondary', fw: 200 }}
          description='An sms is sent to this number when you transact'
          textInputProps={{ variant: 'filled', placeholder: 'Phone number', fz: 'sm' }}
          selectProps={{ variant: 'filled', placeholder: 'Dialling code', clearable: true }}
          {...beneficiaryForm.getInputProps('default_payment.popMobileNumber')}
        />

        <Box>
          <Text c={colorAliases.textInvert} fw={700} fz='sm' mb='3xs'>
            Supporting documents
          </Text>
          <Flex gap='xs' align='center' mb='2xs'>
            <Flex align='center' justify='center' sx={(theme) => ({ border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`, borderRadius: theme.radius.sm })} p='xs'>
              <Box component={IconDocChecked} c='muted' sx={{ flexShrink: 0 }} />
            </Flex>
            <Text fz='xs' color='textPrimary'>
              Supporting documentation justifying payment (contract, invoice, receipt). If you have a general document, upload it here, otherwise you will be
              able to upload individual documents when you make a transaction.
            </Text>
          </Flex>
          {beneficiaryForm.values.default_payment?.documents?.map((d) => (
            <CSSGrid templateColumns='auto 1fr auto auto' key={d.document_id} mb='2xs' gap='2xs' alignItems='center'>
              <Box component={IconTickMarkYes} h='sm' c='success' ml={2} />
              <Box fz='xs' c='primary'>
                {d.document_name}
              </Box>
              <ActionIcon size='sm' variant='transparent' color='primaryHighlight' onClick={() => handleViewDocument(d.document_id)}>
                <IconViewInfoEye />
              </ActionIcon>
              <ActionIcon size='sm' variant='transparent' color='error' p={3}>
                <IconCloseCross onClick={() => handleRemoveFile(d.document_id)} />
              </ActionIcon>
            </CSSGrid>
          ))}

          <Flex mt='lg'>
            <FileUploadButton handleFileChange={handleFileChange} />
          </Flex>
        </Box>
        <Flex ta='center' fz={{ base: '3rem', desktop: '5rem' }} align='center' justify='center'>
          {beneficiaryForm.values.payment_type == PaymentType.SEPA ? <LogoSepa style={{ height: '1em' }} /> : <LogoLeikur style={{ height: '1em' }} />}
        </Flex>
      </CSSGrid>
      <Flex justify={{ base: 'space-between', desktop: 'end' }} align='center' mt='xl' gap='sm'>
        {!editMode && (
          <Button
            w={125}
            radius='xs'
            variant='filled'
            leftIcon={<IconArrowLeft style={{ height: '1.3em', width: '1.3em', color: 'white' }} />}
            color='secondary'
            onClick={back}
          >
            Back
          </Button>
        )}

        {/* <IconButton leftIcon={<IconHelpQuestion />} size='sm' color='muted' variant='borderless'>
            <IconButton.Title fz='xs'>
              Need
              <br />
              Help?
            </IconButton.Title>
          </IconButton> */}
        <Button
          w={125}
          radius='xs'
          rightIcon={<IconCloseCross style={{ height: '0.75rem', width: '0.75rem' }} />}
          variant='outline'
          color={colorAliases.borderSubtle}
          c={colorAliases.textInvert}
          onClick={() => navigate('/beneficiary-centre')}
        >
          Cancel
        </Button>

        <Button
          w={125}
          radius='xs'
          rightIcon={<IconArrowRight style={{ height: '1.3em', width: '1.3em', color: 'white' }} />}
          variant='filled'
          color='primary'
          onClick={() => checkFormAndProcess()}
        >
          Continue
        </Button>
      </Flex>
    </form>
  )
}

export default BeneficiaryForm
