import { Button, ButtonProps, MantineColor, MantineSize, Text, TextProps } from '@mantine/core';
import React, { CSSProperties, forwardRef } from 'react';
import { ForwardRefWithStaticComponents } from '../../../ForwardRefWithStaticComponents';
import useStyles from './IconButton.styles';

export interface IconButtonProps extends Omit<ButtonProps & React.ComponentPropsWithoutRef<'button'>, 'variant'> {
  variant?: 'outline' | 'borderless';
  size?: MantineSize;
  textPadding?: CSSProperties['padding'];
  textColor?: MantineColor;
  leftIconColor?: MantineColor;
  rightIconColor?: MantineColor;
  animation?: 'simple' | 'fancy';
}

export const Title = ({ children, ...rest }: TextProps) => {
  return (
    <Text className={`stream-icon-button-title ${rest.className}`} {...rest}>
      {children}
    </Text>
  );
};

export const Description = ({ children, ...rest }: TextProps) => {
  return (
    <Text className={`stream-icon-button-description ${rest.className}`} {...rest}>
      {children}
    </Text>
  );
};

type IconButtonComponent = ForwardRefWithStaticComponents<
  IconButtonProps,
  {
    Title: typeof Title;
    Description: typeof Description;
  }
>;

export const IconButton: IconButtonComponent = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      classNames,
      textPadding,
      textColor,
      rightIconColor,
      leftIconColor,
      animation,
      variant = 'outline',
      size = 'md',
      ...props
    }: IconButtonProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        textPadding,
        textColor,
        rightIconColor,
        leftIconColor,
        variant,
        size,
        children,
        animation: animation !== undefined ? animation : variant === 'borderless' ? 'fancy' : 'simple',
      },
      { classNames, name: 'custom' }
    );

    return (
      <Button classNames={classes} variant={'outline'} size={size} {...props} ref={ref}>
        {children}
      </Button>
    );
  }
) as any;

IconButton.Title = Title;
IconButton.Description = Description;

// export const IconButton = createPolymorphicComponent<
//   'button',
//   IconButtonProps,
//   { Title: typeof Title; Description: typeof Description }
// >(_IconButton);
