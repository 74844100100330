import { Checkbox as MantineCheckbox, CheckboxProps as MantineCheckboxProps, MantineNumberSize, MantineSize } from '@mantine/core';
import React, { forwardRef } from 'react';
import { ForwardRefWithStaticComponents } from '../../ForwardRefWithStaticComponents';
import useStyles from './Checkbox.styles';

export interface CheckboxProps extends MantineCheckboxProps {
  /** Controls checkbox appearance, defaults to filled */
  variant?: 'outline' | 'filled';
  /** Predefined checkbox width and height  */
  boxSize?: MantineSize;
  /** Space between box and label from theme.spacing or a number in px*/
  spacing?: MantineNumberSize;
}

type CheckboxComponent = ForwardRefWithStaticComponents<CheckboxProps, { Group: typeof MantineCheckbox.Group }>;

export const Checkbox: CheckboxComponent = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, classNames, boxSize, spacing, variant = 'outline', ...props }: CheckboxProps, ref) => {
    const { classes } = useStyles(
      {
        ...props,
        boxSize,
        spacing,
        variant,
        children,
      },
      { classNames, name: 'custom' }
    );

    return <MantineCheckbox classNames={classes} {...props} ref={ref} />;
  }
) as any;

Checkbox.Group = MantineCheckbox.Group;
