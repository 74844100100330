import { createStyles, getSize, getStylesRef } from '@mantine/core';
import { setTransparency } from '../../../utils/theme';
import { VerticalButtonProps } from './VerticalButton';
import { BUTTON_INPUT_SIZES } from '../buttonSizes';

export default createStyles((theme, params: VerticalButtonProps) => {
  const color = theme.fn.themeColor(params.color as string);
  const textColor = theme.fn.themeColor(params.textColor || (params.color as string));
  const iconColor = theme.fn.themeColor(params.iconColor || params.textColor || (params.color as string));

  const width = `calc(${(BUTTON_INPUT_SIZES as Record<string, string>)[params.size || 'md']} + 0.125rem)`;

  return {
    root: {
      '*,*::before': {
        boxSizing: 'border-box',
      },

      padding: '0.1875rem',
      height: 'auto',
      minHeight: width,
      aspectRatio: params.isSquare ? '1/1' : undefined,
      width: width,
      borderWidth: '2pt',
      transition: 'box-shadow 0.1s ease 0s',

      ':hover': {
        borderColor: setTransparency(theme, params.color, params.loading ? 0.5 : 1),
        boxShadow: `0 0 0.375rem ${color}`,
        backgroundColor: 'transparent',

        [`.${getStylesRef('label')}`]: {
          color: theme.fn.lighten(textColor || color, 0.1),
        },
        [`.${getStylesRef('icon')}`]: {
          color: theme.fn.lighten(iconColor || color, 0.1),
        },
      },
      ':active': {
        boxShadow: `0 0 0.375rem transparent`,
        borderColor: theme.fn.darken(color, 0.1),
        [`.${getStylesRef('label')}`]: {
          color: theme.fn.darken(textColor || color, 0.1),
        },
        [`.${getStylesRef('icon')}`]: {
          color: theme.fn.darken(iconColor || color, 0.1),
        },
      },
    },
    inner: {
      display: 'grid',
      justifyItems: 'center',
      alignContent: 'start',
      gridTemplateRows: 'auto min-content',
      gap: '0.1875rem',
    },
    icon: {
      ref: getStylesRef('icon'),
      padding: params.iconPadding || '0 0.1875rem',
      marginRight: 0,
      justifyContent: 'center',
      color: iconColor,
      svg: {
        height: '100%',
        width: '100%',
        fill: 'currentcolor',
      },
    },
    leftIcon: {
      marginRight: 0,
    },
    label: {
      ref: getStylesRef('label'),
      color: params.textColor !== undefined ? textColor : undefined,
      fontWeight: 400,
      fontSize: `calc(${getSize({
        size: params.size || 'md',
        sizes: theme.fontSizes,
      })} * 0.8)`,
      display: params.children !== undefined && params.children !== '' ? undefined : 'none',
      paddingBottom: 1,
    },
  };
});
