import { Center, Loader } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { RouterProvider } from 'react-router-dom'
import modals, { modalProps } from './components/modals'
import { AuthContext } from './contexts'
import browserRouter from './routes/browserRouter'
import useAuthManager from './utils/useAuthManager'

const App = () => {
  const [token, setToken, clearToken] = useAuthManager()

  return (
    <AuthContext.Provider value={{ token, setToken, clearToken }}>
      <ModalsProvider modals={modals} modalProps={modalProps}>
        <RouterProvider
          router={browserRouter}
          fallbackElement={
            <Center p='xl'>
              <Loader />{' '}
            </Center>
          }
        />
      </ModalsProvider>
    </AuthContext.Provider>
  )
}

export default App
