import { Chip } from '@innostream/stream-ui'
import { Button, Checkbox, Divider, Flex, Group, Loader, NumberInput, Paper, Text, TextInput, Title } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { useSetState } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import useFetchTransactions from '../../../../api/queries/useFetchTransactions'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconCloseCross, IconSaveExport, IconSearch } from '../../../../components/icons'
import { INTERNAL_TRANSFER_REFERENCE_SUFFIX } from '../../../../utils/constants'
import { AccountGroup, Transaction, TransactionFilters } from '../../../../utils/interfaces'
import { downloadCSV } from '../../../../utils/jsToCSV'
import Timeline from './Timeline'
import { colorAliases } from '../../../../theme/getMantineTheme'

const transactionFilterValues = ['all', 'send', 'receive', 'internal']

const matchesSelectedTransactionType = (t: Transaction, selectedTransactionTypeFilter: typeof transactionFilterValues) =>
  selectedTransactionTypeFilter.includes('all') ||
  (selectedTransactionTypeFilter.includes('internal') && t.reference.indexOf(INTERNAL_TRANSFER_REFERENCE_SUFFIX) > -1) ||
  (selectedTransactionTypeFilter.includes('send') && t.amount < 0) ||
  (selectedTransactionTypeFilter.includes('receive') && t.amount > 0)

export const TransactionsArea: React.FC<{
  group: AccountGroup
  selectedAccountId?: string
}> = ({ group, selectedAccountId, ...props }) => {
  const [filterValues, setFilterValues] = useState<TransactionFilters>()

  // Note: Filtering occurs here when passing down the filter values to the fetch function
  const { isLoading, data: allTransactions } = useFetchTransactions(filterValues)

  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [selectedTransactionTypeFilter, _setSelectedTransactionTypeFilter] = useState(transactionFilterValues)

  const [filtersVisibility, setFiltersVisibility] = useSetState({ date: false, amount: false })

  const filterForm = useForm<TransactionFilters & { _fromDate?: Date | null; _toDate?: Date | null }>({
    initialValues: {
      _fromDate: null,
      _toDate: null,
      fromAmount: '',
      toAmount: '',
      searchString: '',
    },
    transformValues: (values) => ({
      ...values,
      fromDate: dayjs(values._fromDate)?.format('YYYY-MM-DD'),
      toDate: dayjs(values._toDate)?.format('YYYY-MM-DD'),
    }),
    validate: {
      _fromDate: (value, values) => {
        if (!filtersVisibility.date) return null

        if (values._toDate !== undefined && values._toDate !== null && value === null) {
          return 'Please enter a valid "From date"'
        }

        if (values._toDate !== undefined && values._toDate !== null && dayjs(value).isAfter(dayjs(values._toDate, 'D'))) {
          return 'Please enter a date that is before "To date"'
        }
      },
      _toDate: (value, values) => {
        if (!filtersVisibility.date) return null

        if (values._fromDate !== undefined && values._fromDate !== null && value === null) {
          return 'Please enter a valid "To date"'
        }
      },
      fromAmount: (value) => {
        if (value && typeof value === 'number') {
          if (value < 1) {
            return 'Please enter a value that is greater than zero'
          }
        }
      },
      toAmount: (value, values) => {
        if (value && typeof value === 'number') {
          if (value < 1) {
            return 'Please enter a value that is greater than zero'
          }
          if (values.fromAmount && typeof values.fromAmount === 'number') {
            if (values.fromAmount > value) {
              return 'Please enter a value that is greater or equal to "From amount"'
            }
          }
        }
      },
    },
    validateInputOnBlur: true,
  })

  const setSelectedTransactionTypeFilter = (valueFromChips: string[]) => {
    return _setSelectedTransactionTypeFilter((previousValue) => {
      if (!valueFromChips.includes('all')) {
        if (valueFromChips.length === transactionFilterValues.length - 1) {
          return ['all', ...valueFromChips]
        }
      }
      if (previousValue.includes('all') && valueFromChips.includes('all')) {
        if (valueFromChips.length !== transactionFilterValues.length) {
          return valueFromChips.filter((v) => v !== 'all')
        }
      }
      if (previousValue.includes('all') && !valueFromChips.includes('all')) {
        return []
      }
      if (!previousValue.includes('all') && valueFromChips.includes('all')) {
        return transactionFilterValues
      }

      return valueFromChips
    })
  }

  useEffect(() => {
    if (allTransactions) {
      if (selectedAccountId) {
        setTransactions(allTransactions.filter((t) => t.account_id === selectedAccountId && matchesSelectedTransactionType(t, selectedTransactionTypeFilter)))
      } else {
        setTransactions(
          allTransactions.filter(
            (t) => group.accounts.map((acc) => acc.id).includes(t.account_id) && matchesSelectedTransactionType(t, selectedTransactionTypeFilter)
          )
        )
      }
    }
  }, [allTransactions, group, selectedAccountId, selectedTransactionTypeFilter])

  const inputStyles = {
    input: {
      borderColor: colorAliases.borderSubtle,
    },
    label: { color: colorAliases.textInvertSecondary },
  }

  const filter = () => {
    filterForm.validate()
    if (filterForm.isValid()) {
      const values = filterForm.getTransformedValues()

      setFilterValues({
        searchString: values.searchString,
        fromDate: filtersVisibility.date ? values.fromDate : undefined,
        toDate: filtersVisibility.date ? values.toDate : undefined,
        fromAmount: filtersVisibility.amount ? values.fromAmount : undefined,
        toAmount: filtersVisibility.amount ? values.toAmount : undefined,
      })
    }
  }

  const clearFilters = () => {
    setFilterValues(undefined)
    filterForm.setValues({
      _fromDate: null,
      _toDate: null,
      fromAmount: '',
      toAmount: '',
      searchString: '',
    })
  }

  const exportTransactions = () => {
    if (transactions && transactions.length > 0) {
      modals.openConfirmModal({
        radius: 'md',
        padding: 'md',
        title: 'Statement export',
        children: <Text size='sm'>Please confirm you want to export the transactions shown.</Text>,
        labels: { confirm: 'Download', cancel: 'Cancel' },
        confirmProps: {
          color: 'primary',
          radius: 'xs',
          leftIcon: <IconSaveExport style={{ height: '1.5em' }} />,
        },
        cancelProps: { radius: 'xs', color: 'error', variant: 'outline', leftIcon: <IconCloseCross style={{ width: '0.75em', marginTop: '1px' }} /> },
        onConfirm: () =>
          downloadCSV(
            transactions.map((tx) => ({
              date: tx.created_at,
              reference: tx.reference,
              beneficiary: tx.counterparty.account_holder_name,
              amount: tx.amount,
              currency: tx.currency,
              iban: tx.counterparty.iban,
              transaction_type: tx.type,
            })),
            { filename: group.alias + '-transactions-' + dayjs().format('YYYY-MM-DD') }
          ),
      })
    } else {
      modals.open({
        radius: 'md',
        padding: 'lg',
        title: 'Statement export',
        children: (
          <Text size='sm' c='textPrimary'>
            No transactions to export.
          </Text>
        ),
      })
    }
  }
  return (
    <Paper p='xl' {...props}>
      <Flex justify='space-between' align='center'>
        <Title order={3} fw={300} fz={{ base: 'xl' }}>
          Transactions
        </Title>
        <Button
          radius='xs'
          color='primary'
          variant='subtle'
          leftIcon={<IconSaveExport style={{ height: '1.5em', width: '1.5em' }} />}
          onClick={exportTransactions} fw={200}>
          Save/Export
        </Button>
      </Flex>
      <Divider color={colorAliases.borderHighlight} size='xs' mt='0' />

      <CSSGrid gap='xs'>
        <CSSGrid gap='sm' templateColumns={{ base: '1fr', desktop: '1fr auto auto' }} alignItems='end'>
          <TextInput
            labelProps={{ my: 'lg', fw: 200, c: colorAliases.textInvert }}
            label='Filter transactions'
            type='text'
            rightSection={<IconSearch style={{ height: '1em' }} />}
            placeholder='Search by beneficiary or reference...'
            {...filterForm.getInputProps('searchString')}
          />
          <Flex gap='sm' align='center' justify='start' h={36}>
            <Checkbox
              color='primary.3'
              checked={filtersVisibility.date}
              onChange={() => {
                setFiltersVisibility({ date: !filtersVisibility.date })
                filter()
              }}
              size='md'
              label={
                <Text c={colorAliases.brandGray} fw={200} lh='1' ml={-4} fz='sm'>
                  By date
                </Text>
              }
              styles={{ body: { alignItems: 'center' } }}
            />
            <Checkbox
              color='primary.3'
              checked={filtersVisibility.amount}
              onChange={() => {
                setFiltersVisibility({ amount: !filtersVisibility.amount })
                filter()
              }}
              size='md'
              label={
                <Text c={colorAliases.brandGray} fw={200} ml={-4} fz='sm'>
                  By amount
                </Text>
              }
              styles={{ body: { alignItems: 'center' } }}
            />
          </Flex>
        </CSSGrid>

        {filtersVisibility.date && (
          <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gap='sm' alignItems='start'>
            <DatePickerInput
              label='From date'
              placeholder={'E.g. 25 September 2023'}
              {...filterForm.getInputProps('_fromDate')}
              styles={inputStyles}
              valueFormat='D MMMM YYYY'
              clearable
              excludeDate={(d) => dayjs(d).isAfter(dayjs())}
            />
            <DatePickerInput
              label='To date'
              placeholder={'E.g. 25 October 2023'}
              {...filterForm.getInputProps('_toDate')}
              styles={inputStyles}
              valueFormat='D MMMM YYYY'
              clearable
              excludeDate={(d) => dayjs(d).isAfter(dayjs())}
            />
          </CSSGrid>
        )}

        {filtersVisibility.amount && (
          <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gap='sm' alignItems='start'>
            <NumberInput
              label='From amount'
              placeholder='E.g. 2000'
              styles={inputStyles}
              hideControls
              precision={2}
              {...filterForm.getInputProps('fromAmount')}
            />
            <NumberInput label='To amount' placeholder='E.g. 20000' styles={inputStyles} hideControls precision={2} {...filterForm.getInputProps('toAmount')} />
          </CSSGrid>
        )}
      </CSSGrid>
      <Divider color={colorAliases.borderSubtle} size='xs' my='md' />
      <Flex align='center' gap='sm' justify={{ desktop: 'space-between' }} direction={{ base: 'column', desktop: 'row' }}>
        <Chip.Group multiple={true} value={selectedTransactionTypeFilter} onChange={setSelectedTransactionTypeFilter}>
          <Group spacing='3xs'>
            <Chip value='all' radius='xs' size='sm' fw={200} color='primary'>
              All
            </Chip>
            <Chip value='send' radius='xs' size='sm' fw={200} color='primary'>
              Send
            </Chip>
            <Chip value='receive' radius='xs' size='sm' fw={200} color='primary'>
              Receive
            </Chip>
            <Chip value='internal' radius='xs' size='sm' fw={200} color='primary'>
              Internal
            </Chip>
          </Group>
        </Chip.Group>
        <Flex gap='sm' align='center' h={36}>
          <Button variant='outline' c={colorAliases.textInvert} px='md' h='2.1rem' color='transparentBlack.3' leftIcon={<IconCloseCross style={{ height: '0.9em' }} />} radius='xs' size='xs' onClick={clearFilters}>
            Clear filters
          </Button>
          <Button variant='outline' c={colorAliases.textInvert} px='md' h='2.1rem' color='transparentBlack.3' leftIcon={<IconSearch style={{ height: '1em' }} />} radius='xs' size='xs' onClick={filter}>
            Filter transactions
          </Button>
        </Flex>
      </Flex>
      <Divider color='textPrimary.4' size='sm' my='sm' />
      {!isLoading && <Timeline transactions={transactions} groupThemeAccent='textPrimary.4' />}
      {!isLoading && transactions?.length === 0 && (
        <Flex align='center' justify='center' c='textPrimary' p='md' fs='italic'>
          No transactions found.
        </Flex>
      )}
      {isLoading && (
        <Flex align='center' justify='center'>
          <Loader color='textPrimary.4' />
        </Flex>
      )}
    </Paper>
  )
}
