import { useLocalStorage } from '@mantine/hooks'

/** Hook that manages user authentication, and exposes current
 * authentication state, as well as function(s) to manage this
 * state. Typically used by top-level components that wish to
 * manage an authentication context - i.e. usually at the top-level
 * of the app. The return value is an object suitable to use as the
 * value for a `AuthContext.Provider`.
 */

export default () => {
  // For authentication, we store / retrieve simple JWT (token)
  // in both localstorage and cookies. Changes to these are tracked
  // in top-level application state though
  //

  const [token, setToken, removeToken] = useLocalStorage({ key: 'leikur-token', getInitialValueInEffect: false })

  const storeToken = (jwt: string) => {
    setToken(jwt)
  }

  const clearToken = () => {
    setToken('')
    removeToken()
  }

  return [token, storeToken, clearToken] as [string, (jwt: string) => void, () => void]
}
