import { Currency } from './interfaces'

export const mocked_exchange_rates: { [key in Currency]: number } = {
  EUR: 1,
  GBP: 0.86773394,
  USD: 1.0644317,
  SEK: 11.893857,
  CAD: 1.4333241,
  JPY: 157.85774,
  PLN: 4.6107812,
  CZK: 24.374615,
  AUD: 1.6548927,
  DKK: 7.4552963,
  NOK: 11.472182,
  HUF: 386.77514,
  RON: 4.9708446,
  SGD: 1.4534333,
  HKD: 8.3229741,
  CHF: 0.96399855
}

export interface ExchangeInfo {
  fromAmount: number
  fromCurrency: Currency
  toAmount: number
  toCurrency: Currency
  exchangeRate: number
}

function calculateExchangeRate(fromCurrency: Currency, toCurrency: Currency): number {
  const fromRate = fromCurrency !== Currency.EUR ? 1 / mocked_exchange_rates[fromCurrency] : 1
  return fromRate * mocked_exchange_rates[toCurrency]
}

// trying to get around rounding to 2 decimal places issues
// parseFloat(parseFloat("1.005").toFixed(2))  ----> 1
// vs
// +parseFloat(`${Math.round(parseFloat(`${1.005}e+2`))}e-2`) -----> 1.01
// caveat: won't work if number has 'e'
function roundToTwoDecimalPlaces(number: number): number {
  return +parseFloat(`${Math.round(parseFloat(`${number}e+2`))}e-2`)
}

export function getExchangeInfo({ amount, fromCurrency, toCurrency }: {
  amount: number
  fromCurrency: Currency
  toCurrency: Currency
}): ExchangeInfo {

  const exchangeRate = calculateExchangeRate(fromCurrency, toCurrency)
  const convertedAmount = roundToTwoDecimalPlaces(amount * exchangeRate)

  return {
    fromAmount: amount,
    fromCurrency,
    toAmount: convertedAmount,
    exchangeRate,
    toCurrency
  }
}

