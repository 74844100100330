import { Currencies } from './interfaces'

const currencies: Currencies = {
  EUR: {
    name: 'Euro',
    symbol: '€',
    regionName: 'Euro',
    countryCodeForFlag: 'eu',
  },
  GBP: {
    name: 'Sterling pound',
    symbol: '£',
    regionName: 'United Kingdom',
    countryCodeForFlag: 'gb',
  },
  USD: {
    name: 'American dollar',
    symbol: '$',
    regionName: 'United States',
    countryCodeForFlag: 'us',
  },
  SEK: {
    name: 'Swedish krone',
    symbol: 'kr',
    regionName: 'Sweden',
    countryCodeForFlag: 'se',
  },
  CAD: {
    name: 'Canadian dollar',
    symbol: '$',
    regionName: 'Canada',
    countryCodeForFlag: 'ca',
  },
  JPY: {
    name: 'Japanese yen',
    symbol: '¥',
    regionName: 'Japan',
    countryCodeForFlag: 'jp',
  },
  PLN: {
    name: 'Zloty',
    symbol: 'zł',
    regionName: 'Poland',
    countryCodeForFlag: 'pl',
  },
  CZK: {
    name: 'Czech krone',
    symbol: 'Kč',
    regionName: 'Czechia',
    countryCodeForFlag: 'cz',
  },
  AUD: {
    name: 'Australian dollar',
    symbol: '$',
    regionName: 'Australia',
    countryCodeForFlag: 'au',
  },
  DKK: {
    name: 'Danish krone',
    symbol: 'kr',
    regionName: 'Denmark',
    countryCodeForFlag: 'dk',
  },
  NOK: {
    name: 'Norwegian krone',
    symbol: 'kr',
    regionName: 'Norway',
    countryCodeForFlag: 'no',
  },
  HUF: {
    name: 'Hungarian forint',
    symbol: 'Ft',
    regionName: 'Hungary',
    countryCodeForFlag: 'hu',
  },
  RON: {
    name: 'Romanian leu',
    symbol: 'lei',
    regionName: 'Romania',
    countryCodeForFlag: 'ro',
  },
  SGD: {
    name: 'Singapore dollar',
    symbol: '$',
    regionName: 'Singapore',
    countryCodeForFlag: 'sg',
  },
  HKD: {
    name: 'Hong Kong dollar',
    symbol: '$',
    regionName: 'Hong Kong',
    countryCodeForFlag: 'hk',
  },
  CHF: {
    name: 'Swiss franc',
    symbol: 'Fr',
    regionName: 'Switzerland',
    countryCodeForFlag: 'ch',
  },
}
export default currencies

export const currencyFormat = new Intl.NumberFormat(new Intl.NumberFormat().resolvedOptions().locale, {
  minimumFractionDigits: 2,
})
