import { Country, Flag, keyedCountries } from '@innostream/stream-ui'
import { Box, CloseButton, Group, MultiSelectProps, MultiSelectValueProps, SelectItem, SelectProps, Text, getSize } from '@mantine/core'
import React, { forwardRef } from 'react'

const countries: readonly Country[] = Object.values(keyedCountries)

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  selectItemLabel: string
  countryCode: string
  searchLabel: string
}

const MemoizedSelectItemInner = React.memo(((props) => (
  <Group noWrap spacing={props.spacing || 'md'}>
    <Flag countryCode={props.countryCode} style={{ borderRadius: 4 }} />
    <Text size='sm'>{props.selectItemLabel}</Text>
  </Group>
)) as React.FC<{ spacing?: string; countryCode: string; selectItemLabel: string }>)
MemoizedSelectItemInner.displayName = 'SelectItemInner'

const CountrySelectItem = forwardRef<HTMLDivElement, ItemProps>(({ countryCode, selectItemLabel, ...others }: ItemProps, ref) => {
  return (
    <div ref={ref} {...others}>
      <MemoizedSelectItemInner countryCode={countryCode as never} selectItemLabel={selectItemLabel as never} />
    </div>
  )
})
CountrySelectItem.displayName = 'CountrySelectItem'

const CountrySelectValue = ({ value, label, onRemove, size, disabled, readOnly, ...others }: MultiSelectValueProps & { value: string }) => {
  return (
    <div className={others.className}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          // cursor: 'default',
          alignItems: 'center',
          // paddingLeft: 10,
          borderRadius: 4,
          backgroundColor: disabled
            ? theme.colorScheme === 'dark'
              ? theme.colors.dark[5]
              : theme.colors.gray[3]
            : theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
          color: disabled
            ? theme.colorScheme === 'dark'
              ? theme.colors.dark[1]
              : theme.colors.gray[7]
            : theme.colorScheme === 'dark'
            ? theme.colors.dark[0]
            : theme.colors.gray[7],
          paddingLeft: `calc(${getSize({ size: size, sizes: theme.spacing })} - 0.375rem)`,
          paddingRight: disabled || readOnly ? getSize({ size: size, sizes: theme.spacing }) : '0',
          height: '100%',
          fontWeight: 500,
          fontSize: `calc(${getSize({ size: size, sizes: theme.fontSizes })} - 0.125rem)`,
          cursor: disabled ? 'not-allowed' : 'default',
          userSelect: 'none',
        })}
      >
        <Flag countryCode={value} style={{ borderRadius: 4 }} />
        <Box sx={{ lineHeight: 1, paddingLeft: 8 }}>{label}</Box>
        <CloseButton onMouseDown={onRemove} variant='transparent' size={22} iconSize={14} tabIndex={-1} />
      </Box>
    </div>
  )
}

const countryCodesAsData = countries.reduce<SelectItem[]>(
  (acc, country) => [
    ...acc,
    {
      value: country.alpha2code,
      label: country.name,
      countryCode: country.alpha2code,
      selectItemLabel: country.name,
      searchLabel: `${country.name} ${country.alpha2code}`,
    },
  ],
  []
)

const countryDiallingCodeAsData = countries.reduce<SelectItem[]>(
  (acc, country) => [
    ...acc,
    {
      value: country.alpha2code,
      label: `${country.alpha2code} ${country.phoneCode}`,
      countryCode: country.alpha2code,
      selectItemLabel: `${country.name} ${country.phoneCode}`,
      searchLabel: `${country.name} ${country.alpha2code} ${country.phoneCode}`,
    },
  ],
  []
)

export const getSelectPropsForCountry = (currentVal?: string | null, filter?: (item: SelectItem) => boolean) =>
  ({
    filter: (value, item) =>
      item.searchLabel !== undefined && typeof item.searchLabel == 'string' && item.searchLabel.toLowerCase().includes(value.toLowerCase().trim()),
    data: filter && typeof filter === 'function' ? countryCodesAsData.filter(filter) : countryCodesAsData,
    itemComponent: CountrySelectItem,
    icon: currentVal !== undefined && currentVal !== null ? <Flag countryCode={currentVal} style={{ borderRadius: 4 }} /> : undefined,
  } as SelectProps)

export const getMultiSelectPropsForCountry = (filter?: (item: SelectItem) => boolean) =>
  ({
    filter: (value, selected, item) =>
      !selected && item.searchLabel !== undefined && typeof item.searchLabel == 'string' && item.searchLabel.toLowerCase().includes(value.toLowerCase().trim()),
    data: filter && typeof filter === 'function' ? countryCodesAsData.filter(filter) : countryCodesAsData,
    itemComponent: CountrySelectItem,
    valueComponent: CountrySelectValue,
  } as MultiSelectProps)

export const getSelectPropsForCountryDiallingCode = (currentVal?: string | null, filter?: (item: SelectItem) => boolean) =>
  ({
    filter: (value, item) =>
      item.searchLabel !== undefined && typeof item.searchLabel == 'string' && item.searchLabel.toLowerCase().includes(value.toLowerCase().trim()),
    data: filter && typeof filter === 'function' ? countryDiallingCodeAsData.filter(filter) : countryDiallingCodeAsData,
    itemComponent: CountrySelectItem,
    icon: currentVal !== undefined && currentVal !== null ? <Flag countryCode={currentVal} style={{ borderRadius: 4 }} /> : undefined,
  } as SelectProps)
