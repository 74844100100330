import { Button as MantineButton, ButtonProps as MantineButtonProps, MantineColor, MantineNumberSize, MantineSize, Variants } from '@mantine/core';
import React, { CSSProperties, forwardRef } from 'react';
import useStyles from './Button.styles';

export interface ButtonProps extends Omit<MantineButtonProps & React.ComponentPropsWithoutRef<'button'>, 'variant'> {
  radius?: MantineNumberSize;
  size?: MantineSize;
  variant?: Variants<'filled' | 'outline' | 'light' | 'white' | 'default' | 'subtle' | 'gradient' | 'borderless'>;
  textPadding?: CSSProperties['padding'];
  textColor?: MantineColor;
  leftIconColor?: MantineColor;
  rightIconColor?: MantineColor;
  borderColor?: MantineColor;
}

/**
 * The `Button` supports most cases for buttons.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      classNames,
      textPadding,
      textColor,
      rightIconColor,
      leftIconColor,
      borderColor,
      variant = 'outline',
      size = 'sm',
      ...props
    }: ButtonProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        textColor,
        textPadding,
        rightIconColor,
        leftIconColor,
        borderColor,
        variant,
        size,
        children,
      },
      { classNames, name: 'custom' }
    );

    const mantineVariant = variant === 'borderless' ? 'outline' : variant;

    return (
      <MantineButton ref={ref} classNames={{ ...classNames, ...classes }} variant={mantineVariant} size={size} {...props}>
        {children}
      </MantineButton>
    );
  }
);
