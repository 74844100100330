import { TextInput as MantineTextInput, TextInputProps as MantineTextInputProps } from '@mantine/core';
import React, { forwardRef } from 'react';

import useStyles from '../Input.styles';
import { SharedInputProps } from '../SharedInputProps';

export interface TextInputProps extends MantineTextInputProps, SharedInputProps {}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      classNames,
      size = 'md',
      variant = 'default',
      inputWrapperOrder = ['label', 'input', 'description', 'error'],
      borderColor,
      inputColor,
      labelColor,
      labelFontSize,
      labelFontWeight,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
      ...props
    }: TextInputProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        variant,
        size,
        borderColor,
        inputColor,
        labelColor,
        labelFontSize,
        labelFontWeight,
        errorColor,
        descriptionColor,
        otherFontSize,
        otherFontWeight,
      },
      { classNames, name: 'custom' }
    );
    return (
      <MantineTextInput
        ref={ref}
        variant={variant}
        size={size}
        inputWrapperOrder={inputWrapperOrder}
        classNames={classes}
        pattern={props.pattern}
        type={props.type}
        {...props}
      />
    );
  }
);
