import { useEffect, useState } from 'react'
import { AccountGroup } from '../../utils/interfaces'
import useFetchAccountGroupInfoList from './useFetchAccountGroupInfoList'
import useFetchAccounts from './useFetchAccounts'

export default function useFetchAccountGroupsByIBAN() {
  const [accountGroupsByIBAN, setAccountGroupsByIBAN] = useState<{ [key: string]: AccountGroup }>()

  const { isLoading: isLoadingAccounts, data: accounts } = useFetchAccounts()
  const { isLoading: isLoadingGroupInfoList, data: groupInfoList } = useFetchAccountGroupInfoList()

  const isLoading = isLoadingAccounts || isLoadingGroupInfoList

  useEffect(() => {
    if (groupInfoList && accounts) {
      const updatedAccountGroupsByIBAN = groupInfoList.reduce((map, groupInfo) => {
        map[groupInfo.iban] = {
          ...groupInfo,
          accounts: accounts.filter((a) => {
            return a.iban === groupInfo.iban
          }),
        }
        return map
      }, {} as { [key: string]: AccountGroup })

      setAccountGroupsByIBAN(updatedAccountGroupsByIBAN)
    }
  }, [groupInfoList, accounts])

  return { isLoading, data: accountGroupsByIBAN }
}
