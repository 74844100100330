import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequest } from '../../utils/interfaces'
import { delayEmulation } from '../config'

const fetchPaymentTemplate = (paymentTemplates: PaymentRequest[], id: string) => {
  return new Promise<PaymentRequest | null>((resolve, reject) => {
    delayEmulation()
      .then(() => {
        resolve(paymentTemplates?.find((p) => p.id === id) ?? null)
      })
      .catch(() => reject())
  })
}

export default function useFetchPaymentTemplate(id = '') {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['payment-templates', session.paymentTemplates, id],
    queryFn: () => fetchPaymentTemplate(session.paymentTemplates, id),
  })
}
