import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { EditAccountGroupRequest, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const editAccountGroup = (dispatch: React.Dispatch<SessionReducerAction>, request: EditAccountGroupRequest) => {
  return new Promise<void>((resolve) => {
    dispatch({
      type: SessionReducerActionType.EDIT_ACCOUNT_GROUP,
      payload: request,
    })
    resolve()
  })
}

export default function useEditAccountGroup() {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: EditAccountGroupRequest) => editAccountGroup(dispatch, request),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['accounts'] })
      await queryClient.invalidateQueries({ queryKey: ['account-groups'] })
    },
  })
}
