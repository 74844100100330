import { Box, Button, ButtonProps, createPolymorphicComponent, Flex, packSx, Paper } from '@mantine/core'
import { CSSGrid } from '../common/CSSGrid'
import { LogoLeikur } from '../logos'
import { Container } from './Container'
const date = new Date().getFullYear()

const _SimpleLink: React.FC<ButtonProps> = (props) => (
  <Button
    variant='subtle'
    component='a'
    fz='sm'
    c='textDarker'
    ta='center'
    size='sm'
    p={0}
    h='xl'
    fw={400}
    lh='1.5'
    {...props}
    sx={[
      (theme) => ({
        [':hover']: {
          backgroundColor: 'unset',
          color: theme.fn.themeColor('primary'),
        },
      }),
      ...packSx(props.sx),
    ]}
  />
)

const SimpleLink = createPolymorphicComponent<'a', ButtonProps>(_SimpleLink)

const AppFooter = () => {
  return (
    <Box>
      <Paper radius='none' bg='white' shadow='-lg'>
        <Container p='0.75rem 0'>
          <CSSGrid templateColumns={{ base: 'repeat(3, 1fr)', desktop: 'repeat(6, 1fr)' }} gap='sm' alignContent='center' justifyContent='center' h='100%'>
            {/* <SimpleLink href='/'>Contact Centre</SimpleLink> */}
            <SimpleLink href='/'>Leikur FAQs</SimpleLink>
            {/* <SimpleLink href='/'>Sustainability</SimpleLink> */}
            {/* <SimpleLink href='/'>Investor Relations</SimpleLink> */}
            {/* <SimpleLink href='/'>Legal &amp; Compliance</SimpleLink> */}
            <SimpleLink href='/'>Privacy Policy</SimpleLink>
            {/* <SimpleLink href='/'>Security Centre</SimpleLink> */}
            {/* <SimpleLink href='/'>Dispute Centre</SimpleLink> */}
            <SimpleLink href='/'>Rates &amp; Fees</SimpleLink>
            <SimpleLink href='/'>Banking Regulations</SimpleLink>
            <SimpleLink href='/'>Terms of Use</SimpleLink>
            <SimpleLink href='/'>About Us</SimpleLink>
          </CSSGrid>
        </Container>
      </Paper>
      <Flex mih='3rem' c='white' bg='secondary' sx={{ zIndex: 1 }}>
        <Container py='md'>
          <CSSGrid
            templateColumns={{ base: '1fr', desktop: 'auto 1fr' }}
            gap={{ base: 'md', desktop: '2xl' }}
            alignItems='center'
            justifyItems={{ base: 'center', desktop: 'start' }}
          >
            <Box component={LogoLeikur} w='7rem' sx={{ path: { fill: 'white' } }} />
            <Box component='p' display='block' fz={12} m={0} ta='center'>
              © Copyright Leikur Ltd. {date} <Box component='br' display={{ desktop: 'none' }} />
              <Box component='span' display={{ base: 'none', desktop: 'inline' }}>
                |
              </Box>{' '}
              Leikur UAB, Gedimino pr. 20, Vilnius, Lithuania
            </Box>
          </CSSGrid>
        </Container>
      </Flex>
    </Box>
  )
}

export default AppFooter
