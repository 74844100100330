import { InternationalPhoneNumber } from '@innostream/stream-ui'

export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  SEK = 'SEK',
  CAD = 'CAD',
  JPY = 'JPY',
  PLN = 'PLN',
  CZK = 'CZK',
  CHF = 'CHF',
  AUD = 'AUD',
  DKK = 'DKK',
  NOK = 'NOK',
  HUF = 'HUF',
  RON = 'RON',
  SGD = 'SGD',
  HKD = 'HKD',
}

export enum PaymentRequestTypes {
  NEW_REQUEST = 'new-request',
  NEW_FROM_TEMPLATE = 'new-from-template',
  NEW_TEMPLATE = 'new-template',
}

export enum PaymentRequestStatus {
  PENDING_APPROVAL = 'pending-approval',
  REJECTED = 'rejected',
  PUSHED_BACK = 'pushed-back',
  READY = 'ready',
  TEMPLATE = 'template',
  CANCELLED = 'cancelled',
  AUTHORISED = 'authorised',
}

export interface Address {
  id: string
  street1: string
  street2: string
  city: string
  country_code: string
  postal_code: string
}

export interface BankAccountDetails {
  account_holder_name: string
  iban: string
  bic: string
  bank_name: string
  bank_address: string
  payment_rail: string
}

export type CurrencyInfo = {
  symbol: string
  regionName: string
  countryCodeForFlag: string
  name: string
}

export type Currencies = {
  [key in Currency]: CurrencyInfo
}

export interface AccountAddress {
  id: string
  currency: string
  name: string
  status: string
  bank_account_details: BankAccountDetails
  verification_status: string
  last_verified: string
  audit: {
    created_by: string
    created_on: string
    last_updated_by: string
    last_updated_on: string
  }
}

export interface Identifier {
  id: string
  external_id: string
  source: string //TODO: improve
  type: string //TODO: improve
  valid_from: string
  valid_to: string
}

// export interface Card {
//   id: string
//   label: string
//   display_name: string
//   account_id: string
//   currency: string //TODO: improve
//   exp_month: '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
//   exp_year: `${number}${number}${number}${number}`
//   last4: `${number}${number}${number}${number}`
//   region: string //TODO: improve
//   status: string //TODO
//   created_at: string
//   updated_at: string
//   brand: 'visa' | 'mastercard'
//   type: 'virtual' | 'physical'
// }

export type Theme = 'green' | 'purple' | 'gold' | 'blue' | 'brown'

export interface OtherIdentifier {
  id: string
  name: string
  source: string
  type: string
  valid_from: string
  valid_to: string
}

export interface Account {
  id: string
  balance: number
  iban: string
  currency: Currency
  account_addresses: AccountAddress[]
  alias: string
  account_holder_name: string
  account_holder_type: string
  other_identifiers: OtherIdentifier[]
  status: string
}

export interface User {
  id: string
  first_name: string
  last_name: string
  date_of_birth: string // modify to number/unix ?
  email: string
  phone_number: string
  status: string //TODO: add better type safety for status. Status should not be string.
  nationality: string[] //TODO improve type safety
  country_of_birth: string //TODO improve type safety
  language: string //TODO: improve
  promo_code: string
  agreed_to_tos: boolean
  agreed_to_promotions: boolean
  card_use: string //TODO: improve
  card_funding: string //TODO: improve
  address: Address
  shipping_address: Address
  other_identifiers: Identifier[]
  accounts: Account[]
  lastRefresh: string
}

export enum PaymentType {
  SEPA = 'sepa',
  // SWIFT = 'swift',
  LEIKUR = 'leikur',
}

export type SEPAAccountDetails = {
  type: PaymentType.SEPA
  account_holder_name?: string // This will be used for Switch, can move it out to another Type
  bic?: string // This will be used for Switch, can move it out to another Type
  iban: string
  bank_name?: string // This will be used for Switch, can move it out to another Type
}

export type LeikurAccountDetails = {
  type: PaymentType.LEIKUR
  account_holder_name?: string // actual name from the leikur account
  iban: string // this field will be populated by the server using the provided email address
  email: string
}

export type BeneficiaryAccountDetails = SEPAAccountDetails | LeikurAccountDetails

export type CounterParty = {
  type: PaymentType.SEPA | PaymentType.LEIKUR
  iban: string
  account_holder_name: string
}

export interface IDefaultPaymentDocuments {
  document_id: string
  document_name: string
  document_url: string
  uploaded_at: number
}

export interface IDefaultPayment {
  amount?: number
  currency?: Currency
  popEmail?: string
  popMobileNumber?: InternationalPhoneNumber
  documents?: IDefaultPaymentDocuments[]
}

export interface Transaction {
  account_id: string
  amount: number
  counterparty: CounterParty
  created_at: string
  currency: Currency
  id: string
  reference: string
  request_id: string
  status: string
  type: string
  theme?: Theme // TODO: come back here - check where this is used,
  account?: Account
}

export interface Beneficiary {
  id: string
  name: string
  alias: string
  payment_type: PaymentType
  entity_type: 'individual' | 'corporate'
  currency: Currency
  email: string
  status: 'active' | 'inactive' | 'pending'
  account_details: BeneficiaryAccountDetails
  default_payment: IDefaultPayment
  country: string //TODO: more specific
  reference?: string
}

export interface TransactionByBeneficiary {
  [key: string]: Transaction[]
}

export interface AccountGroupInfo {
  // id: string // ` IBAN
  order: number
  alias: string
  theme: Theme
  category: string
  iban: string // index
  bic: string
}

export interface AccountGroup extends AccountGroupInfo {
  accounts: Account[]
}

export interface PaymentBeneficiary {
  id: string
  amount: number
  name: string
  reference: string
  popEmail?: string
  popMobileNumber?: InternationalPhoneNumber
  documents?: IDefaultPaymentDocuments[]
}

export interface PaymentRequest {
  id: string
  internal_reference: string
  currency: Currency
  beneficiaries: PaymentBeneficiary[]
  created_by: string
  status: PaymentRequestStatus
  statusNote?: string
  last_modified_date: number
  last_modified_by: string
  approval_details?: {
    approved_by?: string
    account_id: string
    approved_at?: number
  }
}

export interface InternalTransferRequest {
  fromAccount: string
  toAccount: string
  amount: number
  reference: string
}

export interface AddCurrencyRequest {
  fromAccountIBAN: string
  toAccountIBAN: string // keeping this as ConnectPay currently ask us to set this.
  amount: number
  exchangedAmount?: number
  currency: Currency
}

export interface PaymentApproval extends PaymentRequest {
  ready: boolean
}

export interface Session {
  version: number
  accountGroupInfoList: AccountGroupInfo[]
  accounts: Account[]
  beneficiaries: Beneficiary[]
  paymentRequests: PaymentRequest[]
  paymentTemplates: PaymentRequest[]
  transactions: Transaction[]
}

export enum SessionReducerActionType {
  ADD_ACCOUNT = 'addAccount',
  EDIT_ACCOUNT_GROUP = 'editAccountGroup',

  ADD_BENEFICIARY = 'addBeneficiary',
  EDIT_BENEFICIARY = 'editBeneficiary',

  ADD_PAYMENT_REQUEST = 'addPaymentRequest',
  EDIT_PAYMENT_REQUEST = 'editPaymentRequest',

  ADD_PAYMENT_TEMPLATE = 'addPaymentTemplate',
  EDIT_PAYMENT_TEMPLATE = 'editPaymentTemplate',

  MAKE_INTERNAL_TRANSFER = 'makeInternalTransfer',

  ADD_CURRENCY = 'addCurrency',

  AUTHORISE_PAYMENT_REQUESTS = 'authorisePaymentRequests',
}

export type EditAccountGroupRequest = { iban: string; alias: string }

export type AddAccountAction = { type: SessionReducerActionType.ADD_ACCOUNT; payload: Account }
export type EditAccountGroupAction = {
  type: SessionReducerActionType.EDIT_ACCOUNT_GROUP
  payload: EditAccountGroupRequest
}

export type AddBeneficiaryAction = { type: SessionReducerActionType.ADD_BENEFICIARY; payload: Beneficiary }
export type EditBeneficiaryAction = { type: SessionReducerActionType.EDIT_BENEFICIARY; payload: Beneficiary }

export type AddPaymentRequestAction = { type: SessionReducerActionType.ADD_PAYMENT_REQUEST; payload: PaymentRequest }
export type EditPaymentRequestAction = { type: SessionReducerActionType.EDIT_PAYMENT_REQUEST; payload: PaymentRequest }

export type AddPaymentTemplateAction = { type: SessionReducerActionType.ADD_PAYMENT_TEMPLATE; payload: PaymentRequest }
export type EditPaymentTemplateAction = {
  type: SessionReducerActionType.EDIT_PAYMENT_TEMPLATE
  payload: PaymentRequest
}

export type MakeInternalTransferAction = {
  type: SessionReducerActionType.MAKE_INTERNAL_TRANSFER
  payload: InternalTransferRequest
}

export type AddCurrencyAction = {
  type: SessionReducerActionType.ADD_CURRENCY
  payload: AddCurrencyRequest
}

export type AuthorisePaymentRequestsAction = {
  type: SessionReducerActionType.AUTHORISE_PAYMENT_REQUESTS
  payload: PaymentRequest[]
}

export type SessionReducerAction =
  | AddAccountAction
  | EditAccountGroupAction
  | AddBeneficiaryAction
  | EditBeneficiaryAction
  | AddPaymentRequestAction
  | EditPaymentRequestAction
  | AddPaymentTemplateAction
  | EditPaymentTemplateAction
  | MakeInternalTransferAction
  | AddCurrencyAction
  | AuthorisePaymentRequestsAction

export type TransactionFilters = {
  fromDate?: string
  toDate?: string
  fromAmount?: number | ''
  toAmount?: number | ''
  searchString?: string
}
