import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { CSSGrid } from '../common/CSSGrid'
import AppFooter from './AppFooter'
import AppNavbar from './AppNavbar'

export const AppWrapper: React.FC<{ title: string; appHeader: ReactNode }> = ({ children, title, appHeader }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppNavbar />
      <CSSGrid mih='calc(100vh - 3.25rem)' templateRows='auto 1fr auto'>
        {appHeader}
        {/* make scrollbar appear only for main content & footer? */}
        {/* <CSSGrid h='calc(100vh - 8rem)' templateRows='1fr auto' sx={{ overflow: 'auto' }}> */}
        <CSSGrid sx={{ zIndex: 1 }}>{children}</CSSGrid>
        <AppFooter />
        {/* </CSSGrid> */}
      </CSSGrid>
    </>
  )
}

export default AppWrapper
