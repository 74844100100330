import { Flag } from '@innostream/stream-ui'
import { Box, BoxProps, Flex, Text, SelectProps } from '@mantine/core'
import React from 'react'
import currencies from '../../utils/currencies'
import { Account, Currency, Theme } from '../../utils/interfaces'
import { ThemedBackgroundSmallImage } from './ThemedBackgroundImage'

type SelectItemProps = BoxProps & {
  value: string
  label: string
  theme: Theme
  currency: Currency
  selected: boolean
  variant?: string
}

const SelectItem: React.FC<SelectItemProps> = React.forwardRef(function SelectItem(
  { value, label, theme, currency, ...others },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  delete others.variant
  return (
    <Box key={value} ref={ref} {...others}>
      <Flex align='center' gap='sm'>
        <Flex pos='relative' align='center' justify='center' sx={{ transform: 'scale(1.2)' }}>
          <ThemedBackgroundSmallImage
            h='md'
            w='md'
            theme={theme || 'blue'}
            pos='absolute'
            sx={{ borderRadius: '50%' }}
          />
          <Flag
            countryCode={currencies[currency || 'EUR']?.countryCodeForFlag}
            square={true}
            sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
            style={{
              borderRadius: '50%',
              width: '12px',
              height: '12px',
              zIndex: 1,
            }}
          />
        </Flex>
        <Text size='sm'>{label}</Text>
      </Flex>
    </Box>
  )
})

export const getSelectPropsForAccounts = (data: (Account & { theme: Theme })[], currentVal: string | null) =>
  ({
    variant: 'filled',
    size: 'sm',
    filter: (value: string, item: SelectItemProps) => item.label.toLowerCase().includes(value.toLowerCase().trim()),
    w: '100%',
    data: [
      ...data.map((account) => ({
        value: account.id,
        label: `${account.alias} (${account.currency})`,
        currency: account.currency,
        theme: account.theme as never,
      })),
    ],
    itemComponent: SelectItem,
    dropdownPosition: 'bottom',
    styles: { input: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' } },
    icon:
      currentVal !== undefined && currentVal !== null ? (
        <Flex pos='relative' align='center' justify='center' sx={{ transform: 'scale(1.2)', isolation: 'isolate' }}>
          <ThemedBackgroundSmallImage
            h='md'
            w='md'
            theme={data.find((a) => a.id === currentVal)?.theme || 'blue'}
            pos='absolute'
            sx={{ borderRadius: '50%' }}
          />
          <Flag
            countryCode={currencies[data.find((a) => a.id === currentVal)?.currency || 'EUR']?.countryCodeForFlag}
            square={true}
            sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
            style={{
              borderRadius: '50%',
              width: '12px',
              height: '12px',
              zIndex: 1,
            }}
          />
        </Flex>
      ) : undefined,
  } as SelectProps)
