
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Checks if the given email address is valid.
 *
 * @param email
 */
export const validateEmail = (email: string) => email.match(EMAIL_REGEX) !== null

/**
 * Validate the given field value against a RegExp. Does not fail when an optional (not required) field is `undefined` or `empty` (Fails when optional field is null)
 * 
 * Default `regex` just checks if there's at least one character: `/^.+/g`
 * @param value 
 * @param required 
 * @param regex 
 */
export const regexValidate = (value: string, required = false, regex = /^.+/g) => {
  if ((value === undefined || value === '') && !required) {
    return true
  }
  return value !== null && value.match(regex) !== null
}