import { EMAIL_REGEX, regexValidate } from '@innostream/stream-ui'
import { Button, Loader, UnstyledButton } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useFetchPaymentTemplate from '../../../../../api/queries/useFetchPaymentTemplate'
import { IconBackCancel, IconCloseCross } from '../../../../../components/icons'
import AppHeader from '../../../../../components/layout/AppHeader'
import AppWrapper from '../../../../../components/layout/AppWrapper'
import Container from '../../../../../components/layout/Container'
import { colorAliases } from '../../../../../theme/getMantineTheme'
import { Currency, PaymentRequest, PaymentRequestStatus, PaymentRequestTypes } from '../../../../../utils/interfaces'
import PaymentRequestForm from './PaymentRequestForm'

const Header = () => {
  const navigate = useNavigate()

  return (
    <AppHeader
      title='Transaction Centre'
      description='Create a payment request or template'
      icon={
        <UnstyledButton onClick={() => navigate('/beneficiary-centre')}>
          <IconBackCancel style={{ color: 'white', marginRight: '0.1rem' }} />
        </UnstyledButton>
      }
      buttons={
        <Button
          size='big'
          variant='leikurLight'
          color={colorAliases.systemError}
          leftIcon={<IconCloseCross style={{ height: '1em' }} />}
          onClick={() => navigate('/transaction-centre')}
        >
          Cancel <br />
        </Button>
      }
    />
  )
}

const initialFormValues: PaymentRequest = {
  id: '',
  currency: Currency.EUR,
  beneficiaries: [{ id: '', amount: 0, name: '', reference: '' }],
  internal_reference: '',
  last_modified_date: 0,
  status: PaymentRequestStatus.TEMPLATE,
  last_modified_by: '',
  created_by: '',
}

const ManagePayments = ({ requestType }: { requestType: PaymentRequestTypes }) => {
  const { templateId } = useParams()
  const { isLoading: isLoadingTemplate, data: paymentTemplate } = useFetchPaymentTemplate(templateId)

  const form = useForm<PaymentRequest & { templateName?: string }>({
    initialValues: initialFormValues,

    validate: {
      internal_reference: (value: string) =>
        value.length === 0 ? (requestType === PaymentRequestTypes.NEW_TEMPLATE ? 'Please enter a template name' : 'Please enter an internal reference') : null,
      //regexValidate(email, true, EMAIL_REGEX)
      beneficiaries: {
        id: (value) => (value === undefined || value.length === 0 ? 'Please add at least one beneficiary' : null),
        reference: (value) =>
          requestType !== PaymentRequestTypes.NEW_TEMPLATE && (value === undefined || value.length === 0) ? 'Please enter a reference' : null,
        amount: (value, values) =>
          values.beneficiaries?.[0]?.id === undefined || values.beneficiaries[0].id.length === 0 || (value !== undefined && value > 0)
            ? null
            : 'Please enter an amount greater than 0',
        popEmail: (value, values) =>
          values.beneficiaries?.[0]?.id === undefined || values.beneficiaries[0].id.length === 0 || regexValidate(value ?? '', false, EMAIL_REGEX)
            ? null
            : 'Please enter a valid email address',
        popMobileNumber: (value, values) =>
          values.beneficiaries?.[0]?.id === undefined || values.beneficiaries[0].id.length === 0 || value === undefined || value.isValid
            ? null
            : 'Please enter a valid phone number',
      },
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })
  // console.log(form.values)

  useEffect(() => {
    if (requestType === PaymentRequestTypes.NEW_FROM_TEMPLATE && paymentTemplate) {
      const values = JSON.parse(JSON.stringify(paymentTemplate)) as PaymentRequest
      form.setValues({
        ...values,
        templateName: paymentTemplate.internal_reference,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTemplate])

  if (requestType === PaymentRequestTypes.NEW_FROM_TEMPLATE && (isLoadingTemplate || !paymentTemplate)) {
    return (
      <AppWrapper title={`Leikur - ${requestType}`} appHeader={<Header />} key='new-from-template-loader'>
        <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </Container>
      </AppWrapper>
    )
  }

  return (
    <AppWrapper title={`Leikur - ${requestType || 'Transaction Centre'}`} appHeader={<Header />}>
      <Container>
        <PaymentRequestForm form={form} requestType={requestType} />
      </Container>
    </AppWrapper>
  )
}

export default ManagePayments
