import dayjs from 'dayjs'
import produce from 'immer'
import { defaultAccountDetails } from '../backend/mockedData'
import { getExchangeInfo } from '../utils/exchange_rates'
import { Currency, PaymentRequestStatus, PaymentType, Session, SessionReducerAction, SessionReducerActionType, Transaction } from '../utils/interfaces'
import { INTERNAL_TRANSFER_REFERENCE_SUFFIX } from '../utils/constants'

export default (session: Session, action: SessionReducerAction) => {
  const nextState = produce(session, (draft) => {
    switch (action.type) {
      case SessionReducerActionType.ADD_BENEFICIARY:
        draft.beneficiaries = [...(draft.beneficiaries ?? []), action.payload]
        break
      case SessionReducerActionType.EDIT_BENEFICIARY:
        // assuming that beneficiaries is not empty
        draft.beneficiaries = draft.beneficiaries.map((beneficiary) => {
          if (beneficiary.id === action.payload.id) {
            return action.payload
          }
          return beneficiary
        })
        break
      case SessionReducerActionType.ADD_PAYMENT_TEMPLATE:
        draft.paymentTemplates = [...draft.paymentTemplates, action.payload]
        break
      case SessionReducerActionType.EDIT_PAYMENT_TEMPLATE:
        // assuming templates is not empty
        draft.paymentTemplates = draft.paymentTemplates.map((template) => {
          if (template.id === action.payload.id) {
            return action.payload
          }
          return template
        })
        break
      case SessionReducerActionType.ADD_PAYMENT_REQUEST:
        draft.paymentRequests = [...draft.paymentRequests, action.payload]
        break
      case SessionReducerActionType.EDIT_PAYMENT_REQUEST:
        // assuming requests are not empty
        draft.paymentRequests = draft.paymentRequests.map((paymentRequest) => {
          if (paymentRequest.id === action.payload.id) {
            return { ...action.payload, last_modified_date: dayjs().valueOf() }
          }
          return paymentRequest
        })
        break
      case SessionReducerActionType.EDIT_ACCOUNT_GROUP:
        // assuming that accounts is not empty
        draft.accounts = draft.accounts.map((account) => {
          if (account.iban === action.payload.iban) {
            return { ...account, alias: action.payload.alias }
          }
          return account
        })
        draft.accountGroupInfoList = draft.accountGroupInfoList.map((groupInfo) => {
          if (groupInfo.iban === action.payload.iban) {
            return { ...groupInfo, alias: action.payload.alias }
          }
          return groupInfo
        })
        break
      case SessionReducerActionType.MAKE_INTERNAL_TRANSFER: {
        // Assuming we have at least 2 accounts at this point.
        const fromAccount = draft.accounts.find((a) => a.id === action.payload.fromAccount)
        const toAccount = draft.accounts.find((a) => a.id === action.payload.toAccount)

        if (fromAccount && toAccount) {
          const exchangeInfo = getExchangeInfo({
            amount: action.payload.amount,
            fromCurrency: fromAccount.currency,
            toCurrency: toAccount.currency,
          })

          fromAccount.balance = fromAccount.balance - action.payload.amount
          toAccount.balance = toAccount.balance + exchangeInfo.toAmount

          draft.accounts = draft.accounts.map((acc) => {
            if (acc.id === fromAccount.id) {
              return fromAccount
            }
            if (acc.id === toAccount.id) {
              return toAccount
            }
            return acc
          })
          const requestId = `request-${dayjs().valueOf()}`
          const transactionFrom = {
            account_id: action.payload.fromAccount,
            amount: action.payload.amount * -1,
            counterparty: {
              type: PaymentType.LEIKUR,
              iban: toAccount.iban,
              account_holder_name: toAccount.alias,
            },
            created_at: dayjs().toISOString(),
            currency: fromAccount.currency,
            id: `transaction-${dayjs().valueOf()}`,
            reference: `${INTERNAL_TRANSFER_REFERENCE_SUFFIX}${action.payload.reference}`,
            request_id: requestId,
            status: 'completed',
            type: 'bank-transfer',
          } as Transaction
          const transactionTo = {
            account_id: action.payload.toAccount,
            amount: exchangeInfo.toAmount,
            counterparty: {
              type: PaymentType.LEIKUR,
              iban: fromAccount.iban,
              account_holder_name: fromAccount.alias,
            },
            created_at: dayjs().add(1, 'millisecond').toISOString(),
            currency: toAccount.currency,
            id: `transaction-${dayjs().valueOf()}`,
            reference: `${INTERNAL_TRANSFER_REFERENCE_SUFFIX}${action.payload.reference}`,
            request_id: requestId,
            status: 'completed',
            type: 'bank-transfer',
          } as Transaction
          draft.transactions = [...(draft.transactions ?? []), transactionFrom, transactionTo]
        }
        break
      }

      case SessionReducerActionType.ADD_CURRENCY: {
        // Assuming we have at least the fromAccount at this point.
        const fromAccount = draft.accounts.find((a) => a.iban === action.payload.fromAccountIBAN && a.currency === Currency.EUR)

        if (fromAccount) {
          const exchangeInfo = getExchangeInfo({
            amount: action.payload.amount,
            fromCurrency: Currency.EUR,
            toCurrency: action.payload.currency,
          })

          const exchangedAmount = exchangeInfo.toAmount

          const toAccount = {
            ...defaultAccountDetails(fromAccount.iban),
            id: crypto.randomUUID(),
            iban: fromAccount.iban,
            balance: exchangedAmount,
            currency: action.payload.currency,
            alias: fromAccount.alias,
          }

          fromAccount.balance = fromAccount.balance - action.payload.amount

          draft.accounts = draft.accounts.map((acc) => {
            if (acc.id === fromAccount.id) {
              return fromAccount
            }
            return acc
          })
          draft.accounts = [...draft.accounts, toAccount]

          const requestId = `request-${dayjs().valueOf()}`
          const transactionFrom = {
            account_id: fromAccount.id,
            amount: action.payload.amount * -1,
            counterparty: {
              type: PaymentType.LEIKUR,
              iban: toAccount.iban,
              account_holder_name: toAccount.alias,
            },
            created_at: dayjs().toISOString(),
            currency: fromAccount.currency,
            id: `transaction-${dayjs().valueOf()}`,
            reference: `${INTERNAL_TRANSFER_REFERENCE_SUFFIX}${crypto.randomUUID()}`,
            request_id: requestId,
            status: 'completed',
            type: 'bank-transfer',
          } as Transaction
          const toRef = `${INTERNAL_TRANSFER_REFERENCE_SUFFIX}${crypto.randomUUID()}`
          const transactionTo = {
            account_id: toAccount.id,
            amount: exchangedAmount,
            counterparty: {
              type: PaymentType.LEIKUR,
              iban: fromAccount.iban,
              account_holder_name: fromAccount.alias,
            },
            created_at: dayjs().add(1, 'millisecond').toISOString(),
            currency: toAccount.currency,
            id: `transaction-${dayjs().valueOf()}`,
            reference: toRef,
            request_id: requestId,
            status: 'completed',
            type: 'bank-transfer',
          } as Transaction
          draft.transactions = [...(draft.transactions ?? []), transactionFrom, transactionTo]
        } else {
          console.error('ORIGIN ACCOUNT NOT FOUND')
        }
        break
      }

      case SessionReducerActionType.AUTHORISE_PAYMENT_REQUESTS: {
        action.payload.forEach((request) => {
          const beneficiaryDetails = draft.beneficiaries.find((b) => b.id === request.beneficiaries[0].id)
          const fromAccount = draft.accounts.find((a) => a.id === request.approval_details?.account_id)
          if (beneficiaryDetails && fromAccount) {
            draft.paymentRequests = draft.paymentRequests.map((paymentRequest) => {
              if (paymentRequest.id === request.id) {
                return { ...request, status: PaymentRequestStatus.AUTHORISED, last_modified_date: dayjs().valueOf() }
              }
              return paymentRequest
            })

            fromAccount.balance = fromAccount.balance - request.beneficiaries[0].amount

            draft.accounts = draft.accounts.map((acc) => {
              if (acc.id === fromAccount.id) {
                return fromAccount
              }
              return acc
            })

            const transaction: Transaction = {
              created_at: dayjs().toISOString(),
              id: `transaction-${dayjs().valueOf()}`,
              account_id: fromAccount.id,
              amount: request.beneficiaries[0].amount * -1,
              currency: request.currency,
              reference: request.beneficiaries[0].reference,
              request_id: request.id,
              counterparty: {
                type: beneficiaryDetails.account_details.type,
                iban: beneficiaryDetails.account_details.iban,
                account_holder_name: beneficiaryDetails.name,
              },
              status: 'completed',
              type: 'bank-transfer',
            }

            draft.transactions = [...(draft.transactions ?? []), transaction]
          }
        })
        break
      }
    }
  })

  localStorage.setItem('leikur-session', JSON.stringify(nextState))
  return nextState
}
