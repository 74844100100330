import { Box, CloseButton, Group, MultiSelectValueProps, SelectItem, Text, getSize } from '@mantine/core';
import React, { forwardRef } from 'react';
import countries from '../../utils/countries';
import { Flag } from '../Flag';
import { MultiSelectProps } from './MultiSelect/MultiSelect';
import { SelectProps } from './_Select/Select';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  selectItemLabel: string;
  countryCode: string;
  searchLabel: string;
}

const MemoizedSelectItemInner = React.memo(({ spacing, countryCode, selectItemLabel }: any) => (
  <Group noWrap spacing={spacing || 'md'}>
    <Flag countryCode={countryCode} style={{ borderRadius: 4 }} />
    <Text size="sm">{selectItemLabel}</Text>
  </Group>
));

const CountrySelectItem = forwardRef<HTMLDivElement, ItemProps>(({ countryCode, selectItemLabel, searchLabel, ...others }: ItemProps, ref) => {
  return (
    <div ref={ref} {...others}>
      <MemoizedSelectItemInner countryCode={countryCode} selectItemLabel={selectItemLabel} />
    </div>
  );
});

const CountrySelectValue = ({
  value,
  label,
  onRemove,
  classNames,
  size,
  disabled,
  readOnly,
  ...others
}: MultiSelectValueProps & { value: string }) => {
  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          // cursor: 'default',
          alignItems: 'center',
          // paddingLeft: 10,
          borderRadius: 4,
          backgroundColor: disabled
            ? theme.colorScheme === 'dark'
              ? theme.colors.dark[5]
              : theme.colors.gray[3]
            : theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
          color: disabled
            ? theme.colorScheme === 'dark'
              ? theme.colors.dark[1]
              : theme.colors.gray[7]
            : theme.colorScheme === 'dark'
            ? theme.colors.dark[0]
            : theme.colors.gray[7],
          paddingLeft: `calc(${getSize({ size: size, sizes: theme.spacing })} - 0.375rem)`,
          paddingRight: disabled || readOnly ? (getSize({ size: size, sizes: theme.spacing }) as string) : '0',
          height: '100%',
          fontWeight: 500,
          fontSize: `calc(${getSize({ size: size, sizes: theme.fontSizes })} - 0.125rem)`,
          cursor: disabled ? 'not-allowed' : 'default',
          userSelect: 'none',
        })}
      >
        <Flag countryCode={value} style={{ borderRadius: 4 }} />
        <Box sx={{ lineHeight: 1, paddingLeft: 8 }}>{label}</Box>
        <CloseButton onMouseDown={onRemove} variant="transparent" size={22} iconSize={14} tabIndex={-1} />
      </Box>
    </div>
  );
};

const countryCodesAsData = countries.reduce<SelectItem[]>(
  (acc, country) => [
    ...acc,
    {
      value: country.alpha2code,
      label: country.name,
      countryCode: country.alpha2code,
      selectItemLabel: country.name,
      searchLabel: `${country.name} ${country.alpha2code}`,
    },
  ],
  []
);

const countryDiallingCodeAsData = countries.reduce<SelectItem[]>(
  (acc, country) => [
    ...acc,
    {
      value: country.alpha2code,
      label: `${country.alpha2code} ${country.phoneCode}`,
      countryCode: country.alpha2code,
      selectItemLabel: `${country.name} ${country.phoneCode}`,
      searchLabel: `${country.name} ${country.alpha2code} ${country.phoneCode}`,
    },
  ],
  []
);

export const getSelectPropsForCountry = (currentVal?: string | null, filter?: (item: SelectItem) => boolean) =>
  ({
    filter: (value, item) => item.searchLabel.toLowerCase().includes(value.toLowerCase().trim()),
    data: (filter && typeof filter === 'function' ? countryCodesAsData.filter(filter) : countryCodesAsData) as SelectItem[],
    itemComponent: CountrySelectItem,
    icon: currentVal !== undefined && currentVal !== null ? <Flag countryCode={currentVal} style={{ borderRadius: 4 }} /> : undefined,
  } as SelectProps);

export const getMultiSelectPropsForCountry = (filter?: (item: SelectItem) => boolean) =>
  ({
    filter: (value, selected, item) => !selected && item.searchLabel.toLowerCase().includes(value.toLowerCase().trim()),
    data: (filter && typeof filter === 'function' ? countryCodesAsData.filter(filter) : countryCodesAsData) as SelectItem[],
    itemComponent: CountrySelectItem,
    valueComponent: CountrySelectValue,
  } as MultiSelectProps);

export const getSelectPropsForCountryDiallingCode = (currentVal?: string | null, filter?: (item: SelectItem) => boolean) =>
  ({
    filter: (value, item) => item.searchLabel.toLowerCase().includes(value.toLowerCase().trim()),
    data: (filter && typeof filter === 'function' ? countryDiallingCodeAsData.filter(filter) : countryDiallingCodeAsData) as SelectItem[],
    itemComponent: CountrySelectItem,
    icon: currentVal !== undefined && currentVal !== null ? <Flag countryCode={currentVal} style={{ borderRadius: 4 }} /> : undefined,
  } as SelectProps);
