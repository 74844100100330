import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequest, PaymentRequestStatus } from '../../utils/interfaces'
import { delayEmulation } from '../config'

const fetchPaymentRequests = async (paymentRequests: PaymentRequest[], filters?: PaymentRequestStatus[]) => {
  return new Promise<PaymentRequest[]>((resolve, reject) => {
    delayEmulation()
      .then(() =>
        resolve(
          paymentRequests.filter((pr) => (filters && filters.length > 0 ? filters.includes(pr.status) : true)) ?? []
        )
      )
      .catch(() => reject())
  })
}

export default function useFetchPaymentRequests(filters?: PaymentRequestStatus[]) {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['payment-requests', session.paymentRequests, filters],
    queryFn: () => fetchPaymentRequests(session.paymentRequests, filters),
  })
}
