import {
  DefaultProps,
  Input,
  InputBaseProps,
  InputWrapperBaseProps,
  packSx,
  Selectors,
  SelectProps,
  SimpleGrid,
  SimpleGridProps,
  Space,
} from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import React, { ComponentProps, forwardRef, useState } from 'react';
import { SharedInputProps, TextInput, TextInputProps } from '../../simple/Inputs';
import { getSelectPropsForCountry, Select } from '../../simple/Selects';
import useStyles, { AddressInputStylesParams } from './AddressInput.styles';

type AddressInputStylesNames = Selectors<typeof useStyles>;

export type Address = {
  countryCode: string | null;
  streetName: string;
  buildingOrHouseNameOrSuiteNumber: string;
  city: string;
  postalCode: string;
  isValid: boolean;
};

export interface AddressInputProps
  extends DefaultProps<AddressInputStylesNames, AddressInputStylesParams>,
    SharedInputProps,
    InputWrapperBaseProps,
    Pick<InputBaseProps, 'disabled'> {
  /** Controlled input value */
  value?: Partial<Address>;
  /** Controlled input onChange handler */
  onChange?(value: Address): void;

  inputRef?: React.ForwardedRef<HTMLInputElement>;
  selectProps?: Partial<SelectProps>;
  textInputProps?: Partial<TextInputProps>;
  simpleGridProps?: Partial<SimpleGridProps>;

  form?: ComponentProps<'input'>['form'];
  onBlur?: (value: Partial<Address>) => {};
  requiredFields?: Array<keyof Omit<Address, 'isValid'>>;
}

export const AddressInput = forwardRef<HTMLInputElement, AddressInputProps>(
  (
    {
      classNames,
      value,
      onChange,
      inputRef,
      selectProps,
      textInputProps,
      simpleGridProps,
      borderColor,
      inputColor,
      labelColor,
      labelFontSize,
      labelFontWeight,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
      disabled,
      form,
      onBlur,
      styles,
      requiredFields = ['countryCode', 'city', 'streetName'],
      ...props
    }: AddressInputProps,
    ref
  ) => {
    // F: allows all inputs description/error to have the same customisation (for color, font size, font weight)
    // as opposed to PhoneNumberInput, we do  use the description for each input box here.
    // passing in the other custom props to allow for a more flexible impl
    const sharedInnerInputProps = {
      labelColor,
      labelFontSize,
      labelFontWeight,
      borderColor,
      inputColor,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
    };

    const { classes } = useStyles(
      {
        ...props,
        labelColor,
        labelFontSize,
        labelFontWeight,
        errorColor,
        descriptionColor,
        otherFontSize,
        otherFontWeight,
      } as AddressInputStylesParams,
      { classNames, name: 'custom' }
    );

    const [_value, handleChange] = useUncontrolled({
      value,
      defaultValue: {
        countryCode: null,
        streetName: '',
        buildingOrHouseNameOrSuiteNumber: '',
        city: '',
        postalCode: '',
        isValid: false,
      },
      onChange,
    });

    const _handleChange = ({ countryCode, streetName, buildingOrHouseNameOrSuiteNumber, city, postalCode }: Partial<Address>) => {
      // F: previously, each time _handleChange was called, it was overwriting the other fields (i.e. ignoring _value fields).
      const newValue = {
        countryCode: countryCode !== undefined ? countryCode : _value.countryCode,
        streetName: streetName !== undefined ? streetName : _value.streetName,
        buildingOrHouseNameOrSuiteNumber:
          buildingOrHouseNameOrSuiteNumber !== undefined ? buildingOrHouseNameOrSuiteNumber : _value.buildingOrHouseNameOrSuiteNumber,
        city: city !== undefined ? city : _value.city,
        postalCode: postalCode !== undefined ? (postalCode.length > 10 ? postalCode.substring(0, 10) : postalCode) : _value.postalCode,
      };

      // F: had a logic error here where isValid was true if at least one required field was filled
      const isValid = requiredFields.find((field) => newValue[field] === undefined || newValue[field] === '') === undefined;

      handleChange({ ...newValue, isValid });
    };

    // TODO: add better Type safety to key
    const [blurredInput, _setBlurredInput] = useState<{ [key: string]: boolean }>({
      countryCode: value?.countryCode !== undefined && value?.countryCode !== null,
      streetName: value?.streetName !== undefined && value?.streetName !== '',
      buildingOrHouseNameOrSuiteNumber: value?.buildingOrHouseNameOrSuiteNumber !== undefined && value?.buildingOrHouseNameOrSuiteNumber !== '',
      city: value?.city !== undefined && value?.city !== '',
      postalCode: value?.postalCode !== undefined && value?.postalCode !== '',
    });

    const setBlurredInput = (key: keyof Address) => {
      const newState = { ...blurredInput, [key]: true };
      const allRequiredFieldsBlurred = requiredFields.find((val) => !newState[val]) === undefined;
      if (allRequiredFieldsBlurred && onBlur !== undefined) {
        onBlur(_value);
      }
      _setBlurredInput(newState);
    };
    return (
      <Input.Wrapper
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        classNames={classes}
        styles={{ ...styles }}
        {...props}
        sx={[{ minWidth: 288 }, ...packSx(props.sx)]}
        ref={ref}
      >
        {/* FIXME: pass full address here? i'm unsure if we should do it this way - mantine does something similar for complex input components */}
        <input ref={inputRef} type="hidden" value={_value ? JSON.stringify(_value) : ''} form={form} disabled={disabled} />

        <SimpleGrid verticalSpacing={simpleGridProps?.verticalSpacing || 'sm'}>
          <SimpleGrid breakpoints={[{ minWidth: 'sm', cols: 2 }]} spacing={32} verticalSpacing="sm" {...simpleGridProps}>
            <Select
              {...getSelectPropsForCountry(_value?.countryCode)}
              value={_value?.countryCode}
              onChange={(countryCode) => typeof countryCode === 'string' && _handleChange({ countryCode })}
              disabled={disabled}
              description={'Country'}
              onBlur={() => setBlurredInput('countryCode')}
              error={requiredFields?.includes('countryCode') && props.error !== undefined}
              {...sharedInnerInputProps}
              {...selectProps}
              styles={{
                ...selectProps?.styles,
                dropdown: { minWidth: 220, ...(selectProps?.styles as any)?.dropdown },
              }}
            />
          </SimpleGrid>
          <SimpleGrid breakpoints={[{ minWidth: 'sm', cols: 2 }]} spacing={32} verticalSpacing="sm" {...simpleGridProps}>
            <TextInput
              type="text"
              value={_value?.streetName}
              onChange={(e) => _handleChange({ streetName: e.currentTarget.value })}
              disabled={disabled}
              description="Street name"
              onBlur={() => setBlurredInput('streetName')}
              error={requiredFields?.includes('streetName') && props.error !== undefined}
              {...sharedInnerInputProps}
              // TODO: rework textInputProps to expose props for each text inputs used here
              {...textInputProps}
            />
            <TextInput
              type="text"
              value={_value?.buildingOrHouseNameOrSuiteNumber}
              onChange={(e) => _handleChange({ buildingOrHouseNameOrSuiteNumber: e.currentTarget.value })}
              disabled={disabled}
              description="Building, house name or suite number"
              onBlur={() => setBlurredInput('buildingOrHouseNameOrSuiteNumber')}
              error={requiredFields?.includes('buildingOrHouseNameOrSuiteNumber') && props.error !== undefined}
              {...sharedInnerInputProps}
              {...textInputProps}
            />

            <TextInput
              type="text"
              value={_value?.city}
              onChange={(e) => _handleChange({ city: e.currentTarget.value })}
              disabled={disabled}
              description="City"
              onBlur={() => setBlurredInput('city')}
              error={requiredFields?.includes('city') && props.error !== undefined}
              {...sharedInnerInputProps}
              {...textInputProps}
            />
            <TextInput
              type="text"
              value={_value?.postalCode}
              onChange={(e) => _handleChange({ postalCode: e.currentTarget.value })}
              disabled={disabled}
              description="Postal code"
              onBlur={() => setBlurredInput('postalCode')}
              error={requiredFields?.includes('postalCode') && props.error !== undefined}
              {...sharedInnerInputProps}
              {...textInputProps}
            />
          </SimpleGrid>
        </SimpleGrid>
        <Space h="sm" />
      </Input.Wrapper>
    );
  }
);
