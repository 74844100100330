import { Box, BoxProps } from '@mantine/core'
import React from 'react'

import './container.css'

export const Container: React.FC<BoxProps & React.ComponentPropsWithoutRef<'div'>> = ({ children, ...props }) => {
  return (
    <Box
      className='leikur-container'
      w='100%'
      mx='auto'
      // legacy comments: now handled in container.css - keeping it for future reference
      // including my because of a weird specificity issue with the Box component for `maw` prop
      // my={{ base: '0', tablet: '0', desktop: '0' }}
      // maw={{ base: 390, tablet: 'min(calc(100vw - 22px), 412px)', desktop: 'min(calc(100vw - 6rem), 1375px)' }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default Container
