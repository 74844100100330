import { createStyles, getSize, rem } from '@mantine/core';

export const sizes = {
  xs: rem(20),
  sm: rem(24),
  md: rem(32),
  lg: rem(44),
  xl: rem(64),
};

export default createStyles(
  (
    theme,
    {
      multiline,
      variant,
      size,
      rightSectionWidth,
      withRightSection,
      radius,
      color,
      borderColor,
      inputColor,
      labelColor,
      labelFontSize,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
      labelFontWeight,
    }: any
  ) => {
    const sizeStyles: any =
      variant === 'default' || variant === 'filled'
        ? {
          minHeight: getSize({ size, sizes }),
          paddingLeft: `calc(${getSize({ size, sizes })} / 3)`,
          paddingRight: withRightSection
            ? rightSectionWidth
            : `calc(${getSize({ size, sizes })} / 3)`,
        }
        : null;
    return {
      input: {
        height: multiline
          ? variant === 'unstyled'
            ? undefined
            : 'auto'
          : getSize({ size, sizes }),
        ...sizeStyles,

        color: inputColor
          ? theme.fn.themeColor(inputColor)
          : theme.other?.defaultInputProps?.inputColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.inputColor)
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[0]
              : theme.black,
        backgroundColor: variant === 'default' ? 'transparent' : undefined,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderRadius: variant === 'filled' ? theme.fn.radius(radius) : 0,
        borderColor: borderColor
          ? theme.fn.themeColor(borderColor)
          : theme.other?.defaultInputProps?.borderColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.borderColor)
            : variant === 'default'
              ? theme.colors.gray[5]
              : undefined,

        ':focus, :focus-within': {
          boxShadow: `0 0 0 1pt ${borderColor
            ? theme.fn.themeColor(borderColor)
            : theme.other?.defaultInputProps?.borderColor
              ? theme.fn.themeColor(theme.other.defaultInputProps.borderColor)
              : theme.fn.themeColor(color)}`,
          borderRadius: theme.fn.radius(radius),
          borderBottomColor: borderColor
            ? theme.fn.themeColor(borderColor)
            : theme.other?.defaultInputProps?.borderColor
              ? theme.fn.themeColor(theme.other.defaultInputProps.borderColor)
              : theme.fn.themeColor(color),
        },
        '&::placeholder': {
          color: theme.colors.muted ? theme.colors.muted[6] : theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
          fontSize: `calc(${getSize({ size: size, sizes: theme.fontSizes, })} * 0.875)`,
          fontStyle: 'italic',
        },
      },
      label: {
        fontSize: getSize({
          size:
            labelFontSize ||
            theme.other?.defaultInputProps?.labelFontSize ||
            size,
          sizes: theme.fontSizes,
        }),
        fontWeight:
          labelFontWeight ||
          theme.other?.defaultInputProps?.labelFontWeight ||
          500,
        color: labelColor
          ? theme.fn.themeColor(labelColor)
          : theme.other?.defaultInputProps?.labelColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.labelColor)
            : theme.fn.primaryColor(),
      },
      description: {
        fontSize: otherFontSize
          ? getSize({
            size: otherFontSize,
            sizes: theme.fontSizes,
          })
          : theme.other?.defaultInputProps?.otherFontSize
            ? getSize({
              size: theme.other.defaultInputProps.otherFontSize,
              sizes: theme.fontSizes,
            })
            : `calc(${getSize({ size, sizes: theme.fontSizes })} - 0.25rem)`,
        fontWeight:
          otherFontWeight ||
          theme.other?.defaultInputProps?.otherFontWeight ||
          400,
        color: descriptionColor
          ? theme.fn.themeColor(descriptionColor)
          : theme.other?.defaultInputProps?.descriptionColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.descriptionColor)
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[2]
              : theme.colors.gray[7],
      },
      error: {
        paddingLeft: 16,
        paddingTop: 2,
        fontSize: otherFontSize
          ? getSize({
            size: otherFontSize,
            sizes: theme.fontSizes,
          })
          : theme.other?.defaultInputProps?.otherFontSize
            ? getSize({
              size: theme.other.defaultInputProps.otherFontSize,
              sizes: theme.fontSizes,
            })
            : `calc(${getSize({ size, sizes: theme.fontSizes })} - 0.25rem)`,
        fontWeight:
          otherFontWeight ||
          theme.other?.defaultInputProps?.otherFontWeight ||
          400,
        color: errorColor
          ? theme.fn.themeColor(errorColor)
          : theme.other?.defaultInputProps?.errorColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.errorColor)
            : theme.fn.variant({ variant: 'filled', color: 'red' }).background,
      },
    };
  }
);
