import { MultiSelect as MantineMultiSelect, MultiSelectProps as MantineMultiSelectProps } from '@mantine/core';
import React, { forwardRef } from 'react';
import { SharedInputProps } from '../../Inputs';

import useStyles from './MultiSelect.styles';

export interface MultiSelectProps extends MantineMultiSelectProps, SharedInputProps {}

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  (
    {
      classNames,
      size = 'md',
      variant = 'default',
      inputWrapperOrder = ['label', 'input', 'description', 'error'],
      searchable = true,
      borderColor,
      inputColor,
      labelColor,
      labelFontSize,
      labelFontWeight,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
      ...props
    }: MultiSelectProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        borderColor,
        inputColor,
        labelColor,
        labelFontSize,
        labelFontWeight,
        errorColor,
        descriptionColor,
        otherFontSize,
        otherFontWeight,
        variant,
        size,
      },
      { classNames, name: 'custom' }
    );

    return (
      <MantineMultiSelect
        ref={ref}
        searchable={searchable}
        variant={variant}
        size={size}
        inputWrapperOrder={inputWrapperOrder}
        classNames={classes}
        {...props}
      />
    );
  }
);
