import { Flag } from '@innostream/stream-ui'
import {
  ActionIcon,
  Box,
  Button,
  CopyButton,
  Divider,
  Flex,
  Paper,
  PaperProps,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSGrid } from '../../../components/common/CSSGrid'
import {
  IconAccountView,
  IconCopy,
  IconEditModifyPen,
  IconViewInfoEye,
  IconViewInfoEyeOff,
} from '../../../components/icons'
import { openModalForAccountGroupAliasUpdate } from '../../../components/modals/AccountGroupAliasUpdate'
import currencies, { currencyFormat } from '../../../utils/currencies'
import formatIBAN from '../../../utils/formatIBAN'
import { AccountGroup } from '../../../utils/interfaces'
import OptionButtons from '../Dashboard/DashboardGroup/OptionButtons'
import { colorAliases } from '../../../theme/getMantineTheme'

const AccountsArea: React.FC<
  PaperProps & {
    group: AccountGroup
    accountId?: string
  }
> = ({ group, accountId, ...otherPaperProps }) => {
  const navigate = useNavigate()

  const theme = useMantineTheme()

  const accentColor = group.theme + 'GroupThemeAccent'

  return (
    <Paper miw={390} mih={206} {...otherPaperProps} style={{ overflow: 'clip' }} bg={colorAliases.surfaceLightgray}>
      <Box px='2xl' py='2xl'>
        <Flex justify='space-between' align='center' mt='md'>
          <Title fz={{ base: 'xl' }}>
            {group.alias}
          </Title>
          <ActionIcon
            variant='transparent'
            c={colorAliases.borderHighlight}
            size='sm'
            ta='center'
            onClick={openModalForAccountGroupAliasUpdate({
              iban: group.iban,
              currentAlias: group.alias,
              themeColor: accentColor,
            })}
          >
            <IconEditModifyPen style={{ height: '5rem', width: '5rem' }} />
          </ActionIcon>
        </Flex>
        <Divider color={colorAliases.borderSubtle} size='xs' mt='sm' />

        <OptionButtons
          group={group}
          mt={{ base: 'lg', desktop: 'xl' }}
          mb={{ base: '0', desktop: '3xs' }}
          withoutAccounts={true}
        // selectedAccount={group.accounts[0]}
        // FIXME: change to selected account when available
        // selectedAccountId
        />
      </Box>
      <Paper
        radius='none'
        shadow='lg'
        p='xl'
        sx={{ borderBottomLeftRadius: theme.radius.lg, borderBottomRightRadius: theme.radius.lg }}
      >
        <CSSGrid
          c='white'
          fz='xl'
          alignItems='center'
          gap='md'
          templateColumns='auto 1fr auto'
          mb='xl'
          mx='lg'
          p='md'
          bg={colorAliases.brandSecondary}
          sx={{
            borderRadius: theme.radius.lg,
          }}
        >
          <Text fw={700}>IBAN</Text>
          <Box sx={{ maxWidth: '21ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <Text fz={{ base: 'md', desktop: 'lg' }}>{formatIBAN(group.iban)}</Text>
          </Box>

          <CopyButton value={group.iban}>
            {({ copied, copy }) => (
              <Tooltip color={colorAliases.brandSecondaryLight} label='Copied' withArrow position='right' opened={copied}>
                <ActionIcon variant='transparent' color='white' onClick={copy} size='sm'>
                  <IconCopy />
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
          <Text fw={700}>BIC</Text>
          <Box>
            <Text fz={{ base: 'md', desktop: 'lg' }}>{group.bic}</Text>
          </Box>
          <CopyButton value={group.bic}>
            {({ copied, copy }) => (
              <Tooltip color={colorAliases.brandSecondaryLight} label='Copied' withArrow position='right' opened={copied}>
                <ActionIcon variant='transparent' color='white' onClick={copy} size='sm'>
                  <IconCopy />
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </CSSGrid>
        <Stack spacing='sm'>
          {group?.accounts.map((account) => {
            const accountHidden = accountId && accountId !== account.id
            return (
              <React.Fragment key={account.id}>
                <Divider size='xs' color='gray.2' />
                <CSSGrid
                  c={accountHidden ? 'muted' : 'textPrimary'}
                  fz='lg'
                  alignItems='center'
                  gap='md'
                  templateColumns='auto 1fr auto'
                  sx={{
                    userSelect: 'none',
                    cursor: 'pointer',
                    ':hover': { filter: accountId !== account.id ? 'brightness(85%)' : undefined },
                  }}
                  onClick={() =>
                    accountId && accountId === account.id
                      ? navigate(`/statement-centre/${group.iban}`)
                      : navigate(`/statement-centre/${group.iban}/${account.id}`)
                  }
                >
                  <Flag
                    countryCode={currencies[account.currency].countryCodeForFlag}
                    square={true}
                    sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
                    style={{
                      width: '3.125rem',
                      height: '3.125rem',
                      filter: accountHidden ? 'grayscale(100%) brightness(150%)' : undefined,
                    }}
                  />
                  <Flex direction='column' justify='space-between' ta='right'>
                    <Box fw={700} c={colorAliases.brandSecondary} lh={1.25}>
                      <Text fz='md'>
                        {currencies[account.currency].symbol} {currencyFormat.format(account.balance)}
                      </Text>
                    </Box>
                    <Box fw={500}>
                      <Text fz='sm' c={colorAliases.textInvertSecondary}>
                        {account.currency} / {currencies[account.currency].regionName}
                      </Text>
                    </Box>
                  </Flex>
                  <Box>
                    <ActionIcon variant='transparent' c={colorAliases.borderHighlight}>
                      {accountHidden ? <IconViewInfoEyeOff /> : <IconViewInfoEye />}
                    </ActionIcon>
                  </Box>
                </CSSGrid>
              </React.Fragment>
            )
          })}
        </Stack>
        {accountId && (
          <Flex align='center' justify='center' mt='md'>
            <Button
              onClick={() => navigate(`/statement-centre/${group.iban}`)}
              color={accentColor}
              leftIcon={<IconAccountView style={{ height: '1em' }} />}
              radius='xl'
              size='sm'
              variant='outline'
            >
              View all accounts
            </Button>
          </Flex>
        )}
      </Paper>
    </Paper>
  )
}

export default AccountsArea
