import { Badge, Box, Flex, MantineTheme, Paper, PaperProps, Title } from '@mantine/core'
import dayjs from 'dayjs'
import { PaymentRequest, PaymentRequestStatus } from '../../../../utils/interfaces'
import RequestDetails from './RequestDetails'
import { colorAliases } from '../../../../theme/getMantineTheme'

const getBorderStyleForRequestStatus = (theme: MantineTheme, status: string) => {
  if (status === PaymentRequestStatus.PUSHED_BACK) {
    return `2px solid ${theme.colors.warning[1]}`
  } else if (status === PaymentRequestStatus.REJECTED) {
    return `2px solid ${theme.colors.error[3]}`
  } else {
    return '2px solid white'
  }
}


const RequestDetailsArea: React.FC<PaperProps & { request: PaymentRequest }> = ({ request, ...props }) => {

  const getStatusColor = (status: PaymentRequestStatus) =>
    status === PaymentRequestStatus.PUSHED_BACK ? 'warning' : status === PaymentRequestStatus.READY ? colorAliases.systemSuccess : colorAliases.textInvert

  return (
    <Paper p='lg' {...props} sx={(theme) => ({ border: getBorderStyleForRequestStatus(theme, request.status) })}>
      <Box pb='sm'>
        <Flex justify='space-between' align='center'>
          <Box>
            <Title order={3} fz='lg'>
              {request.internal_reference}
            </Title>
            <Title order={4} c='textPrimary' fz='xs' fw={400} display={{ base: 'none', desktop: 'block' }}>
              Last modified on {dayjs(request.last_modified_date).format('DD MMMM, YYYY - HH:mm')}, by {request.last_modified_by}
            </Title>
            <Title order={4} c='textPrimary' fz='xs' fw={400} display={{ base: 'block', desktop: 'none' }}>
              Last modified on {dayjs(request.last_modified_date).format('DD MMMM YYYY')}, <br />
              {dayjs(request.last_modified_date).format('HH:mm')}, by {request.last_modified_by}
            </Title>
          </Box>
          <Badge tt='capitalize' p={{ base: '0 0.5rem', desktop: '0.9rem 1rem' }} fz='sm' variant='outline' color={getStatusColor(request.status)} ml='1rem'>
            {request.status.replace('-', ' ')}
          </Badge>
        </Flex>
      </Box>

      <RequestDetails request={request} />
    </Paper>
  )
}

export default RequestDetailsArea
