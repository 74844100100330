import { Chip as MantineChip, ChipProps as MantineChipProps, MantineColor } from '@mantine/core';
import React, { forwardRef } from 'react';
import { ForwardRefWithStaticComponents } from '../../ForwardRefWithStaticComponents';

import useStyles from './Chip.styles';

export interface ChipProps extends Omit<MantineChipProps, 'variant'> {
  activeBorderColor?: MantineColor;
  activeColor?: MantineColor;
  activeBackgroundColor?: MantineColor;
  inactiveBorderColor?: MantineColor;
  inactiveColor?: MantineColor;
  inactiveBackgroundColor?: MantineColor;
}

//TODO: find a way to neatly include individual chip props in groups

type ChipComponent = ForwardRefWithStaticComponents<ChipProps, { Group: typeof MantineChip.Group }>;

export const Chip: ChipComponent = forwardRef<HTMLInputElement, ChipProps>(
  (
    {
      children,
      classNames,
      activeBorderColor,
      activeColor,
      activeBackgroundColor,
      inactiveBorderColor,
      inactiveColor,
      inactiveBackgroundColor,
      size = 'md',
      radius = 'sm',
      ...props
    }: ChipProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        activeBorderColor,
        activeColor,
        activeBackgroundColor,
        inactiveBorderColor,
        inactiveColor,
        inactiveBackgroundColor,
        children,
        size,
        radius,
      },
      { classNames, name: 'custom' }
    );

    return (
      <MantineChip size={size} radius={radius} variant="filled" classNames={classes} {...props} ref={ref}>
        {children}
      </MantineChip>
    );
  }
) as any;

Chip.Group = MantineChip.Group;
