import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequest, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const authorisePaymentRequests = async (dispatch: React.Dispatch<SessionReducerAction>, requests: PaymentRequest[]) => {
  return new Promise((resolve) => {
    dispatch({
      type: SessionReducerActionType.AUTHORISE_PAYMENT_REQUESTS,
      payload: requests,
    })
    resolve(requests)
  })
}

export default function useAuthorisePaymentRequests(onSuccess?: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (requests: PaymentRequest[]) => authorisePaymentRequests(dispatch, requests),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['payment-requests', 'transactions'] })
      onSuccess && onSuccess()
    },
  })
}
