import { ActionIcon, Alert, Box, BoxProps, Button, Flex, Group, Indicator, Popover } from '@mantine/core'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchPaymentRequests from '../../api/queries/useFetchPaymentRequests'
import { AuthContext } from '../../contexts'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequestStatus } from '../../utils/interfaces'
import { CSSGrid } from '../common/CSSGrid'
import { Menu, MenuItem } from '../common/Menu'
import { IconBell, IconLogout, IconMessage, IconPaymentProcessing } from '../icons'
import { LogoLeikur } from '../logos'
import { openModalForInternalAccountTransfer } from '../modals/InternalAccountTransfer'
import { Container } from './Container'
import { colorAliases } from '../../theme/getMantineTheme'

const Notifications: React.FC<BoxProps> = () => {
  const { isLoading, data } = useFetchPaymentRequests()
  const navigate = useNavigate()
  const [notificationOpened, setNotificationOpened] = useState(false)

  if (isLoading) {
    return <></>
  }

  const hasNotif =
    data !== undefined &&
    data.some(
      (pr) =>
        pr.status === PaymentRequestStatus.PUSHED_BACK || pr.status === PaymentRequestStatus.REJECTED || pr.status === PaymentRequestStatus.PENDING_APPROVAL
    )

  const pushBacked = data?.filter((pr) => pr.status === PaymentRequestStatus.PUSHED_BACK) ?? []
  const pendingApproval = data?.filter((pr) => pr.status === PaymentRequestStatus.PENDING_APPROVAL) ?? []

  return hasNotif ? (
    <Popover opened={notificationOpened} onChange={setNotificationOpened} withArrow shadow='md' radius='md'>
      <Popover.Target>
        <Indicator color='negative' disabled={!hasNotif} mr={hasNotif ? 8 : 0} size={6} offset={5}>
          <ActionIcon color='secondary' variant='transparent' onClick={() => setNotificationOpened((o) => !o)}>
            <IconBell />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown py='md'>
        <CSSGrid gap='sm'>
          {pushBacked.length > 0 && (
            <Alert
              title='Please provide additional information'
              variant='outline'
              color='orange'
              p='sm'
              fz='xs'
              styles={{ root: { cursor: 'pointer' }, title: { marginBottom: '0.25rem' } }}
              onClick={() => navigate('/transaction-centre/payment-requests')}
            >
              You have {pushBacked.length} pushed-back payment request{pushBacked.length > 1 ? 's' : ''}.
            </Alert>
          )}
          {pendingApproval.length > 0 && (
            <Alert
              title='Please review pending payment requests'
              variant='outline'
              p='sm'
              fz='xs'
              styles={{ root: { cursor: 'pointer' }, title: { marginBottom: '0.25rem' } }}
              onClick={() => navigate('/transaction-centre/payment-approval')}
            >
              You have {pendingApproval.length} payment request{pendingApproval.length > 1 ? 's' : ''} pending approval.
            </Alert>
          )}
        </CSSGrid>
      </Popover.Dropdown>
    </Popover>
  ) : (
    <></>
  )
}

const AppNavbar: React.FC<BoxProps> = (props) => {
  const { setToken } = useContext(AuthContext)
  const { clearSession } = useContext(SessionContext)

  const [opened, setOpened] = useState(false)

  const navigate = useNavigate()

  return (
    <Box component='nav' pos='relative' h='4.25rem' {...props}>
      {/* Replace logic with pos='sticky'? */}
      <Box w='100%' pos='fixed' top={0} bg='light.7' sx={{ zIndex: 10 }}>
        <Container pos='relative' h='4.25rem'>
          <Flex pos='absolute' mx='auto' align='center' justify={{ base: 'left', desktop: 'center' }} inset={0}>
            <Box component={LogoLeikur} w='8.5rem' />
          </Flex>
          <Flex
            pos='absolute'
            mx='auto'
            align='center'
            justify={{ base: 'right', desktop: 'space-between' }}
            inset={0}
            direction={{ base: 'row-reverse', desktop: 'row' }}
          >
            <Menu
              closeButtonProps={{ size: 'lg' }}
              size={412}
              zIndex={1001}
              opened={opened}
              setOpened={setOpened}
              title={
                <Box fz='2xl' fw={700} lh={1} ta='center' w='100%' c={colorAliases.textInvert}>
                  Menu &amp; Settings
                </Box>
              }
              overlayProps={{ blur: 5, opacity: 0.2 }}
              shadow='lg'
              menuButtonTitle={
                <Box c='dark' lh='18px' display={{ base: 'none', desktop: 'block' }}>
                  Menu &amp;
                  <br />
                  Settings
                </Box>
              }
            >
              <MenuItem
                title='Account Overview'
                textColor={colorAliases.textInvert}
                description='Dashboard of all your accounts'
                onClick={() => {
                  navigate('/dashboard')
                  setOpened(false)
                }}
              />
              <MenuItem textColor={colorAliases.textInvert}
                title='Transaction
              Centre'
                description='Manage transactions and transfers'
                borderPosition='top'
                borderColor='gray.4'
              >
                <MenuItem
                  title='Initiate Payments'
                  description='Create a payment request'
                  textColor={colorAliases.textInvert}
                  align='right'
                  borderWidth='xs'
                  borderPosition='top'
                  borderColor='gray.4'
                  onClick={() => {
                    navigate('/transaction-centre')
                    setOpened(false)
                  }}
                />
                <MenuItem
                  title='Transfer Funds Internally'
                  description='Move funds between accounts'
                  textColor={colorAliases.textInvert}
                  borderWidth='xs'
                  borderPosition='top'
                  borderColor='gray.4'
                  align='right'
                  onClick={() => {
                    openModalForInternalAccountTransfer()()
                    setOpened(false)
                  }}
                />
                <MenuItem
                  title='Outstanding Authorizations'
                  textColor={colorAliases.textInvert}
                  description='Transactions that need release'
                  borderWidth='xs'
                  borderPosition='top'
                  borderColor='gray.4'
                  align='right'
                  onClick={() => {
                    navigate('/transaction-centre/payment-approval')
                    setOpened(false)
                  }}
                />
                <MenuItem
                  title='Request Management'
                  textColor={colorAliases.textInvert}
                  description='Payment Requests that need amending'
                  borderWidth='xs'
                  borderPosition='top'
                  borderColor='gray.4'
                  align='right'
                  onClick={() => {
                    navigate('/transaction-centre/payment-requests')
                    setOpened(false)
                  }}
                />
              </MenuItem>
              <MenuItem
                title='Statement Centre'
                textColor={colorAliases.textInvert}
                description='Analytics, transaction history, downloads'
                borderPosition='top'
                borderColor='gray.4'
                onClick={() => {
                  navigate('/statement-centre')
                  setOpened(false)
                }}
              />
              <MenuItem
                title='Beneficiary Centre'
                textColor={colorAliases.textInvert}
                description='Add and manage beneficiaries'
                borderPosition='top'
                borderColor='gray.4'
                onClick={() => {
                  navigate('/beneficiary-centre')
                  setOpened(false)
                }}
              />
              <MenuItem
                title='Payment Processing'
                textColor={colorAliases.textInvert}
                description='Navigate to Leikur gateway'
                borderPosition='top'
                borderColor='gray.4'
                onClick={() => {
                  window.open('https://gateway.leikur.com/sign-in', '_blank')
                  setOpened(false)
                }}
              />
              <MenuItem
                title='Account Profile'
                textColor={colorAliases.textInvert}
                description='Privileges, update info, personalization'
                borderPosition='top'
                borderColor='gray.4'
              >
                <MenuItem
                  title='Profile Information'
                  description='Passwords, manage info, address'
                  textColor={colorAliases.textInvert}
                  borderWidth='xs'
                  borderPosition='top'
                  borderColor='gray.4'
                  align='right'
                />
              </MenuItem>

              {/* <MenuItem
                title='Mail, News & Security Info'
                textColor={colorAliases.textInvert}
                description='Latest news & product updates'
                borderWidth='sm'
                borderPosition='top'
                textColor='textPrimary'
              /> */}

              <MenuItem
                title='Help & Contact Us'
                textColor={colorAliases.textInvert}
                description='Reach out for assistance & to give us feedback'
                borderWidth='xs'
                borderPosition='top'
                borderColor='gray.4'
              />

              {/* <MenuItem
                title='Legal Documents'
                textColor={colorAliases.textInvert}
                description='Dashboard of all legal information'
                borderWidth='sm'
                borderPosition='top'
                textColor='textPrimary'
              /> */}

              <MenuItem
                title='Log Out'
                textColor={colorAliases.textInvert}
                description='Log out of your banking profile'
                borderWidth='xs'
                borderPosition='top and bottom'
                borderColor='gray.4'
                onClick={() => {
                  setToken('')
                  clearSession()
                }}
              />
            </Menu>
            <Group spacing='2xs'>
              <Notifications />
              <Group spacing='2xs' display={{ base: 'none', desktop: 'flex' }}>
                <Button
                  leftIcon={<IconPaymentProcessing />}
                  color='secondary'
                  variant='subtle'
                  size='medium'
                  py={0}
                  px='xs'
                  onClick={() => {
                    window.open('https://gateway.leikur.com/sign-in', '_blank')
                    setOpened(false)
                  }}
                >
                  <Box c='dark' lh='18px'>
                    Payment <br />
                    Processing
                  </Box>
                </Button>
                <Button leftIcon={<IconMessage />} color='secondary' variant='subtle' size='medium' py={0} px='xs'>
                  <Box c='dark' lh='18px'>
                    Message <br />+ Contact
                  </Box>
                </Button>
                <Button
                  leftIcon={<IconLogout />}
                  color='secondary'
                  variant='subtle'
                  size='medium'
                  py={0}
                  px='xs'
                  onClick={() => {
                    setToken('')
                    clearSession()
                  }}
                >
                  <Box c='dark' lh='18px'>
                    Log <br /> Out
                  </Box>
                </Button>
              </Group>
            </Group>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default AppNavbar
