import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SessionReducerActionType, SessionReducerAction, PaymentRequest } from '../../utils/interfaces'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'

const updatePaymentTemplate = async (dispatch: React.Dispatch<SessionReducerAction>, template: PaymentRequest) => {
  return new Promise((resolve) => {
    dispatch({
      type: SessionReducerActionType.EDIT_PAYMENT_TEMPLATE,
      payload: template,
    })
    resolve(template)
  })
}

export default function useUpdatePaymentTemplate(onSuccess: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (template: PaymentRequest) => updatePaymentTemplate(dispatch, template),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['payment-templates'] })
      onSuccess()
    },
  })
}
