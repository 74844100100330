import { Box, CloseButton } from '@mantine/core'
import * as React from 'react'
import { CSSGrid, CSSGridProps } from '../common/CSSGrid'

interface ModalWrapperProps {
  onClose: () => void
  title?: string
  themeColor?: string
}

const ModalWrapper: React.FC<CSSGridProps & ModalWrapperProps> = ({
  title = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  themeColor = 'primary',
  onClose,
  children,
  ...props
}) => {
  return (
    <Box
      // works with padding in the modal config (./index.tsx) to have the scrollbar appear nicely when needed
      style={{
        maxHeight: 'calc(100dvh - (5dvh * 2) - 2rem)',
        overflowY: 'auto',
        padding: '0 0.75rem',
        margin: '1rem',
      }}
    >
      <CSSGrid templateColumns='1fr auto' alignItems='center' mt='3xs' mb='sm' {...props}>
        <Box c='dark' fw='bold' fz='lg' ta='center'>
          {title}
        </Box>
        <CloseButton size='lg' onClick={onClose} variant='transparent' />
      </CSSGrid>
      {children}
    </Box>
  )
}

export default ModalWrapper
