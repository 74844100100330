import { MantineTheme, InputBaseProps } from '@mantine/core'

export default function setCustomInputColorStyles(color = 'primary', variant = 'filled') {
  return (theme: MantineTheme, props: InputBaseProps) => ({
    input: {
      borderColor: variant !== 'filled' ? theme.fn.themeColor('primary', 1) : undefined,
      backgroundColor: variant === 'filled' ? theme.fn.lighten(theme.fn.themeColor(color), 0.9) : undefined,
      ':focus, :focus-within': { borderColor: props?.error ? theme.fn.themeColor('error') : theme.fn.themeColor(color) },
      '&[data-invalid]:focus, &[data-invalid]:focus-within': { borderColor: theme.fn.themeColor('error') }
    },
    label: { color: theme.fn.themeColor(color) },
  })
}