import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Beneficiary } from '../../utils/interfaces'
import { delayEmulation } from '../config'

const fetchBeneficiaries = async (beneficiaries: Beneficiary[]) => {
  return new Promise<Beneficiary[]>((resolve, reject) => {
    delayEmulation()
      .then(() => resolve(beneficiaries ?? []))
      .catch(() => reject())
  })
}

export default function useFetchBeneficiaries() {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['beneficiaries', session.beneficiaries],
    queryFn: () => fetchBeneficiaries(session.beneficiaries),
  })
}
