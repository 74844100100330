import { Box, BoxProps, Button, Flex, Group, Paper } from '@mantine/core'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts'
import { SessionContext } from '../../contexts/SessionContext'
import { OnboardingSessionContext } from '../../contexts/onboarding/OnboardingContext'
import { Menu, MenuItem } from '../common/Menu'
import { IconLogout, IconMessage } from '../icons'
import { LogoLeikur } from '../logos'
import { Container } from './Container'
import { colorAliases } from '../../theme/getMantineTheme'

const OnboardingAppNavbar: React.FC<BoxProps & { forSignup: boolean }> = ({ forSignup, ...props }) => {
  const { setToken } = useContext(AuthContext)
  const { clearSession } = useContext(SessionContext)
  const { clearOnboardingSession } = useContext(OnboardingSessionContext)
  const [opened, setOpened] = useState(false)

  const navigate = useNavigate()

  const navbarHeight = forSignup ? '5rem' : '4rem'
  const logoWidth = forSignup ? '11rem' : '9.5rem'
  return (
    <Box component='nav' pos='relative' h={navbarHeight} {...props}>
      {/* Replace logic with pos='sticky'? */}
      <Paper w='100%' radius={0} pos='fixed' top={0} bg='light.7' sx={{ zIndex: 10 }}>
        <Container pos='relative' h={navbarHeight}>
          <Flex pos='absolute' mx='auto' align='center' justify={{ base: forSignup ? 'center' : 'left', desktop: forSignup ? 'left' : 'center' }} inset={0}>
            <Box component={LogoLeikur} w={logoWidth} sx={{ path: { fill: 'dark' } }} />
          </Flex>

          <Flex pos='absolute' mx='auto' align='center' justify={{ base: 'right', desktop: forSignup ? 'right' : 'space-between' }} inset={0}>
            {!forSignup && (
              <Menu
                closeButtonProps={{ size: 'lg' }}
                size={412}
                zIndex={1001}
                opened={opened}
                setOpened={setOpened}
                title={
                  <Box fz='2xl' fw={700} lh={1} ta='center' w='100%' c='dark'>
                    Menu &amp; Settings
                  </Box>
                }
                overlayProps={{ blur: 5, opacity: 0.2 }}
                shadow='lg'
                menuButtonTitle={
                  <Box c='dark' lh='18px' display={{ base: 'none', desktop: 'block' }}>
                    Menu &amp;
                    <br />
                    Settings
                  </Box>
                }
              >
                <MenuItem
                  textColor={colorAliases.textInvert}
                  title='Onboarding Dashboard'
                  description='Initiate and manage your onboarding process'
                  borderWidth='sm'
                  borderPosition='top'
                  borderColor='gray.4'
                  onClick={() => {
                    navigate('/onboarding/dashboard')
                    setOpened(false)
                  }}
                />

                <MenuItem
                  textColor={colorAliases.textInvert}
                  title='Account Profile'
                  description='Privileges, update info, personalization'
                  borderWidth='sm'
                  borderPosition='top'
                  borderColor='gray.4'
                >
                  <MenuItem
                    textColor={colorAliases.textInvert}
                    title='Profile Information'
                    description='Passwords, manage info, address'
                    borderWidth='xs'
                    borderPosition='top'
                    borderColor='gray.4'
                    align='right'
                  />
                </MenuItem>

                {/* <MenuItem
                textColor={colorAliases.textInvert}
                  title='Mail, News & Security Info'
                  description='Latest news & product updates'
                  borderWidth='sm'
                  borderPosition='top'
                  borderColor='gray.4'
                  textColor='textPrimary'
                /> */}

                <MenuItem
                  textColor={colorAliases.textInvert}
                  title='Help & Contact Us'
                  description='Reach out for assistance & to give us feedback'
                  borderWidth='sm'
                  borderPosition='top'
                  borderColor='gray.4'
                />

                {/* <MenuItem
                textColor={colorAliases.textInvert}
                  title='Legal Documents'
                  description='Dashboard of all legal information'
                  borderWidth='sm'
                  borderPosition='top'
                  borderColor='gray.4'
                  textColor='textPrimary'
                /> */}

                <MenuItem
                  textColor={colorAliases.textInvert}
                  title='Log Out'
                  description='Log out of your banking profile'
                  borderWidth='sm'
                  borderColor='gray.4'
                  borderPosition='top and bottom'
                  onClick={() => {
                    setToken('')
                    clearSession()
                    clearOnboardingSession()
                  }}
                />
              </Menu>
            )}
            <Group spacing='2xs' display={{ base: 'none', desktop: 'flex' }}>
              <Button leftIcon={<IconMessage />} color='secondary' variant='subtle' size='medium' py={0} px='xs'>
                <Box c='dark' lh='18px'>
                  Message <br />+ Contact
                </Box>
              </Button>
              {!forSignup && (
                <Button
                  leftIcon={<IconLogout />}
                  color='secondary'
                  variant='subtle'
                  size='medium'
                  py={0}
                  px='xs'
                  onClick={() => {
                    setToken('')
                    clearSession()
                    clearOnboardingSession()
                  }}
                >
                  <Box c='dark' lh='18px'>
                    Log <br /> Out
                  </Box>
                </Button>
              )}
              {forSignup && (
                <Button
                  leftIcon={<IconLogout />}
                  color='secondary'
                  variant='subtle'
                  size='medium'
                  py={0}
                  px='xs'
                  onClick={() => {
                    navigate('/sign-in')
                  }}
                >
                  <Box c='dark' lh='18px'>
                    Log <br /> In
                  </Box>
                </Button>
              )}
            </Group>
          </Flex>
        </Container>
      </Paper>
    </Box>
  )
}

export default OnboardingAppNavbar
