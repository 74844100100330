import { Session } from '../utils/interfaces'

export const defaultAccountDetails = (iban: string) => ({
  account_addresses: [
    {
      id: 'string',
      verification_status: 'unverified',
      last_verified: '2023-05-24',
      name: 'string',
      status: 'active',
      currency: 'EUR',
      bank_account_details: {
        account_holder_name: 'Leikur UAB',
        iban: iban,
        bic: 'CNUALT21',
        bank_name: 'ConnectPay UAB',
        bank_address: 'Algirdo st. 38, Vilnius, LT-03218, Lithuania',
        payment_rail: 'uk_local',
      },
      audit: {
        created_by: 'string',
        created_on: '2023-05-24T14:15:22Z',
        last_updated_by: 'string',
        last_updated_on: '2023-05-24T14:15:22Z',
      },
    },
  ],
  account_holder_name: 'Leikur UAB',
  account_holder_type: 'individual',
  other_identifiers: [
    {
      id: 'string',
      name: 'string',
      source: 'string',
      type: 'individual',
      valid_from: '2023-05-24T14:15:22Z',
      valid_to: '2023-05-24T14:15:22Z',
    },
  ],
  status: 'active',
})

export default {
  version: 15,
  accountGroupInfoList: [
    {
      iban: 'LT693897617946254898',
      bic: 'CNUALT21',
      order: 0,
      alias: 'Operational',
      theme: 'green',
      category: 'operational',
    },
    {
      iban: 'LT342428681429253849',
      bic: 'CNUALT21',
      order: 1,
      alias: 'Merchant',
      theme: 'purple',
      category: 'merchant',
    },
    {
      iban: 'LT179239139767194895',
      bic: 'CNUALT21',
      order: 2,
      alias: 'Reserves',
      theme: 'gold',
      category: 'reserves',
    },
    {
      iban: 'LT825357579971364835',
      bic: 'CNUALT21',
      order: 4,
      alias: 'The Project',
      theme: 'blue',
      category: 'miscellaneous',
    },
  ],
  accounts: [
    {
      ...defaultAccountDetails('LT693897617946254898'),
      id: '1bb5ae83-e4b1-43cc-b358-9dfc714f6158',
      iban: 'LT693897617946254898',
      balance: 145050,
      currency: 'EUR',
      alias: 'Operational',
    },
    {
      ...defaultAccountDetails('LT693897617946254898'),
      id: '28ce7bde-9d0f-4def-b369-9792b51c9dbc',
      iban: 'LT693897617946254898',
      balance: 35750,
      currency: 'USD',
      alias: 'Operational',
    },
    {
      ...defaultAccountDetails('LT693897617946254898'),
      id: '2e185564-2d6e-4d88-b509-242a5fe75bee',
      iban: 'LT693897617946254898',
      balance: 14180,
      currency: 'GBP',
      alias: 'Operational',
    },
    {
      ...defaultAccountDetails('LT342428681429253849'),
      id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      iban: 'LT342428681429253849',
      balance: 450268,
      currency: 'EUR',
      alias: 'Merchant',
    },
    {
      ...defaultAccountDetails('LT342428681429253849'),
      id: '81ce1600-3335-403e-a1a2-bed6cf8a8299',
      iban: 'LT342428681429253849',
      balance: 125452,
      currency: 'SEK',
      alias: 'Merchant',
    },
    {
      ...defaultAccountDetails('LT179239139767194895'),
      id: '9350ba2b-a090-47be-875e-4591a668ef4d',
      iban: 'LT179239139767194895',
      balance: 120000,
      currency: 'EUR',
      alias: 'Reserves',
    },
    {
      ...defaultAccountDetails('LT179239139767194895'),
      id: 'a356aa34-2f41-4197-85f2-9c4318eae5f7',
      iban: 'LT179239139767194895',
      balance: 80000,
      currency: 'SEK',
      alias: 'Reserves',
    },
    {
      ...defaultAccountDetails('LT825357579971364835'),
      id: 'e2ffa5c6-093b-4a79-9bf7-0f06f5c078ef',
      iban: 'LT825357579971364835',
      balance: 42000,
      currency: 'EUR',
      alias: 'The Project',
    },
  ],
  transactions: [
    {
      created_at: '2023-06-02T14:15:24.900Z',
      id: '6af99c67-f212-4cd5-8371-987a404acbf1',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: 750000,
      currency: 'EUR',
      reference: 'SSN_113_795',
      request_id: '3454314d-791b-419c-b7cb-35fe7cfaccf9',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'Multilever Incubator',
        iban: 'PT32003506513233999616792',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-03T15:17:24.900Z',
      id: '6d15b1cb-cc26-41b8-80de-e2445bec0573',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: -250000,
      currency: 'EUR',
      reference: 'Internal/setup ops',
      request_id: '245a7c7b-6a5e-4660-b527-41456dabab00',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'Leikur UAB',
        iban: 'LT693897617946254898',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-03T15:17:25.900Z',
      id: '848d2dc9-1859-4d0a-8dca-67e84b259dd6',
      account_id: '1bb5ae83-e4b1-43cc-b358-9dfc714f6158',
      amount: 250000,
      currency: 'EUR',
      reference: 'Internal/setup ops',
      request_id: '245a7c7b-6a5e-4660-b527-41456dabab00',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'Leikur UAB',
        iban: 'LT342428681429253849',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-03T15:22:28.900Z',
      id: 'd68d84a5-d1b8-4644-b241-0b845b7863f3',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: -300000,
      currency: 'EUR',
      reference: 'Internal/to reserves',
      request_id: 'a7afda46-94e1-4362-842b-a5799bd5de5f',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'Leikur UAB',
        iban: 'LT179239139767194895',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-03T15:22:29.000Z',
      id: 'ceee8b0b-e5b5-4270-8ac0-6204ea0b7950',
      account_id: '9350ba2b-a090-47be-875e-4591a668ef4d',
      amount: 300000,
      currency: 'EUR',
      reference: 'Internal/to reserves',
      request_id: 'a7afda46-94e1-4362-842b-a5799bd5de5f',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'Leikur UAB',
        iban: 'LT342428681429253849',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-03T15:28:12.000Z',
      id: '90528038-8de9-4c3d-b84f-3827bfdb7e5f',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: -20000,
      currency: 'EUR',
      reference: 'Internal/Forex from EUR to SEK',
      request_id: 'b7b3b63c-92c8-4a9c-9ba7-3f85866fb0c9',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'Leikur UAB',
        iban: 'LT342428681429253849',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-03T15:28:14.000Z',
      id: '9a0695f0-55fa-4e6a-84e3-8a6d6c59b110',
      account_id: '81ce1600-3335-403e-a1a2-bed6cf8a8299',
      amount: 237557,
      currency: 'SEK',
      reference: 'Internal/from EUR to SEK',
      request_id: 'b7b3b63c-92c8-4a9c-9ba7-3f85866fb0c9',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'Leikur UAB',
        iban: 'LT342428681429253849',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-05T12:48:14.000Z',
      id: '6d3ecac9-8699-41b8-a2c3-a43188aafcbf',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: 5000,
      currency: 'EUR',
      reference: 'Reinforced Screws INV#2150',
      request_id: 'af3841bd-1a56-4a31-9651-cb869a6fb010',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'SafeMiners Group',
        iban: 'AE180216919817752518634',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-05T13:55:14.000Z',
      id: 'f94babe4-08cf-45bc-b32f-fd40c2f5fb55',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: 2000,
      currency: 'EUR',
      reference: 'Metal Rod INV#2511',
      request_id: '37c2d3c2-c921-4121-b05a-41739182ac52',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'SafeMiners Group',
        iban: 'AE180216919817752518634',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-05T14:48:14.000Z',
      id: '457e43a2-647a-4a92-83b4-24e5feb35b57',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: 17000,
      currency: 'EUR',
      reference: 'Diamond Tips INV#2211',
      request_id: '7d3a8ea6-cdaa-453d-8c2a-4df7871e5243',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'SafeMiners Group',
        iban: 'AE180216919817752518634',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-05T15:42:14.000Z',
      id: 'edb45efc-b3fa-466b-8955-fdfbc1de1d91',
      account_id: '6ffdccd0-c09d-4c0f-8968-c7e8fadeae98',
      amount: 23000,
      currency: 'EUR',
      reference: 'Titanium Drills INV#2212',
      request_id: '2a9d9f46-36fa-4899-8855-d4ed6f7ae8e2',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'SafeMiners Group',
        iban: 'AE180216919817752518634',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-12T15:42:14.000Z',
      id: '2fd0f9d4-425e-4c1b-8230-bc942284d42f',
      account_id: '1bb5ae83-e4b1-43cc-b358-9dfc714f6158',
      amount: -1500,
      currency: 'EUR',
      reference: 'Travelling fees',
      request_id: '14020d28-f637-4a4c-810b-031c1ab50f7e',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'M.Smithson LLC',
        iban: 'CY64002001950000357034975049',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-14T15:42:14.000Z',
      id: '1e202815-7fa3-4c04-a817-58b70b9784af',
      account_id: '28ce7bde-9d0f-4def-b369-9792b51c9dbc',
      amount: -600,
      currency: 'USD',
      reference: 'Security maintenance',
      request_id: '48084aea-bbf4-4926-8e3d-c71e69ad9b6a',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'SecTor LLC',
        iban: 'LT211424198567321635',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-25T15:42:14.000Z',
      id: 'aa14faae-e19b-4b5a-9a91-ee019455559f',
      account_id: '2e185564-2d6e-4d88-b509-242a5fe75bee',
      amount: -250,
      currency: 'GBP',
      reference: 'Consultancy services',
      request_id: '193c8e9d-47c8-42d6-8d58-1b2fb0d8479b',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'Jonathan Smith',
        iban: 'GB95BARC20035349761423',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-28T15:42:14.000Z',
      id: 'db1da13c-c78f-48a4-87d8-bcfbff702d61',
      account_id: '81ce1600-3335-403e-a1a2-bed6cf8a8299',
      amount: -24220,
      currency: 'SEK',
      reference: 'Emanag fraud prevention #8474',
      request_id: '3cd6e902-2c52-48d1-8bfc-4d5c32f15acd',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'E-Management N.V.',
        iban: 'MT21FIND97000000000000201010648',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-06-29T11:22:16.000Z',
      id: 'a3f288fc-412a-47d6-a34e-e51fed1484a9',
      account_id: '1bb5ae83-e4b1-43cc-b358-9dfc714f6158',
      amount: -1700,
      currency: 'EUR',
      reference: 'Camera installations',
      request_id: 'b0589647-f40b-4ab0-881c-233710afccc3',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'Intellivision',
        iban: 'LT541923472742358195',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-07-02T11:22:16.000Z',
      id: 'a7eb3d85-f4d6-46e1-8350-b26c648acc10',
      account_id: '1bb5ae83-e4b1-43cc-b358-9dfc714f6158',
      amount: -250,
      currency: 'EUR',
      reference: 'Travelling expenses',
      request_id: '1d03fe85-151d-4b25-809a-d768d9e5da8c',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'M.Smithson LLC',
        iban: 'CY64002001950000357034975049',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-07-06T11:22:16.000Z',
      id: 'fe785def-256f-4c72-a3be-f43e34e52a71',
      account_id: '28ce7bde-9d0f-4def-b369-9792b51c9dbc',
      amount: -2400,
      currency: 'USD',
      reference: 'Anti-phishing training',
      request_id: 'bbcfe9db-aeb8-44cd-8615-702c9db7bad2',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'RUN Communication AB',
        iban: 'SE1195000099604219399807',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-07-14T16:22:16.000Z',
      id: 'd0171b76-8708-474f-83e5-4cf226c84051',
      account_id: '28ce7bde-9d0f-4def-b369-9792b51c9dbc',
      amount: -600,
      currency: 'USD',
      reference: 'Security maintenance',
      request_id: 'dbff025a-09c8-4ac4-8879-52fcb725ad61',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'SecTor LLC',
        iban: 'LT211424198567321635',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-07-15T11:22:16.000Z',
      id: 'a963ef2d-e84c-437e-9e3c-73a2136f3bd1',
      account_id: '1bb5ae83-e4b1-43cc-b358-9dfc714f6158',
      amount: -5300,
      currency: 'EUR',
      reference: 'Self-defence training',
      request_id: '359fb117-50e5-4bd2-9a5f-08afbda09b17',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'Francois Delarue',
        iban: 'FR5110096000508451484398Z61',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-07-25T11:22:16.000Z',
      id: 'ff40e43e-5b74-4d60-9273-8ecb8088c69a',
      account_id: '2e185564-2d6e-4d88-b509-242a5fe75bee',
      amount: -250,
      currency: 'GBP',
      reference: 'Consultancy services',
      request_id: '09ed46ae-9e08-4080-8acc-31cba34470e6',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'Jonathan Smith',
        iban: 'GB95BARC20035349761423',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-07-30T11:22:16.000Z',
      id: '01f71bd9-2085-43b8-b22f-447c24f0146e',
      account_id: '81ce1600-3335-403e-a1a2-bed6cf8a8299',
      amount: -12000,
      currency: 'SEK',
      reference: 'Internal chatbot Implementation',
      request_id: '0c536267-8661-412a-81a5-fe65498998f9',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'SmaterAI Ltd',
        iban: 'LT425194892699222191',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-08-02T11:22:16.000Z',
      id: 'b61c7ea1-2753-4a1c-9409-d999deb1ba09',
      account_id: '1bb5ae83-e4b1-43cc-b358-9dfc714f6158',
      amount: -250,
      currency: 'EUR',
      reference: 'Travelling expenses',
      request_id: '23c11f53-e6a3-45d5-ac31-c9e5f052a008',
      counterparty: {
        type: 'sepa',
        account_holder_name: 'M.Smithson LLC',
        iban: 'CY64002001950000357034975049',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      created_at: '2023-08-14T11:22:16.000Z',
      id: '9ad3a3b1-4227-405e-8a01-5af4a650d2bd',
      account_id: '28ce7bde-9d0f-4def-b369-9792b51c9dbc',
      amount: -600,
      currency: 'USD',
      reference: 'Security maintenance',
      request_id: '16349ea5-df8f-40a0-a4d8-07885a3fa9f0',
      counterparty: {
        type: 'leikur',
        account_holder_name: 'SecTor LLC',
        iban: 'LT211424198567321635',
      },
      status: 'completed',
      type: 'bank-transfer',
    },
  ],
  beneficiaries: [
    {
      id: '2712ff1e-0c88-4b56-8716-faca24aebffd',
      name: 'M.Smithson LLC',
      alias: 'Smithson',
      payment_type: 'sepa',
      entity_type: 'corporate',
      currency: 'EUR',
      email: 'billing@msmithson.com',
      country: 'CY',
      status: 'active',
      account_details: {
        type: 'sepa',
        account_holder_name: 'M.Smithson LLC',
        bic: 'BCYPCY2N',
        iban: 'CY64002001950000357034975049',
        bank_name: 'Bank of Cyprus',
      },
      default_payment: {
        amount: 500,
        popEmail: 'billing@msmithson.com',
        popMobileNumber: {
          countryCode: 'CY',
          localPhoneNumber: '22123123',
          diallingCode: '357',
          isValid: true,
          formattedPhoneNumber: '+357 22 123123',
        },
        documents: [
          {
            document_id: '2fbfd74d-8e2d-4dad-96ba-13a27e871d8a',
            uploaded_at: 1693922494235,
            document_name: 'M smithson INVOICE.pdf',
            document_url: '/dummy/dummy-invoice.pdf',
          },
        ],
      },
    },
    {
      id: '68ccf2c0-cd0a-4512-b4d5-d4bf791e3a3b',
      name: 'E-Management N.V.',
      alias: 'Noah',
      payment_type: 'sepa',
      entity_type: 'corporate',
      currency: 'SEK',
      email: 'admin@emanagement.com',
      country: 'SE',
      status: 'active',
      account_details: {
        type: 'sepa',
        account_holder_name: 'E-Management N.V.',
        bic: 'FINDMTMTXXX',
        iban: 'MT21FIND97000000000000201010648',
        bank_name: 'FINDUCTIVE LTD',
      },
      default_payment: {
        amount: 1000,
        popEmail: 'admin@emanagement.com',
        popMobileNumber: {
          countryCode: 'SE',
          localPhoneNumber: '135380985',
          diallingCode: '46',
          isValid: true,
          formattedPhoneNumber: '+46 13 538 09 85',
        },
        documents: [
          {
            document_id: '85bda6c7-cc07-4ca3-bf92-4187ac2d2728',
            uploaded_at: 1693922494235,
            document_name: 'EManagementContract.pdf',
            document_url: '/dummy/dummy-contract.pdf',
          },
          {
            document_id: '34b337e3-93cc-4369-b9fd-8f15cec3353e',
            uploaded_at: 1693922494235,
            document_name: 'EManagementAgreement.pdf',
            document_url: '/dummy/dummy-agreement.pdf',
          },
        ],
      },
    },
    {
      id: '46db1a1c-d207-4430-8824-0677993d5aa7',
      name: 'RUN Communication AB',
      alias: 'RunCom',
      payment_type: 'sepa',
      entity_type: 'corporate',
      currency: 'USD',
      email: 'jdoe@runcom.com',
      country: 'MT',
      status: 'active',
      account_details: {
        type: 'sepa',
        account_holder_name: 'RUN Communication AB',
        bic: 'NDEASESS',
        iban: 'SE1195000099604219399807',
        bank_name: 'Nordea/PlusGirot',
      },
      default_payment: {
        amount: 250,
        popEmail: 'jdoe@runcom.com',
        popMobileNumber: {
          countryCode: 'MT',
          localPhoneNumber: '27233959',
          diallingCode: '356',
          isValid: true,
          formattedPhoneNumber: '+356 2723 3959',
        },
        documents: [
          {
            document_id: '45099d9a-8807-4245-9b84-2d05e4857503',
            uploaded_at: 1693922494235,
            document_name: 'RUNComm-recur-invoice.jpg',
            document_url: '/dummy/dummy-invoice.pdf',
          },
          {
            document_id: 'c6e3be63-7131-4dda-812e-c7940f37c268',
            uploaded_at: 1693922494235,
            document_name: 'RUNComm-contract.pdf',
            document_url: '/dummy/dummy-contract.pdf',
          },
        ],
      },
    },
    {
      id: 'd969610e-c90f-482e-8067-5e370ab6c193',
      name: 'Intellivision',
      alias: 'Intellivision',
      payment_type: 'leikur',
      entity_type: 'corporate',
      currency: 'EUR',
      email: 'intellivision@email.com',
      country: 'LT',
      status: 'active',
      account_details: {
        type: 'leikur',
        account_holder_name: 'Intellivision',
        iban: 'LT541923472742358195',
        email: 'intellivision@email.com',
      },
      default_payment: {
        amount: 100,
        popEmail: 'intellivision@email.com',
        popMobileNumber: {
          countryCode: 'DE',
          localPhoneNumber: '8979690472',
          diallingCode: '49',
          isValid: true,
          formattedPhoneNumber: '+49 89 79690472',
        },
        documents: [
          {
            document_id: '80f2ef3a-74e7-44cf-8215-f37393ded2a1',
            uploaded_at: 1693922494235,
            document_name: 'intellivision-contract.pdf',
            document_url: '/dummy/dummy-contract.pdf',
          },
        ],
      },
    },
    {
      id: '732102af-e1b1-434d-89de-5656f019f092',
      name: 'SmaterAI Ltd',
      alias: 'SmartAI',
      payment_type: 'leikur',
      entity_type: 'corporate',
      currency: 'SEK',
      email: 'smartai@email.com',
      country: 'LT',
      status: 'active',
      account_details: {
        type: 'leikur',
        account_holder_name: 'SmaterAI Ltd',
        iban: 'LT425194892699222191',
        email: 'smartai@email.com',
      },
      default_payment: {
        amount: 300,
        documents: [],
      },
    },
    {
      id: 'dfb82308-3e45-41b4-81a1-7cf7a0fbbe02',
      name: 'SecTor LLC',
      alias: 'Sector',
      payment_type: 'leikur',
      entity_type: 'corporate',
      currency: 'USD',
      email: 'sector@email.com',
      country: 'LT',
      status: 'active',
      account_details: {
        type: 'leikur',
        account_holder_name: 'SecTor LLC',
        iban: 'LT211424198567321635',
        email: 'sector@email.com',
      },
      default_payment: {
        amount: 600,
        popEmail: 'sector@email.com',
        popMobileNumber: {
          countryCode: 'US',
          localPhoneNumber: '5174764690',
          diallingCode: '1',
          isValid: true,
          formattedPhoneNumber: '+1 517 476 4690',
        },
        documents: [
          {
            document_id: '82e472e4-cdc8-4523-881c-c3e5ec3e6204',
            uploaded_at: 1693922494235,
            document_name: 'sector-sec-maintenance.pdf',
            document_url: '/dummy/dummy-contract.pdf',
          },
        ],
      },
    },
    {
      id: 'd71fc9a5-e57b-4604-97d4-e7ceddc24113',
      name: 'Jonathan Smith',
      alias: 'John',
      payment_type: 'sepa',
      entity_type: 'individual',
      currency: 'GBP',
      email: 'john@email.com',
      country: 'GB',
      status: 'active',
      account_details: {
        type: 'sepa',
        account_holder_name: 'Jonathan Smith',
        bic: 'BKNLGB22XXX',
        iban: 'GB95BARC20035349761423',
        bank_name: 'Bank North Limited',
      },
      default_payment: {
        amount: 250,
        popEmail: 'john@email.com',
        documents: [
          {
            document_id: '0cec032e-8c3a-477d-82c2-8070428fabee',
            uploaded_at: 1693922494235,
            document_name: 'johnsmith-consultancy-invoice.pdf',
            document_url: '/dummy/dummy-invoice.pdf',
          },
        ],
      },
    },
    {
      id: '57a66d03-b48f-47f5-914c-459d02b9a2fd',
      name: 'Francois Delarue',
      alias: 'Francois',
      payment_type: 'sepa',
      entity_type: 'individual',
      currency: 'EUR',
      email: 'francois@email.com',
      country: 'FR',
      status: 'active',
      account_details: {
        type: 'sepa',
        account_holder_name: 'Francois Delarue',
        bic: 'CCBPFRPPALB',
        iban: 'FR5110096000508451484398Z61',
        bank_name: 'Banque Populaire',
      },
      default_payment: {
        amount: 250,
        popEmail: 'francois@email.com',
        documents: [],
      },
    },
  ],
  paymentTemplates: [
    {
      id: 'dabfcf89-f8a8-4791-97db-6401311e04d5',
      internal_reference: 'Security maintenance by Sector',
      currency: 'USD',
      last_modified_date: 1692546955000,
      last_modified_by: 'admin',
      created_by: 'admin',
      status: 'template',
      statusNote: '',
      beneficiaries: [
        {
          id: 'dfb82308-3e45-41b4-81a1-7cf7a0fbbe02',
          reference: 'Security maintenance',
          name: 'SecTor LLC',
          amount: 600,
          popEmail: 'sector@email.com',
          documents: [
            {
              document_id: '82e472e4-cdc8-4523-881c-c3e5ec3e6204',
              uploaded_at: 1693922494235,
              document_name: 'sector-sec-maintenance.pdf',
              document_url: '/dummy/dummy-contract.pdf',
            },
            {
              document_id: '82e472e4-cdc8-4523-1111-c3e5ec3e6204',
              uploaded_at: 1693922494235,
              document_name: 'sector-sec-maintenance-inv.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
          ],
        },
      ],
    },
    {
      id: '9c8dbbd2-057b-425f-bccb-7a44074b7cfc',
      internal_reference: 'John - Consultancy',
      currency: 'GBP',
      last_modified_date: 1683274980000,
      last_modified_by: 'admin',
      status: 'template',
      statusNote: '',
      created_by: 'admin',
      beneficiaries: [
        {
          id: 'd71fc9a5-e57b-4604-97d4-e7ceddc24113',
          reference: 'Consultancy services',
          name: 'Jonathan Smith',
          amount: 250,
          popEmail: 'john@email.com',
          documents: [
            {
              document_id: '0cec032e-8c3a-477d-82c2-8070428fabee',
              uploaded_at: 1693922494235,
              document_name: 'johnsmith-consultancy-invoice.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
          ],
        },
      ],
    },
  ],
  paymentRequests: [
    {
      id: 'eeb47a6b-ccfd-41be-852a-076f76aee330',
      internal_reference: 'Smithson - Travelling expenses',
      currency: 'EUR',
      last_modified_date: 1692546955000,
      last_modified_by: 'admin',
      status: 'pending-approval',
      statusNote: '',
      created_by: 'admin',
      beneficiaries: [
        {
          id: '2712ff1e-0c88-4b56-8716-faca24aebffd',
          reference: 'Travelling expenses',
          name: 'M.Smithson LLC',
          amount: 250,
          popEmail: 'billing@msmithson.com',
          popMobileNumber: {
            countryCode: 'CY',
            localPhoneNumber: '22123123',
            diallingCode: '357',
            isValid: true,
            formattedPhoneNumber: '+357 22 123123',
          },
          documents: [
            {
              document_id: '2fbfd74d-8e2d-4dad-96ba-13a27e871d8a',
              uploaded_at: 1693922494235,
              document_name: 'M smithson INVOICE.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
          ],
        },
      ],
    },
    {
      id: 'eec47a6b-ccfd-41be-852a-076f76aee330',
      internal_reference: 'Smithson - Other',
      currency: 'EUR',
      last_modified_date: 1692546955000,
      last_modified_by: 'admin',
      status: 'pushed-back',
      statusNote: 'Please provide more details about travel expenses',
      created_by: 'admin',
      beneficiaries: [
        {
          id: '2712ff1e-0c88-4b56-8716-faca24aebffd',
          reference: 'Travelling expenses',
          name: 'M.Smithson LLC',
          amount: 250,
          popEmail: 'billing@msmithson.com',
          popMobileNumber: {
            countryCode: 'CY',
            localPhoneNumber: '22123123',
            diallingCode: '357',
            isValid: true,
            formattedPhoneNumber: '+357 22 123123',
          },
          documents: [
            {
              document_id: '2fbfd74d-8e2d-4dad-96ba-13a27e871d8a',
              uploaded_at: 1693922494235,
              document_name: 'M smithson INVOICE.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
          ],
        },
      ],
    },
    {
      id: 'c32854fd-e07b-4af7-a838-a69772fedd3c',
      internal_reference: 'Self-defense training',
      currency: 'EUR',
      last_modified_date: 1692546955000,
      last_modified_by: 'admin',
      status: 'pending-approval',
      statusNote: '',
      created_by: 'admin',
      beneficiaries: [
        {
          id: '57a66d03-b48f-47f5-914c-459d02b9a2fd',
          reference: 'Self-defence training',
          name: 'Francois Delarue',
          amount: 5300,
          popEmail: 'francois@email.com',
          documents: [
            {
              document_id: '5ee52de7-792b-412e-ac22-990853d82a8a',
              uploaded_at: 1693922494235,
              document_name: 'self-def-training-august-invoice.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
            {
              document_id: '5ee52de7-792b-412e-ac22-990853d8285a',
              uploaded_at: 1693922494237,
              document_name: 'self-def-training-july-invoice.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
          ],
        },
      ],
    },
    {
      id: 'ff75ce02-6c1a-4497-8d8a-ad75b27da347',
      internal_reference: 'SmartAI Chatbot Impl',
      currency: 'SEK',
      last_modified_date: 1692546955000,
      last_modified_by: 'admin',
      status: 'ready',
      statusNote: '',
      created_by: 'admin',
      beneficiaries: [
        {
          id: 'dfb82308-3e45-41b4-81a1-7cf7a0fbbe02',
          reference: 'SmartAI Chatbot Impl',
          name: 'SmarterAI Ltd',
          amount: 12000,
          popEmail: 'smartai@email.com',
          documents: [
            {
              document_id: 'ad2485dd-bc39-4cd9-8235-daf8d0360bf0',
              uploaded_at: 1693922494235,
              document_name: 'aug-fees.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
          ],
        },
      ],
      approval_details: {
        approved_by: 'admin',
        account_id: '81ce1600-3335-403e-a1a2-bed6cf8a8299',
      },
    },
    {
      id: '6da8d5fc-a3af-429e-89c7-1ecd0cbaf6cc',
      internal_reference: 'John - Consultancy',
      currency: 'GBP',
      last_modified_date: 1692546955000,
      last_modified_by: 'admin',
      status: 'rejected',
      statusNote: 'What consultancy service did we require?',
      created_by: 'admin',
      beneficiaries: [
        {
          id: 'd71fc9a5-e57b-4604-97d4-e7ceddc24113',
          reference: 'Consultancy services',
          name: 'Jonathan Smith',
          amount: 250,
          popEmail: 'john@email.com',
          documents: [
            {
              document_id: '0cec032e-8c3a-477d-82c2-8070428fabee',
              uploaded_at: 1693922494235,
              document_name: 'johnsmith-consultancy-invoice.pdf',
              document_url: '/dummy/dummy-invoice.pdf',
            },
          ],
        },
      ],
    },
  ],
} as Session
