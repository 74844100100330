import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../contexts'

const Authenticator = () => {
  // const navigate = useNavigate()

  const { token } = useContext(AuthContext)

  // useEffect(() => {
  //   if (!token) navigate('/sign-in')
  // }, [])
  return !token ? <Navigate replace to='/sign-in' /> : <Outlet />
}

export default Authenticator
