import { Box, Button, Divider, Flex, Paper, Select, Stack, TextInput, Title } from '@mantine/core'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { IconCampaign } from '../../../components/icons'
import Container from '../../../components/layout/Container'
import OnboardingAppWrapper from '../../../components/layout/OnboardingAppWrapper'
import { useNavigate } from 'react-router-dom'
import { useForm } from '@mantine/form'
import { industrySelectData, subIndustrySelectDataMap } from '../onboardingUtils'
import { getSelectPropsForCountry } from '../../../components/inputs/helper'

const SignUp = () => {
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      countryOfIncorporation: null,
      businessActivityIndustry: '',
      businessActivitySubIndustry: '',
    },
    validate: {
      email: (value: string) => (value.length === 0 ? 'Please enter an email' : null), // ToDO: add proper email verification here
      firstname: (value: string) => (value.length === 0 ? 'Please enter a firstname' : null),
      lastname: (value: string) => (value.length === 0 ? 'Please enter a lastname' : null),
      countryOfIncorporation: (value: string) => (value === null ? 'Please select a country' : null),
      businessActivityIndustry: (value: string) => (value === null ? 'Please select the industry of your business' : null),
      businessActivitySubIndustry: (value: string) => (value === null ? 'Please select the industry sub-type of your business' : null),
    },
  })

  const onSubmit = () => {
    form.validate()
    if (form.isValid()) {
      navigate('/onboarding/sign-up/awaiting-verification', {
        state: { email: form.values.email /*token: signupResponse.token*/ },
      })
    }
  }

  return (
    <OnboardingAppWrapper title='Leikur - Onboarding' forSignup={true}>
      <Container py={{ base: 'xl', desktop: '2xl' }} sx={{ isolation: 'isolate' }} my='2xl'>
        <Paper py={{ base: '2xl', desktop: 'none' }} mx={{ desktop: '6rem' }}>
          <CSSGrid rowGap='xl' templateColumns={{ base: '1fr', desktop: '1fr auto 1fr' }} alignItems='center'>
            <Box px={{ base: 'lg', desktop: '2xl' }}>
              <Box pb={{ base: 'lg', desktop: '2xl' }}>
                <Stack align='center' justify='center' pos='relative'>
                  <Paper component={IconCampaign} h={{ base: '4rem', desktop: '5rem' }} c='white' bg='primary' radius='50%' p='xs' shadow='md' />
                  <Title ta='center' order={1} mt='sm' c='primary'>
                    Great News!
                  </Title>
                </Stack>
              </Box>
              <Box ta='center' fz='md' c='textPrimary' p={{ desktop: 'md' }}>
                <Box mb={{ base: 'xl', desktop: '3xl' }}>
                  <b>Leikur&apos;s application process is completed entirely online!</b>
                </Box>
                <Box>
                  We&apos;ve taken great care to create an easy and pain-free system for you to follow. Effortlessly edit, review and submit all your
                  information when you are ready. Here goes...
                </Box>
              </Box>
            </Box>
            <Divider size='xs' color='primary.1' orientation={'vertical'} my='2xl' display={{ base: 'none', desktop: 'unset' }} />
            <CSSGrid px={{ base: 'lg', desktop: '3xl' }} py={{ base: 'md', desktop: '3xl' }} gap='md'>
              <TextInput label='First name' description='First name of the person filling this application' {...form.getInputProps('firstname')} />
              <TextInput label='Last name' description='Last name of the person filling this application' {...form.getInputProps('lastname')} />
              <TextInput label='Email address' description='Email address of the person filling this application' {...form.getInputProps('email')} />
              <Select
                label='Industry of your business'
                description='One core industry that is applicable to your business'
                searchable
                {...form.getInputProps('businessActivityIndustry')}
                onChange={(v) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  form.getInputProps('businessActivityIndustry').onChange(v)
                  form.setFieldValue('businessActivitySubIndustry', '')
                }}
                data={industrySelectData}
              />
              <Select
                disabled={form.values.businessActivityIndustry === ''}
                label='Industry sub-type of your business'
                description='Type of industry that is applicable to your business'
                searchable
                {...form.getInputProps('businessActivitySubIndustry')}
                data={subIndustrySelectDataMap[form.values.businessActivityIndustry as never] ?? []}
              />
              <Select
                label='Country of incorporation'
                description='Country where the company is incorporated'
                searchable
                {...getSelectPropsForCountry(form.values.countryOfIncorporation)}
                {...form.getInputProps('countryOfIncorporation')}
              />
              <Flex align='center' justify='end' mt='md'>
                <Button onClick={onSubmit}>Start your application</Button>
              </Flex>
            </CSSGrid>
          </CSSGrid>
        </Paper>
      </Container>
    </OnboardingAppWrapper>
  )
}

export default SignUp
