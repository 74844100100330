import React, { createContext } from 'react'
import { SessionReducerAction, Session } from '../utils/interfaces'
import { useSessionManager } from './useSessionManager'

interface SessionContextProps {
  session: Session
  dispatch: React.Dispatch<SessionReducerAction>
  clearSession: () => void
}

//  Will only ever be {} if the context is read from somewhere that's not a child of SessionProvider
export const SessionContext = createContext<SessionContextProps>({} as SessionContextProps)

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, dispatch, clearSession] = useSessionManager()

  return <SessionContext.Provider value={{ session, clearSession, dispatch }}>{children}</SessionContext.Provider>
}
