import { Box, Button, ButtonProps, createStyles, Drawer, DrawerProps, MantineColor, MantineNumberSize, Text } from '@mantine/core'
import React, { useState } from 'react'
import useStyles from './Menu.styles'
import { IconChevron, IconMenu } from '../../icons'

export interface MenuItemProps {
  textColor?: MantineColor
  descriptionColor?: MantineColor
  borderColor?: MantineColor
  borderWidth?: MantineNumberSize
  borderPosition?: 'top' | 'bottom' | 'top and bottom'
  onHoverBackgroundColor?: MantineColor
  title: string
  description: string
  icon?: React.ReactNode
  align?: 'left' | 'right'
  onClick?: () => void
}
export interface MenuProps extends Omit<DrawerProps, 'onClose'> {
  menuButtonProps?: Partial<ButtonProps>
  menuButtonTitle?: string | React.ReactNode
  onClose?: () => void
  setOpened: (opened: boolean) => void
}

const useDrawerStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.fn.themeColor('light.0'),
  },
  content: {
    overflow: 'auto',
    backgroundColor: theme.fn.themeColor('light.0'),
  },
  title: {
    width: '100%',
  },
}))

export const Menu = ({ menuButtonTitle, menuButtonProps, children, onClose, setOpened, classNames, ...props }: MenuProps) => {
  const { classes } = useDrawerStyles(undefined, { classNames, name: 'custom' })
  return (
    <>
      <Drawer
        padding='xl'
        size='xl'
        onClose={() => {
          setOpened(false)
          onClose && onClose()
        }}
        classNames={classes}
        {...props}
      >
        {children}
      </Drawer>
      <Button
        onClick={() => {
          setOpened(true)
        }}
        leftIcon={!menuButtonProps?.leftIcon && !menuButtonProps?.rightIcon ? <IconMenu /> : null}
        color='secondary'
        variant='subtle'
        size='medium'
        py={0}
        px='xs'
        {...menuButtonProps}
      >
        {menuButtonTitle}
      </Button>
    </>
  )
}

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  title,
  description,
  textColor,
  descriptionColor,
  borderColor,
  borderWidth,
  borderPosition,
  onHoverBackgroundColor,
  icon,
  align,
  onClick,
}) => {
  const { classes } = useStyles({
    borderColor,
    borderWidth,
    onHoverBackgroundColor,
    textColor,
    descriptionColor,
    borderPosition,
  })
  const [showSubItems, setShowSubItems] = useState(false)
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className={classes.menuItem}
        key={title}
        onClick={() => {
          children !== undefined ? setShowSubItems(!showSubItems) : null
          onClick ? onClick() : null
        }}
      >
        <div style={{ display: 'flex', width: '100%' }} className={align === 'right' ? classes.rightAlign : ''}>
          {icon && <div className={classes.menuIcon}>{icon}</div>}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text className={classes.itemHeader} weight={600}>
              {title}
            </Text>
            <div className={classes.itemDescription}>{description}</div>
          </div>
        </div>
        {children !== undefined && (
          <Box>
            <Box
              component={IconChevron}
              style={{
                transition: 'transform 0.5s ease-in-out 0s',
                transform: showSubItems ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </Box>
        )}
      </div>

      {showSubItems && children}
    </div>
  )
}
