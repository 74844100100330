import { Box, Button, Center, Chip, Collapse, Flex, Loader, Paper, TextInput, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchPaymentRequests from '../../../../api/queries/useFetchPaymentRequests'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import Instructions from '../../../../components/common/Instructions'
import { IconAuthorise, IconCloseCross, IconDownArrow, IconSearch } from '../../../../components/icons'
import AppHeader from '../../../../components/layout/AppHeader'
import AppWrapper from '../../../../components/layout/AppWrapper'
import Container from '../../../../components/layout/Container'
import { PaymentRequest, PaymentRequestStatus } from '../../../../utils/interfaces'
import ApprovalRequestDetails from './ApprovalRequestDetails'
import ApprovalRequestDetailsArea from './ApprovalRequestDetailsArea'
import ApprovalRequestSummary from './ApprovalRequestSummary'
import { modals } from '@mantine/modals'
import { Text } from '@mantine/core'
import useAuthorisePaymentRequests from '../../../../api/queries/useAuthorisePaymentRequests'
import { colorAliases } from '../../../../theme/getMantineTheme'

const PaymentApproval = () => {
  const navigate = useNavigate()
  const largeScreen = useMediaQuery('(min-width: 62em)') // 62em => desktop breakpoint

  const { isLoading: isAuthorisingPayment, mutate: authorisePayments } = useAuthorisePaymentRequests()

  const [selectedRequestIndex, setSelectedRequestIndex] = useState<number>(0)

  const [paymentStatusFilter, _setPaymentStatusFilter] = useState<PaymentRequestStatus>(PaymentRequestStatus.PENDING_APPROVAL)

  const [filteredData, setFilteredData] = useState<PaymentRequest[]>([])

  const setPaymentStatusFilter = (v: PaymentRequestStatus) => {
    _setPaymentStatusFilter(v)
    setFilteredData([])
    setSelectedRequestIndex(0)
  }

  const [dismissHelp, setDismissHelp] = useState(false)
  const { isLoading, data } = useFetchPaymentRequests(
    paymentStatusFilter === PaymentRequestStatus.PENDING_APPROVAL ? [paymentStatusFilter, PaymentRequestStatus.READY] : [paymentStatusFilter]
  )
  const [nameFilter, setNameFilter] = useState('')
  const theme = useMantineTheme()

  const chipStyles = {
    iconWrapper: { display: 'none' },
    label: {
      fontSize: '0.8rem',
      paddingTop: '1.3rem',
      paddingBottom: '1.3rem',
      height: '2.5rem',
      backgroundColor: 'transparent',
      width: '100%',
      justifyContent: 'center',
      border: `1px solid ${theme.fn.themeColor('transparentBlack.3')}`,
      '&[data-checked]:not([data-disabled])': {
        color: theme.fn.themeColor('white.5'),
        borderColor: 'transparent',
        backgroundColor: theme.fn.themeColor('dark.0'),
        ':hover': {
          backgroundColor: theme.fn.lighten(theme.fn.themeColor('dark.0'), 0.1),
        },
        ':active': {
          backgroundColor: theme.fn.themeColor('dark.0'),
        },
      },
      '&[data-variant="filled"]': {
        borderColor: 'transparent',
        backgroundColor: `${theme.fn.themeColor('transparentBlack.3')}`,
        ':hover': {
          backgroundColor: theme.fn.lighten(theme.fn.themeColor('dark.0'), 0.1),
        },
        ':active': {
          backgroundColor: theme.fn.themeColor('dark.0'),
        },
      },
    },
  }

  useEffect(() => {
    if (data) {
      setFilteredData(
        data.filter((request) => {
          return (
            request.internal_reference.toLowerCase().includes(nameFilter.toLowerCase()) ||
            request.beneficiaries.some(
              (b) => b.name.toLowerCase().includes(nameFilter.toLowerCase()) || b.reference.toLowerCase().includes(nameFilter.toLowerCase())
            )
          )
        })
      )
    }
  }, [data, nameFilter])

  const authorise = () => {
    modals.openConfirmModal({
      radius: 'md',
      padding: 'md',
      title: 'Confirm authorisation',
      children: <Text size='sm'>Are you sure you want to authorise the payment(s)?</Text>,
      labels: { confirm: 'Yes', cancel: 'No' },
      confirmProps: {
        color: 'primary',
        radius: 'lg',
        rightIcon: <IconDownArrow style={{ height: '0.55em', transform: 'rotate(270deg)' }} />,
      },
      cancelProps: { radius: 'lg', color: 'error', variant: 'outline', rightIcon: <IconCloseCross style={{ width: '0.75em', marginTop: '1px' }} /> },
      onConfirm: () => {
        authorisePayments(filteredData.filter((pr) => pr.status === PaymentRequestStatus.READY))
      },
    })
  }

  return (
    <AppWrapper
      title='Leikur - Payment Authorisation'
      appHeader={<AppHeader title='Authorise Payments' description='Review payment templates and transactions' iconOnClick={() => navigate('/dashboard')} />}
    >
      <Container py={{ base: 'xl', desktop: '3xl' }}>
        {!dismissHelp && (
          <Instructions
            instructions='Review Payment requests and Authorise transactions'
            stepOne='Select View to review payment and beneficiary details'
            stepTwo='Mark the request as ready, pushed-back or deny when the review is done'
            stepThree='Authorise to complete the transaction and pay beneficiaries'
            dismissHelp={() => setDismissHelp(true)}
          />
        )}

        <CSSGrid gap={{ base: 'xl', desktop: '3xl' }} alignItems='start' templateColumns={{ base: '1fr', desktop: 'auto 1fr' }}>
          <Paper shadow='none' miw={390} mih={206} bg={colorAliases.surfaceLightgray}>
            <Box pos='relative'>
              <CSSGrid px='xl' py='2xl' pt='lg' templateColumns='1fr auto' gap='2xs'>
                <Title fz='lg' ta='center'>
                  {paymentStatusFilter === PaymentRequestStatus.PENDING_APPROVAL ? 'Pending' : 'Pushed Back'} payment requests
                </Title>
              </CSSGrid>

              <Box px='xl' mb='xl'>
                <TextInput
                  placeholder='Search by name or reference...'
                  rightSection={<Box component={IconSearch} h='1em' c='muted' />}
                  styles={{ input: { height: '2.6rem' } }}
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.currentTarget.value)}
                />
              </Box>

              <Flex mb='lg' px='xl' gap='sm'>
                <Chip.Group multiple={false} value={paymentStatusFilter} onChange={(v) => setPaymentStatusFilter(v as PaymentRequestStatus)}>
                  <Chip
                    styles={chipStyles}
                    radius='xs'
                    ta='center'
                    checked={paymentStatusFilter === PaymentRequestStatus.PENDING_APPROVAL}
                    value={PaymentRequestStatus.PENDING_APPROVAL}
                    size='sm'
                    style={{ flex: 1, }}
                  >
                    Pending
                  </Chip>
                  <Chip
                    styles={chipStyles}
                    radius='xs'
                    size='sm'
                    ta='center'
                    checked={paymentStatusFilter === PaymentRequestStatus.PUSHED_BACK}
                    value={PaymentRequestStatus.PUSHED_BACK}
                    style={{ flex: 1 }}
                  >
                    Pushed-back
                  </Chip>
                </Chip.Group>
              </Flex>
              <Box sx={(theme) => ({ borderBottomLeftRadius: theme.radius.lg, borderBottomRightRadius: theme.radius.lg })}>
                {isLoading ? (
                  <Center
                    py='xl'
                    bg='white'
                    sx={(theme) => ({
                      borderBottomLeftRadius: theme.radius.lg,
                      borderBottomRightRadius: theme.radius.lg,
                    })}
                  >
                    <Loader color='secondary' />
                  </Center>
                ) : (
                  filteredData && (
                    <Box mb='xl'>
                      {filteredData.map((request, index) => (
                        <Box key={request.id}>
                          <ApprovalRequestSummary
                            key={request.id}
                            isSelected={index === selectedRequestIndex}
                            onSelect={() => {
                              setSelectedRequestIndex(index)
                              // not for mobile -> window.scrollTo(0, 0)
                            }}
                            request={request}
                          />
                          {!largeScreen && (
                            <Collapse in={index === selectedRequestIndex} display={{ desktop: 'none' }}>
                              <ApprovalRequestDetails
                                request={filteredData[selectedRequestIndex]}
                                readyPaymentRequests={filteredData.filter((pr) => pr.status === PaymentRequestStatus.READY)}
                              />
                            </Collapse>
                          )}
                        </Box>
                      ))}
                      {/* TODO: implement this function */}
                      {filteredData.some((pr) => pr.status === PaymentRequestStatus.READY) && (
                        <Button
                          mt='xl'
                          mx='auto'
                          display='block'
                          variant='white'
                          radius='xl'
                          color='secondary'
                          leftIcon={<IconAuthorise style={{ height: '2em' }} />}
                          disabled={isAuthorisingPayment}
                          onClick={authorise}
                        >
                          Authorise all requests that are ready
                        </Button>
                      )}
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Paper>
          {filteredData?.[selectedRequestIndex] ? (
            largeScreen ? (
              <ApprovalRequestDetailsArea
                request={filteredData[selectedRequestIndex]}
                readyPaymentRequests={filteredData.filter((pr) => pr.status === PaymentRequestStatus.READY)}
              />
            ) : undefined
          ) : (
            <Paper p='lg'>
              <Box pb='sm' sx={(theme) => ({ borderBottom: `2pt solid ${theme.fn.themeColor('dark')}` })}>
                <Center>
                  <Title fz={{ base: 'xl', desktop: 'xl' }} fw='bold' color='secondary' ta='center'>
                    No requests to show.
                  </Title>
                </Center>
              </Box>
            </Paper>
          )}
        </CSSGrid>
      </Container>
    </AppWrapper>
  )
}

export default PaymentApproval
