import { generateShades } from '@innostream/stream-ui'
import { ButtonProps, MantineTheme, MantineThemeOverride, rem } from '@mantine/core'

export const colorAliases = {
  blue300: 'primary.0',
  blue500: 'primary.2',
  blue700: 'primary',
  blue900: 'primary.6',
  blue1000: 'primary.7',
  brandPrimary: 'primary',
  brandSecondary: 'secondary',
  brandSecondaryLight: 'secondary.3',
  brandGray: '#8A8A8D',
  dark500: 'dark.0',
  dark700: 'dark.2',
  dark900: 'dark',
  green300: 'secondary.0',
  green500: 'secondary.2',
  green700: 'secondary',
  green900: 'secondary.6',
  green1000: 'secondary.7',
  light500: 'light.0',
  light50: '#767c80',
  light700: 'light',
  light900: 'light.7',
  surfaceDark: 'dark',
  surfaceDarkgray: 'dark.2',
  surfaceLight: 'light',
  surfaceLightgray: 'light.7',
  surfaceBrand: 'secondary.6',
  surfaceSilver: 'dark.0',
  surfaceSemiWhite: 'light.0',
  systemAlert: 'alert',
  systemError: 'negative',
  systemIcon: 'secondary',
  systemSuccess: 'positive',
  textAccent: 'primary.6',
  textInvert: 'dark',
  textInvertSecondary: 'dark.2',
  textLink: 'primary.6',
  textPrimaryInvert: 'light.0',
  textSecondaryInvert: 'light.7',
  borderSubtle: 'transparentBlack.3',
  borderDefault: 'transparentBlack.5',
  borderHighlight: 'transparentBlack.8',
  warning: '#eac24b',
}

export default (theme: MantineTheme): MantineThemeOverride => ({
  colorScheme: 'light',
  primaryShade: 4, // index used for default color shade in theme.colors. i.e. theme.colors.primary[4] = 'primary.4' = 'primary'
  primaryColor: 'primary',
  fontFamily: '"Roboto", sans-serif',
  lineHeight: '1.5',
  defaultRadius: 'xs',

  cursorType: 'pointer',

  // Default loader used in Loader and LoadingOverlay components
  loader: 'bars',

  white: '#ffffff', // lightest light from colors below
  black: '#0d0d10', // darkest dark from colors below

  colors: {
    primary: [
      '#AFAEFF', // primary.0 -> blue300
      '#9795fc',
      '#6d6aed', // primary.2 -> blue500
      '#5653c9',
      '#4845E9', // default primary -> blue700, brand/primary
      '#403e97',
      '#3532D0', // primary.6 -> blue900, Text-accent, Text-Link
      '#19179C', // primary.7 -> blue1000
      '#14127d',
      '#0f0e5e',
    ],

    secondary: [
      '#ACDEFF', // secondary.0 -> green300 #ACDEFF
      '#82adca',
      '#69AEDC', // secondary.2 -> green500 #69AEDC
      '#5e96bb',
      '#4C8AB3', // default secondary -> green700, brand/secondary
      '#447ca1',
      '#356C91', // secondary.6 -> green900, surface-brand
      '#204963', // secondary.7 -> green1000
      '#1e3748',
      '#0f1c24',
    ],

    dark: [
      '#3C3C4B', // dark.0 -> dark500, surface-silver
      '#30303E',
      '#292934', // dark.2 -> dark700, surface-darkgray, Text-Invert-secondary
      '#1B1B22',
      '#16161C', // default dark -> dark900, surface-dark, Text-Invert
      '#111116',
      '#0D0D11',
      '#0A0A0D',
      '#060608',
      '#050506',
    ],

    light: [
      '#ffffff', // white, light.0 -> light500, surface-semiwhite, Text-primary-invert
      '#f9fbfd',
      '#f5fafc',
      '#f2f8fb',
      '#EFF6FA', // default light -> light700, surface-light
      '#e6eef2',
      '#dee8ed',
      '#d6e2e9', // light.7 => light900, surface-lightgray, text-secondary-invert
      '#c1cbd2',
      '#abb5ba',
    ],
    positive: [
      '#aee484',
      '#9ede6b',
      '#8ed953',
      '#7dd33a',
      '#5dc809', // default
      '#54b408',
      '#418c06',
      '#387805',
      '#2f6405',
      '#255004',
    ],
    negative: [
      '#fab1a2',
      '#f9a493',
      '#f89783',
      '#f78a74',
      '#f67d64', // default
      '#c56450',
      '#ac5846',
      '#944b3c',
      '#7b3f32',
      '#623228',
    ],
    alert: [
      '#f9e998',
      '#f8e687',
      '#f7e275',
      '#f6df64',
      '#f5db53', // default
      '#ddc54b',
      '#c4af42',
      '#ac993a',
      '#938332',
      '#7b6e2a',
    ],

    transparentBlack: [
      'rgba(0,0,0,0.05)',
      'rgba(0,0,0,0.1)',
      'rgba(0,0,0,0.15)',
      'rgba(0,0,0,0.2)', // light15%!!, transparentBlack.3 -> borderSubtle
      'rgba(0,0,0,0.25)',
      'rgba(0,0,0,0.3)', // light30%, transparentBlack.5 -> borderDefault
      'rgba(0,0,0,0.35)',
      'rgba(0,0,0,0.4)',
      'rgba(0,0,0,0.45)', // light50%!!, transparentBlack.8 -> borderHighlight
      'rgba(0,0,0,0.5)',
    ],

    // TODO stop using these:
    success: generateShades(theme, '#7ea47d'),
    error: generateShades(theme, '#fa5252'),
    warning: generateShades(theme, '#eac24b'),
    muted: generateShades(theme, '#c0c0c0'),
    textMuted: generateShades(theme, '#5a5a5a'),
    background: generateShades(theme, '#f6f6f6'),
    backgroundMuted: generateShades(theme, '#c0c0c0'),
    textPrimary: generateShades(theme, '#5a5a5a'),
    textDarker: generateShades(theme, '#444444'),
    buttonDisabled: generateShades(theme, '#c0c0c0'),
    primaryHighlight: generateShades(theme, '#4c8ab3'),
    primaryHighlight2: generateShades(theme, '#93b1cb'),
    primaryLight: generateShades(theme, '#dfe7f0'),
    greenGroupThemeAccent: generateShades(theme, '#7ea47d'),
    greenGroupThemeText: generateShades(theme, '#444444'),
    purpleGroupThemeAccent: generateShades(theme, '#644354'),
    purpleGroupThemeText: generateShades(theme, '#dbdbdb'),
    brownGroupThemeAccent: generateShades(theme, '#948c65'), // was #8d6d54 #948c65
    brownGroupThemeText: generateShades(theme, '#444444'),
    goldGroupThemeAccent: generateShades(theme, '#d79630'),
    goldGroupThemeText: generateShades(theme, '#444444'),
    blueGroupThemeAccent: generateShades(theme, '#24608b'),
    blueGroupThemeText: generateShades(theme, '#dbdbdb'),
    primaryMuted: generateShades(theme, '#4c8ab3'),
    white: generateShades(theme, '#ffffff'), // TODO: remove and replace with `light.0` when used (if needed), or theme.white

    // leave this one as a shortcut to transparent
    transparent: ['#00000000', '#00000000', '#00000000', '#00000000', '#00000000', '#00000000', '#00000000', '#00000000', '#00000000', '#00000000'],
  },

  // left default values for reference
  breakpoints: {
    tablet: '25.75em', // 412px
    // xs: '36em', // default mantine value
    // sm: '48em', // default mantine value
    // md: '62em', // default mantine value
    desktop: '62em', // 992px
    // lg: '75em', // default mantine value
    wide: '75em', // 1200px
    // xl: '88em', // default mantine value
  },

  fontSizes: {
    '3xs': '0.5rem',
    '2xs': '0.625rem',
    // xs: '0.75rem', // default theme values
    // sm: '0.875rem', // default theme values
    // md: '1rem' // default theme values
    // lg: '1.125rem', // default theme values
    // xl: '1.25rem', // default theme values
    '2xl': '1.5rem',
    '3xl': '1.75rem',
  },

  radius: {
    '3xs': '1px',
    '2xs': '2px',
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
  },

  //TODO: fix spacing
  spacing: {
    '4xs': '2px',
    '3xs': '4px', // prev + 2px
    '2xs': '6px', // + 2px
    // xs: '8px', // + 2px, default mantine value
    // sm: '12px', // +4px, default mantine value
    // md: '16px', // +4px, default mantine value
    // lg: '20px', // +4px, default mantine value
    // xl: '24px', // +4px, default mantine value
    '2xl': '32px', // +8px
    '3xl': '40px', // +8px
    '4xl': '48px', // +8px
  },

  headings: {
    fontFamily: '"Object Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
    fontWeight: 400,
    sizes: {
      h1: { fontSize: '2.5rem', lineHeight: 1.3, fontWeight: 400 },
      h2: { fontSize: '2rem', lineHeight: 1.3, fontWeight: 400 },
      h3: { fontSize: '1.5rem', lineHeight: 1.3, fontWeight: 400 },
      h4: { fontSize: '1.25rem', lineHeight: 1.3, fontWeight: 400 }, // use as subtitle
    },
  },

  components: {
    InputWrapper: {
      styles: (theme) => ({
        label: { color: theme.fn.themeColor('primary'), fontWeight: 'bold' },
        description: { color: theme.fn.themeColor('textPrimary') },
      }),
    },
    Chip: {
      styles: () => ({
        label: { paddingTop: '1rem', paddingBottom: '1rem' },
      }),
    },
    Input: {
      styles: (theme, { invalid }) => ({
        input: {  color: theme.fn.themeColor('textDarker')},
        wrapper: { marginBottom: invalid ? 'calc(' + theme.spacing.xs + ' / 2)' : undefined },
      }),
    },
    PasswordInput: {
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
    },
    Textarea: {
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      styles: (theme) => ({
       input: {
        border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`, 
        borderRadius: theme.fn.radius('sm'),
        background: 'white' 
        }
      })
    },
    TextInput: {
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      }, 
      styles: (theme) => ({
        input: {
         border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`, 
         borderRadius: theme.fn.radius('sm'),
         background: 'white' 
         }
      })
    },
    NumberInput: {
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      styles: (theme) => ({
        input: {
         border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`, 
         borderRadius: theme.fn.radius('sm'),
         background: 'white' 
         }
      })
    },
    Select: {
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      styles: (theme) => ({
        input: {
          border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`, 
          borderRadius: theme.fn.radius('sm'),
          background: 'white' 
        },
        item: {
          color: theme.fn.themeColor('textPrimary'),
          '&[data-selected]': {
            '&, &:hover': {
              backgroundColor: theme.fn.themeColor('primary'),
              color: theme.fn.themeColor('white'),
            },
          },
          '&[data-hovered]': {
            backgroundColor: theme.fn.themeColor('primary'),
            color: theme.fn.themeColor('white'),
          },
        },
      }),
    },
    Checkbox: {
      styles: (theme) => ({
        label: { color: theme.fn.themeColor('primary'), fontWeight: 'bold' },
      }),
    },
    Paper: {
      defaultProps: {
        radius: 'md',
      },
    },
    Button: {
      sizes: {
        big: (theme, params, context) => ({
          root: {
            height: '3.625rem',
            fontSize: context.variant && context.variant.indexOf('leikur') > -1 ? '0.875rem' : '1rem',
            lineHeight: '1.5rem', // as per button design, not typography (07/03/2024)
            paddingInline: context.variant && context.variant.indexOf('leikur') > -1 ? '0 0.5rem' : '2rem',
            paddingBlock: context.variant && context.variant.indexOf('leikur') > -1 ? '0' : '1rem',
          },
        }),
        medium: (theme, params, context) => ({
          root: {
            height: '3rem',
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            paddingInline: context.variant && context.variant.indexOf('leikur') > -1 ? '0 0.5rem' : '1.5rem',
            paddingBlock: context.variant && context.variant.indexOf('leikur') > -1 ? '0' : '0.75rem',
          },
        }),
        small: (theme, params, context) => ({
          root: {
            height: '2.25rem',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            paddingInline: context.variant && context.variant.indexOf('leikur') > -1 ? '0 0.5rem' : '1rem',
            paddingBlock: context.variant && context.variant.indexOf('leikur') > -1 ? '0' : '0.5rem',
            // no font-weight change as per button design, not typography (07/03/2024)
          },
        }),
      },
      variants: {
        leikurLight: (theme, params: ButtonProps) => {
          const colors = theme.fn.variant({ color: params.color, variant: 'subtle' })
          return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            root: {
              border: `${rem(1)} solid ${colors.border ?? 'transparent'}`,
              backgroundColor: colors.background,
              color: colors.color,
              ...theme.fn.hover({
                backgroundColor: colors.hover,
                '& .mantine-Button-icon': {
                  border: 'unset',
                },
              }),
            },
            inner: {
              justifyContent: 'start',
            },
            icon: {
              display: 'flex',
              height: '100%',
              aspectRatio: '1',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid rgba(0,0,0,0.2)',
              borderRadius: theme.radius.xs,
              color: theme.fn.themeColor('dark'),
            },
          }
        },
        leikurDark: (theme, params: ButtonProps) => {
          const colors = theme.fn.variant({ color: params.color, variant: 'subtle' })
          return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            root: {
              border: `${rem(1)} solid ${colors.border ?? 'transparent'}`,
              backgroundColor: colors.background,
              color: colors.color,
              ...theme.fn.hover({
                backgroundColor: colors.hover,
                '& .mantine-Button-icon': {
                  border: 'unset',
                },
              }),
            },
            inner: {
              justifyContent: 'start',
            },
            icon: {
              display: 'flex',
              height: '100%',
              aspectRatio: '1',
              alignItems: 'center',
              justifyContent: 'center',
              // border: '1px solid rgba(0,0,0,0.2)',
              borderRadius: theme.radius.xs,
              background: theme.fn.themeColor(params.color ?? 'primary'),
              color: theme.fn.themeColor('light.0'),
            },
          }
        },
      },
      styles: () => ({
        // root: {
        // },
        label: {
          //not Work Sans, as per button design not typography (07/03/2024)
          fontFamily: '"Object Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
          fontWeight: 400,
        },
        // icon: {
        // display: 'unset',
        // },
      }),
    },
  },

  // TODO: if needed, use this to configure active button styles
  // activeStyles: {

  // },

  other: {
    /** helper variable to make styling slightly more readable */

    // TODO: check if these are needed in new design
    defaultInputProps: {
      inputColor: 'primary',
      labelColor: 'primary',
      labelFontWeight: 700,
      labelFontSize: 'sm',
      descriptionColor: 'textPrimary',
    },
  },
})
