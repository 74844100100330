import { Outlet } from 'react-router-dom'
import { OnboardingSessionProvider } from '../../contexts/onboarding/OnboardingContext'

const OnboardingContextWrapper = () => {

  return (
    <OnboardingSessionProvider>
      <Outlet />
    </OnboardingSessionProvider>
  )
}

export default OnboardingContextWrapper
