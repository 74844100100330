import { useEffect } from 'react'
import { Navigate, Outlet, createBrowserRouter, useLocation } from 'react-router-dom'
import { PaymentRequestTypes } from '../utils/interfaces'
import Authenticator from './Authenticator'
import SignIn from './SignIn'
import BeneficiaryCentre from './authenticated/BeneficiaryCentre'
import AddBeneficiary from './authenticated/BeneficiaryCentre/manage/AddBeneficiary'
import EditBeneficiary from './authenticated/BeneficiaryCentre/manage/EditBeneficiary'
import Dashboard from './authenticated/Dashboard'
import StatementCentre from './authenticated/StatementCentre'
import InitiatePayments from './authenticated/TransactionCentre/InitiatePayments'
import ManagePayments from './authenticated/TransactionCentre/InitiatePayments/ManagePayments'
import PaymentApproval from './authenticated/TransactionCentre/PaymentApproval'
import PaymentRequests from './authenticated/TransactionCentre/PaymentRequests'
import OnboardingContextWrapper from './onboarding/OnboardingContextWrapper'
import onboardingRoutes from './onboarding/onboardingRoutes'
import AwaitingEmailVerification from './onboarding/signup/AwaitingEmailVerification'
import SignUp from './onboarding/signup/SignUp'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <Outlet />
}
const browserRouter = createBrowserRouter([
  { path: '*', element: <Navigate replace to='/dashboard' /> },
  {
    element: <ScrollToTop />,
    children: [
      { path: '/sign-in', element: <SignIn /> },
      { path: '/onboarding/sign-up', element: <SignUp /> },
      { path: '/onboarding/sign-up/awaiting-verification', element: <AwaitingEmailVerification /> },
      {
        element: <Authenticator />,
        children: [
          { path: '/dashboard', element: <Dashboard /> },
          { path: '/statement-centre', element: <StatementCentre /> },
          { path: '/statement-centre/:groupId', element: <StatementCentre /> },
          { path: '/statement-centre/:groupId/:accountId', element: <StatementCentre /> },
          { path: '/beneficiary-centre', element: <BeneficiaryCentre /> },
          { path: '/beneficiary-centre/manage/add', element: <AddBeneficiary /> },
          { path: '/beneficiary-centre/manage/edit/:beneficiaryId', element: <EditBeneficiary /> },
          { path: '/transaction-centre', element: <InitiatePayments /> },
          {
            path: '/transaction-centre/manage/new-request',
            element: <ManagePayments requestType={PaymentRequestTypes.NEW_REQUEST} key={PaymentRequestTypes.NEW_REQUEST} />,
          },
          {
            path: '/transaction-centre/manage/new-request/:templateId',
            element: <ManagePayments requestType={PaymentRequestTypes.NEW_FROM_TEMPLATE} key={PaymentRequestTypes.NEW_FROM_TEMPLATE} />,
          },
          {
            path: '/transaction-centre/manage/new-template',
            element: <ManagePayments requestType={PaymentRequestTypes.NEW_TEMPLATE} key={PaymentRequestTypes.NEW_TEMPLATE} />,
          },
          { path: '/transaction-centre/payment-approval', element: <PaymentApproval /> },
          { path: '/transaction-centre/payment-requests', element: <PaymentRequests /> },
          {
            path: '/onboarding',
            element: <OnboardingContextWrapper />,
            children: onboardingRoutes,
          },
        ],
      },
    ],
  },
])

export default browserRouter
