import { createStyles, Drawer, DrawerProps, MantineColor, MantineNumberSize, Text } from '@mantine/core';

import React, { useState } from 'react';
import { ArrowUpIcon } from '../../iconComponents/icon_arrow_up';
import { MenuIcon } from '../../iconComponents/icon_menu';
import { IconButton, IconButtonProps } from '../../simple/Buttons';
import useStyles from './Menu.styles';

export interface MenuItemProps {
  textColor?: MantineColor;
  descriptionColor?: MantineColor;
  borderColor?: MantineColor;
  borderWidth?: MantineNumberSize;
  borderPosition?: 'top' | 'bottom' | 'top and bottom';
  onHoverBackgroundColor?: MantineColor;
  title: string;
  description: string;
  icon?: React.ReactNode;
  align?: 'left' | 'right';
  onClick?: () => void;
}
export interface MenuProps extends Omit<DrawerProps, 'onClose'> {
  iconButtonProps?: Partial<IconButtonProps>;
  iconButtonTitle?: string | React.ReactNode;
  iconButtonDescription?: string;
  onClose?: () => void;
  setOpened: (opened: boolean) => void;
}

const useDrawerStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.fn.themeColor('background', 6, false),
  },
  content: {
    overflow: 'auto',
    backgroundColor: theme.fn.themeColor('background', 6, false),
  },
  title: {
    width: '100%',
  },
}));

export const Menu = ({ iconButtonProps, iconButtonTitle, iconButtonDescription, children, onClose, setOpened, classNames, ...props }: MenuProps) => {
  const { classes } = useDrawerStyles(undefined, { classNames, name: 'custom' });
  return (
    <>
      <Drawer padding="xl" size="xl" onClose={() => setOpened(false)} classNames={classes} {...props}>
        {children}
      </Drawer>
      <IconButton
        onClick={() => {
          setOpened(true);
        }}
        leftIcon={!iconButtonProps?.leftIcon && !iconButtonProps?.rightIcon ? <MenuIcon /> : null}
        {...iconButtonProps}
      >
        <IconButton.Title>{iconButtonTitle}</IconButton.Title>
        <IconButton.Description>{iconButtonDescription}</IconButton.Description>
      </IconButton>
    </>
  );
};

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  title,
  description,
  textColor,
  descriptionColor,
  borderColor,
  borderWidth,
  borderPosition,
  onHoverBackgroundColor,
  icon,
  align,
  onClick,
}) => {
  const { classes } = useStyles({
    borderColor,
    borderWidth,
    onHoverBackgroundColor,
    textColor,
    descriptionColor,
    borderPosition,
  });
  const [showSubItems, setShowSubItems] = useState(false);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className={classes.menuItem}
        key={title}
        onClick={() => {
          children !== undefined ? setShowSubItems(!showSubItems) : null;
          onClick ? onClick() : null;
        }}
      >
        <div style={{ display: 'flex', width: '100%' }} className={align === 'right' ? classes.rightAlign : ''}>
          {icon && <div className={classes.menuIcon}>{icon}</div>}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text className={classes.itemHeader} weight={600}>
              {title}
            </Text>
            <div className={classes.itemDescription}>{description}</div>
          </div>
        </div>
        {children !== undefined && (
          <div
            style={{
              transform: showSubItems ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
            className={classes.arrowIcon}
          >
            <ArrowUpIcon />
          </div>
        )}
      </div>

      {showSubItems && children}
    </div>
  );
};
