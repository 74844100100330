import { Box, Button, Loader } from '@mantine/core'
import { useSetState } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchBeneficiaries from '../../../api/queries/useFetchBeneficiaries'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { IconBeneficiaryAddNew } from '../../../components/icons'
import AppHeader from '../../../components/layout/AppHeader'
import AppWrapper from '../../../components/layout/AppWrapper'
import Container from '../../../components/layout/Container'
import { BeneficiaryProvider } from '../../../contexts/BeneficiaryContext'
import { colorAliases } from '../../../theme/getMantineTheme'
import { Beneficiary } from '../../../utils/interfaces'
import BeneficiaryCentreList from './BeneficiaryCentreList'
import BeneficiaryCentreSearchIndividual from './BeneficiaryCentreSearchIndividual'

const BeneficiaryCentreHeader = () => {
  const navigate = useNavigate()

  return (
    <AppHeader
      title='Manage beneficiaries'
      description='Management of beneficiaries'
      iconOnClick={() => navigate('/dashboard')}
      buttons={
        <Button
          size='big'
          variant='leikurLight'
          color={colorAliases.textLink}
          onClick={() => navigate('/beneficiary-centre/manage/add')}
          leftIcon={<IconBeneficiaryAddNew style={{ height: '1.2rem' }} />}
        >
          <Box>
            Beneficiary <br />
            <Box component='span' lh='1' c={colorAliases.textInvert}>
              create new
            </Box>
          </Box>
        </Button>
      }
    />
  )
}

export enum FILTER_ENTITY_TYPE {
  Individual = 'individual',
  Corporate = 'corporate',
  All = 'all',
}
export interface BeneficiaryFilter {
  nameOrAliasOrReference: string
  entity: FILTER_ENTITY_TYPE
  status: 'active' | 'inactive' | 'pending'
  country: string | null
  currency: string | null
}

const BeneficiaryCentre = () => {
  const { isLoading, data: beneficiaries } = useFetchBeneficiaries()

  const [filters, setFilters] = useSetState<BeneficiaryFilter>({
    nameOrAliasOrReference: '',
    entity: FILTER_ENTITY_TYPE.All,
    country: null,
    currency: null,
    status: 'active',
  })

  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState<Beneficiary[]>([])

  useEffect(() => {
    if (beneficiaries) setFilteredBeneficiaries(beneficiaries)
  }, [beneficiaries])

  useEffect(() => {
    setFilteredBeneficiaries(
      beneficiaries?.filter((beneficiary) => {
        if (filters.entity !== FILTER_ENTITY_TYPE.All && beneficiary.entity_type !== filters.entity) {
          return false
        }
        if (filters.country !== null && beneficiary.country !== filters.country) {
          return false
        }
        if (filters.currency !== null && beneficiary.currency !== filters.currency) {
          return false
        }
        if (
          !(
            beneficiary.name.toLowerCase().includes(filters.nameOrAliasOrReference.toLowerCase()) ||
            beneficiary.alias.toLowerCase().includes(filters.nameOrAliasOrReference.toLowerCase()) ||
            beneficiary.reference?.toLowerCase().includes(filters.nameOrAliasOrReference.toLowerCase())
          )
        ) {
          return false
        }
        return true
      }) ?? []
    )
  }, [beneficiaries, filters])

  if (isLoading) {
    return (
      <AppWrapper title='Leikur - Transaction Centre' appHeader={<BeneficiaryCentreHeader />}>
        <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </Container>
      </AppWrapper>
    )
  }

  return (
    <BeneficiaryProvider>
      <AppWrapper title='Leikur - Beneficiary Centre' appHeader={<BeneficiaryCentreHeader />}>
        <Container my='lg' py={{ base: 'xl', desktop: '3xl' }}>
          <CSSGrid
            columnGap={{ base: 'xl', desktop: 'xl' }}
            rowGap='xl'
            alignItems='start'
            templateAreas={{
              base: '"filters" "list"',
              desktop: '"filters" "list"',
            }}
            templateColumns={{ base: '1fr', desktop: 'auto 1fr' }}
          >
            <BeneficiaryCentreSearchIndividual filters={filters} setFilters={setFilters} />
            <BeneficiaryCentreList beneficiaries={filteredBeneficiaries} />
          </CSSGrid>
        </Container>
      </AppWrapper>
    </BeneficiaryProvider>
  )
}

export default BeneficiaryCentre
