import { Box, Text, Button } from '@mantine/core'
import { useSetState } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import useFetchPaymentTemplates from '../../../../api/queries/useFetchPaymentTemplates'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconDocChecked, IconMoneySign } from '../../../../components/icons'
import AppHeader from '../../../../components/layout/AppHeader'
import AppWrapper from '../../../../components/layout/AppWrapper'
import Container from '../../../../components/layout/Container'
import { colorAliases } from '../../../../theme/getMantineTheme'
import { PaymentRequestTypes } from '../../../../utils/interfaces'
import ActionBar from './ActionBar'
import ExistingTemplatesArea from './ExistingTemplatesArea'
import TimingsArea from './TimingsArea'

const Header = () => {
  const navigate = useNavigate()

  return (
    <AppHeader
      title='Transaction Centre'
      description='Create a payment request or template'
      iconOnClick={() => {
        navigate('/dashboard')
      }}
      buttons={[
        <Button
          key='createTemplateButton'
          size='big'
          variant='leikurLight'
          color={colorAliases.textLink}
          onClick={() => navigate(`/transaction-centre/manage/${PaymentRequestTypes.NEW_TEMPLATE}`)}
          leftIcon={<IconDocChecked style={{ width: '1.5em' }} />}
        >
          <Box>
            <Text c={colorAliases.textInvert}>Payment template</Text>
            <Box component='span' lh={1} c='primary'>
              create new
            </Box>
          </Box>
        </Button>,

        <Button
          key='createPaymentButton'
          size='big'
          variant='leikurLight'
          color={colorAliases.textLink}
          onClick={() => navigate(`/transaction-centre/manage/${PaymentRequestTypes.NEW_REQUEST}`)}
          leftIcon={<IconMoneySign style={{ width: '1.5em' }} />}
        >
          <Box>
            <Text c={colorAliases.textInvert}>Payment request</Text>
            <Box component='span' lh={1} c='primary'>
              create new
            </Box>
          </Box>
        </Button>,
      ]}
    />
  )
}

export interface PaymentTemplateFilters {
  nameOrAliasOrReference: string
  currency: string | null
}

const InitiatePayments = () => {
  const [filters, setFilters] = useSetState<PaymentTemplateFilters>({
    nameOrAliasOrReference: '',
    currency: null,
  })

  const { isLoading, isSuccess, data: paymentTemplates } = useFetchPaymentTemplates(filters)

  return (
    <AppWrapper title='Leikur - Transaction Centre' appHeader={<Header />}>
      <Container py={{ base: 'xl', desktop: '3xl' }} mt='lg' sx={{ isolation: 'isolate' }}>
        <CSSGrid
          columnGap={{ base: 'xl', desktop: '3xl' }}
          rowGap='xl'
          alignItems='start'
          templateAreas={{
            base: '"filters" "report" "processing_times"',
            desktop: '"filters report" "processing_times report"',
          }}
          templateColumns={{ base: '1fr', desktop: 'auto 1fr' }}
          templateRows={{ base: 'auto', desktop: 'auto 1fr' }}
        >
          <ActionBar sx={{ gridArea: 'filters' }} w={{ base: '100%', desktop: 390 }} mih={250} filters={filters} setFilters={setFilters} />
          <TimingsArea w={{ base: '100%', desktop: 390 }} sx={{ gridArea: 'processing_times' }} />
          <ExistingTemplatesArea sx={{ gridArea: 'report' }} paymentTemplates={paymentTemplates ?? []} isLoading={isLoading} isSuccess={isSuccess} />
        </CSSGrid>
      </Container>
    </AppWrapper>
  )
}

export default InitiatePayments
