import { Box, Button, Loader } from '@mantine/core'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import useFetchAccountGroupsByIBAN from '../../../api/queries/useFetchAccountGroupsByIBAN'
import { CSSGrid } from '../../../components/common/CSSGrid'
import Instructions from '../../../components/common/Instructions'
import { IconFundsConvertForex } from '../../../components/icons'
import AppHeader from '../../../components/layout/AppHeader'
import AppWrapper from '../../../components/layout/AppWrapper'
import { Container } from '../../../components/layout/Container'
import { colorAliases } from '../../../theme/getMantineTheme'
import { AccountGroup } from '../../../utils/interfaces'
import AccountsArea from './AccountsArea'
import TransactionsArea from './TransactionsArea'
import Welcome from './Welcome'

const Header: React.FC<{ group: AccountGroup | undefined }> = ({ group }) => {
  const navigate = useNavigate()
  return (
    <AppHeader
      title={'Statement Centre'}
      description='Manage, navigate and export statements'
      iconOnClick={() => {
        navigate('/dashboard')
      }}
      buttons={
        group ? (
          <Button
            size='big'
            variant='leikurLight'
            color={colorAliases.textLink}
            onClick={() => navigate('/statement-centre')}
            leftIcon={<IconFundsConvertForex style={{ height: '1.5rem', aspectRatio: 1 }} />}
          >
            <Box>
              Accounts <br />
              <Box component='span' lh='1' c={colorAliases.textInvert}>
                view all
              </Box>
            </Box>
          </Button>
        ) : undefined
      }
    />
  )
}

const StatementCentre = () => {
  const { groupId, accountId } = useParams()

  const [dismissHelp, setDismissHelp] = useState(false)
  const { isLoading, data: accountGroupsByIBAN } = useFetchAccountGroupsByIBAN()

  const group = groupId && accountGroupsByIBAN && Object.hasOwn(accountGroupsByIBAN, groupId) ? accountGroupsByIBAN[groupId] : undefined

  if (isLoading || accountGroupsByIBAN === undefined) {
    return (
      <AppWrapper title={`Leikur - ${group ? group.alias : 'Statement Centre'}`} appHeader={<Header group={group} />}>
        <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader color={group ? group.theme + 'GroupThemeAccent' : 'primary'} />
        </Container>
      </AppWrapper>
    )
  }

  return (
    <AppWrapper title={`Leikur - ${group ? group.alias : 'Statement Centre'}`} appHeader={<Header group={group} />}>
      <Container py={{ base: 'xl', desktop: '3xl' }} sx={{ isolation: 'isolate' }}>
        {!dismissHelp && groupId === undefined && (
          <Instructions
            instructions='Three easy steps to search, find and export past transactions'
            stepOne='Choose an account from the list to start'
            stepTwo='Use the filters to search, narrow and refine your statement history'
            stepThree='Export and save exactly the information you need for easy accounting'
            dismissHelp={() => setDismissHelp(true)}
          />
        )}
        <CSSGrid
          columnGap={{ base: 'xl' }}
          rowGap='xl'
          templateColumns={{ base: '1fr', desktop: group ? 'auto 1fr' : '1fr' }}
          alignItems='start'
        >
          {group === undefined && <Welcome dismissHelp={dismissHelp} showHelp={() => setDismissHelp(false)} />}
          {group && <AccountsArea group={group} accountId={accountId} />}
          {group && <TransactionsArea group={group} selectedAccountId={accountId} />}
        </CSSGrid>
      </Container>
    </AppWrapper>
  )
}

export default StatementCentre
