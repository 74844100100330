import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequest, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const addPaymentRequest = async (dispatch: React.Dispatch<SessionReducerAction>, paymentRequest: PaymentRequest) => {
  return new Promise((resolve) => {
    const newPaymentRequest = {
      ...paymentRequest,
      id: `payment-request-${dayjs().valueOf()}`,
      last_modified_date: dayjs().valueOf(),
      last_modified_by: 'admin',
      created_by: 'admin',
    }
    dispatch({
      type: SessionReducerActionType.ADD_PAYMENT_REQUEST,
      payload: newPaymentRequest,
    })
    resolve(newPaymentRequest)
  })
}

export default function useAddPaymentRequest(onSuccess: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (paymentRequest: PaymentRequest) => addPaymentRequest(dispatch, paymentRequest),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['payment-requests'] })
      onSuccess()
    },
  })
}
