import dayjs from 'dayjs'
import React, { useReducer } from 'react'

import {
  BusinessActivityIndustry,
  BusinessActivitySubIndustry,
  DeepPartial,
  OnboardingRequest,
  OnboardingSession,
  OnboardingSessionReducerAction,
  OnboardingSteps,
  exampleOnboardingData,
} from '../../types/onboarding'
import onboardingSessionReducer from './onboardingSessionReducer'

// !!!! DEFAULT form values are included here
export const DEFAULT_ONBOARDING_FORM_FIELDS: DeepPartial<OnboardingRequest> = {
  company: {
    name: '',
    dateOfIncorporation: '',
    registrationNumber: '',
    taxIdentification: {
      additionalCountries: [],
    },
    phoneNumber: {},
    registeredOfficeAddress: { addressLine1: '', addressLine2: '', city: '', postcode: '', countryCode: '' },
    headquartersOfficeAddress: { addressLine1: '', addressLine2: '', city: '', postcode: '', countryCode: '' },
    website: '',
    email: '',
    group: { stockExchange: { listedSubsidiaries: [] }, governmentalLicense: { licensedSubsidiaries: [] } },
    governmentalLicense: { licensePublicSource: '', fileId: '' },
    stockExchange: { isinCode: '' },
    shareholders: {
      nominee: {
        fullName: '',
        regulatingCountryCode: '',
      },
    },
  },
  account: {
    purposesOfAccount: [],
    purposesOfAccountAdditionalInformation: {
      serviceProviders: [],
      clients: [],
    },
    requestedProducts: [],
    sourceOfIncome: [],
    limits: {},
    sendFunds: { countries: [] },
    receiveFunds: { countries: [] },
  },
  persons: [],
  other: {
    currenciesForPayment: [],
    fatcaStatus: {},
    isPartOfGroup: undefined,
    isGovGroup: undefined,
    isStockGroup: undefined,
  },
}

// CHANGE with boolean to enable full mocked data
const WITH_FULL_MOCKED_DATA = true

const initSession: OnboardingSession = {
  version: 1.7,
  last_update: dayjs().toISOString(),
  onboarding_request: WITH_FULL_MOCKED_DATA
    ? exampleOnboardingData
    : // mocking only sign up
      {
        ...DEFAULT_ONBOARDING_FORM_FIELDS,
        company: {
          ...DEFAULT_ONBOARDING_FORM_FIELDS.company,
          countryOfIncorporation: 'SE',
          businessActivityIndustry: BusinessActivityIndustry.EDUCATION,
          businessActivitySubIndustry: BusinessActivitySubIndustry.EDUCATION,
        },
      },

  steps_status_map: WITH_FULL_MOCKED_DATA
    ? {
        [OnboardingSteps.STEP_01_BASIC_DETAILS]: { status: 'untouched', step: OnboardingSteps.STEP_01_BASIC_DETAILS },
        [OnboardingSteps.STEP_02_CONTACT_DETAILS]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_02_CONTACT_DETAILS,
        },
        [OnboardingSteps.STEP_03_ADVANCED_DETAILS]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_03_ADVANCED_DETAILS,
        },
        [OnboardingSteps.STEP_04_REPRESENTATIVES]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_04_REPRESENTATIVES,
        },
        [OnboardingSteps.STEP_05_FINANCIAL_DETAILS]: {
          status: 'completed',
          step: OnboardingSteps.STEP_05_FINANCIAL_DETAILS,
        },
        [OnboardingSteps.STEP_06_TAX_REGULATIONS]: {
          status: 'completed',
          step: OnboardingSteps.STEP_06_TAX_REGULATIONS,
        },
        [OnboardingSteps.STEP_07_BUSINESS_ACTIVITY]: {
          status: 'completed',
          step: OnboardingSteps.STEP_07_BUSINESS_ACTIVITY,
        },
        [OnboardingSteps.STEP_08_DATA_PROTECTION]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_08_DATA_PROTECTION,
        },
        [OnboardingSteps.STEP_09_DOCUMENTS]: { status: 'untouched', step: OnboardingSteps.STEP_09_DOCUMENTS },
        [OnboardingSteps.STEP_10_ID_VERIFICATION]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_10_ID_VERIFICATION,
        },
        [OnboardingSteps.STEP_11_REVIEW_APPLICATION]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_11_REVIEW_APPLICATION,
        },
      }
    : {
        [OnboardingSteps.STEP_01_BASIC_DETAILS]: { status: 'untouched', step: OnboardingSteps.STEP_01_BASIC_DETAILS },
        [OnboardingSteps.STEP_02_CONTACT_DETAILS]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_02_CONTACT_DETAILS,
        },
        [OnboardingSteps.STEP_03_ADVANCED_DETAILS]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_03_ADVANCED_DETAILS,
        },
        [OnboardingSteps.STEP_04_REPRESENTATIVES]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_04_REPRESENTATIVES,
        },
        [OnboardingSteps.STEP_05_FINANCIAL_DETAILS]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_05_FINANCIAL_DETAILS,
        },
        [OnboardingSteps.STEP_06_TAX_REGULATIONS]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_06_TAX_REGULATIONS,
        },
        [OnboardingSteps.STEP_07_BUSINESS_ACTIVITY]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_07_BUSINESS_ACTIVITY,
        },
        [OnboardingSteps.STEP_08_DATA_PROTECTION]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_08_DATA_PROTECTION,
        },
        [OnboardingSteps.STEP_09_DOCUMENTS]: { status: 'untouched', step: OnboardingSteps.STEP_09_DOCUMENTS },
        [OnboardingSteps.STEP_10_ID_VERIFICATION]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_10_ID_VERIFICATION,
        },
        [OnboardingSteps.STEP_11_REVIEW_APPLICATION]: {
          status: 'untouched',
          step: OnboardingSteps.STEP_11_REVIEW_APPLICATION,
        },
      },
}

export const useOnboardingSessionManager = () => {
  const localStorageSession = localStorage.getItem('leikur-onboarding-session')

  let leikurOnboardingSession = localStorageSession !== null ? (JSON.parse(localStorageSession) as OnboardingSession) : initSession

  if (leikurOnboardingSession.version !== initSession.version) {
    /* reset session if a new version is needed - avoids unstable state. 
    shouldn't cause any issue if we are pulling info from API */
    leikurOnboardingSession = initSession
    // localStorage.setItem('leikur-session', JSON.stringify(leikurSession))
  }
  localStorage.setItem('leikur-onboarding-session', JSON.stringify(leikurOnboardingSession))

  const [session, dispatch] = useReducer(
    onboardingSessionReducer((nextState: OnboardingSession) => localStorage.setItem('leikur-onboarding-session', JSON.stringify(nextState))),
    leikurOnboardingSession
  )

  const clearSession = () => {
    localStorage.removeItem('leikur-onboarding-session')
  }

  return [session, dispatch, clearSession] as [OnboardingSession, React.Dispatch<OnboardingSessionReducerAction>, () => void]
}
