import { Flex, Text, Button } from '@mantine/core'
import { ContextModalProps, modals } from '@mantine/modals'
import { CSSGrid } from '../common/CSSGrid'
import ModalWrapper from './ModalWrapper'

const ConfirmModal = ({
  context,
  id,
  innerProps: { onConfirm, onCancel },
}: ContextModalProps<{ onConfirm: () => void; onCancel?: () => void }>) => {
  const handleConfirm = () => {
    onConfirm()
    context.closeModal(id)
  }

  return (
    <ModalWrapper title='Confirm this action' onClose={() => context.closeModal(id)} key={id}>
      <CSSGrid gap='md'>
        <Text size='md' ta='center' color='textPrimary'>
          Are you sure you want to perform this action?
        </Text>
        <Flex align='center' justify='center' gap='xs'>
          <Button
            radius='xs'
            variant='outline'
            color='error'
            onClick={() => (onCancel ? onCancel() : context.closeModal(id))}
          >
            Cancel
          </Button>

          <Button radius='xl' variant='filled' color='primary' onClick={handleConfirm}>
            Confirm
          </Button>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type OpenConfirmationModalProps = {
  onClose?: () => void
  onConfirm: () => void
}
export function openConfirmModal({ onConfirm, onClose }: OpenConfirmationModalProps) {
  return () =>
    modals.openContextModal({
      modal: 'confirmationModal',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'lg',
      innerProps: { onConfirm, onClose },
    })
}

export default ConfirmModal
