import { createStyles, getSize } from '@mantine/core';
import { sizes } from '../Input.styles';
import { rem } from '@mantine/core';

export default createStyles((theme, { size, rightSectionWidth, inputColor }: any) => ({
  innerInput: {
    width: `calc(100% - ${rem(rightSectionWidth)})`,
    paddingLeft: `calc(${getSize({ size, sizes })} / 3)`,
    fontSize: getSize({ size, sizes: theme.fontSizes }),
    height: `calc(${getSize({ size, sizes })} - 0.125rem)`,
    lineHeight: `calc(${getSize({ size, sizes })} - 0.125rem)`,
    color: inputColor
      ? theme.fn.themeColor(inputColor)
      : theme.other?.defaultInputProps?.inputColor
      ? theme.fn.themeColor(theme.other.defaultInputProps.inputColor)
      : theme.colorScheme === 'dark'
      ? theme.colors.dark[0]
      : theme.black,
  },
  withIcon: {
    paddingLeft: getSize({ size, sizes }),
  },
}));
