import { Flag } from '@innostream/stream-ui'
import { Box, Button, Flex, Loader, MediaQuery, NumberInput, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { ContextModalProps, modals } from '@mantine/modals'
import { useEffect, useState } from 'react'
import useAddCurrency from '../../api/queries/useAddCurrency'
import setCustomInputColorStyles from '../../theme/setCustomInputColorStyles'
import currencies, { currencyFormat } from '../../utils/currencies'
import { ExchangeInfo, getExchangeInfo } from '../../utils/exchange_rates'
import { AccountGroup, AddCurrencyRequest, Currency } from '../../utils/interfaces'
import { CSSGrid } from '../common/CSSGrid'
import { ThemedBackgroundSmallImage } from '../common/ThemedBackgroundImage'
import { getSelectPropsForCurrency } from '../common/currencyDropdownHelper'
import { IconCloseCross, IconDownArrow, IconTickMarkYes } from '../icons'
import ModalWrapper from './ModalWrapper'
import { colorAliases } from '../../theme/getMantineTheme'

const CreateNewCurrencyAccount = ({ context, id, innerProps }: ContextModalProps<{ accountGroup: AccountGroup }>) => {
  const { mutate: addCurrency, isLoading, isSuccess } = useAddCurrency()

  const fromAccount = innerProps.accountGroup.accounts.find((acc) => acc.currency === Currency.EUR) ?? innerProps.accountGroup.accounts[0]
  const usedCurrencies = innerProps.accountGroup.accounts.map((account) => account.currency)

  const supportedCurrencies = (Object.keys(currencies) as Currency[]).filter((currency) => !usedCurrencies.includes(currency))

  const [exchangeInfo, setExchangeInfo] = useState<ExchangeInfo>()

  const form = useForm<
    Omit<AddCurrencyRequest, 'amount' | 'currency'> & {
      amount: number | ''
      currency: Currency | null
    }
  >({
    initialValues: {
      fromAccountIBAN: fromAccount.iban,
      toAccountIBAN: fromAccount.iban,
      amount: '',
      currency: null,
    },
    validate: {
      amount: (value) =>
        value === '' || value <= 0 ? 'Please enter a valid amount.' : fromAccount.balance < value ? 'The amount is greater than the available balance.' : null,
      currency: (value) => (value === null ? 'Please select a currency' : null),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  useEffect(() => {
    if (form.values.amount !== '' && form.values.currency !== null) {
      if (form.values.amount !== exchangeInfo?.fromAmount || form.values.currency !== exchangeInfo.toCurrency) {
        setExchangeInfo(getExchangeInfo({ amount: form.values.amount, fromCurrency: Currency.EUR, toCurrency: form.values.currency }))
      }
    }
  }, [form, exchangeInfo])

  const sendAddCurrencyRequest = () => {
    form.validate()
    if (form.isValid()) {
      addCurrency({
        ...form.values,
      } as AddCurrencyRequest)
    }
  }

  if (isLoading) {
    return (
      <CSSGrid py='xs' gap='md' alignContent='center' justifyItems='center' mih={300}>
        <Loader />
      </CSSGrid>
    )
  }

  if (isSuccess && exchangeInfo) {
    return (
      <ModalWrapper onClose={() => context.closeModal(id)}>
        <CSSGrid gap='md' alignContent='center' justifyItems='center' mih={300} fz={{ base: 'md', desktop: 'xl' }} pb='md'>
          <Text fz={{ base: 'lg', desktop: 'xl' }} fw='bold' color='success'>
            Exchange successful!
          </Text>
          <Box c='success' my='lg' fz={{ base: 12, desktop: 14 }}>
            <IconTickMarkYes style={{ height: '4.5em' }} />
          </Box>
          <Box c='textPrimary' ta='center'>
            <Box fw='bold'>
              {currencyFormat.format(exchangeInfo.toAmount)} {exchangeInfo.toCurrency}
            </Box>{' '}
            <Text fz='lg'>was added to the new account</Text>
          </Box>
          <Flex direction={{ base: 'column', desktop: 'row' }} justify='space-between' c='textPrimary' align='center' fw='bold' gap='md' mt='md'>
            <Flex gap='xs' align='center'>
              <Flag
                countryCode={currencies[Currency.EUR].countryCodeForFlag}
                square={true}
                sx={{ borderRadius: '6px', border: '1pt solid #f5f5f5' }}
                style={{
                  borderRadius: '10px',
                  width: '2.25em',
                  height: '2.25em',
                }}
              />
              <Box>
                {currencyFormat.format(exchangeInfo.fromAmount)} {exchangeInfo.fromCurrency}
              </Box>
            </Flex>
            <MediaQuery largerThan='desktop' styles={{ display: 'none' }}>
              <Flex c='success'>
                <IconDownArrow style={{ height: '0.7em' }} />
              </Flex>
            </MediaQuery>
            <MediaQuery smallerThan='desktop' styles={{ display: 'none' }}>
              <Flex c='success'>
                <IconDownArrow style={{ height: '0.7em', transform: 'rotate(270deg)' }} />
              </Flex>
            </MediaQuery>
            <Flex gap='xs' align='center'>
              <Flag
                countryCode={currencies[exchangeInfo.toCurrency]?.countryCodeForFlag}
                square={true}
                sx={{ borderRadius: '6px', border: '1pt solid #f5f5f5' }}
                style={{
                  borderRadius: '10px',
                  width: '2.25em',
                  height: '2.25em',
                }}
              />
              <Box>
                {currencyFormat.format(exchangeInfo.toAmount)} {exchangeInfo.toCurrency}
              </Box>
            </Flex>
          </Flex>
          <Box c='textMuted' fz='sm' lh={1.3}>
            <Box>
              Exchange rate: <b>{exchangeInfo.exchangeRate}</b>
            </Box>
          </Box>
          <Button
            mt='md'
            radius='xl'
            leftIcon={<IconCloseCross style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
            variant='filled'
            color='success'
            onClick={() => context.closeModal(id)}
          >
            Close
          </Button>
        </CSSGrid>
      </ModalWrapper>
    )
  }

  const inputStyles = setCustomInputColorStyles(innerProps.accountGroup.theme + 'GroupThemeAccent')
  return (
    <ModalWrapper title='Add new currency account' onClose={() => context.closeModal(id)}>
      <CSSGrid gap='md' p='sm'>
        <TextInput
          readOnly={true}
          label='From account'
          labelProps={{ c: 'secondary' }}
          value={fromAccount.alias}
          variant='filled'
          icon={
            <Flex pos='relative' align='center' justify='center' sx={{ transform: 'scale(1.2)', isolation: 'isolate' }}>
              <ThemedBackgroundSmallImage h='md' w='md' theme={innerProps.accountGroup.theme} pos='absolute' sx={{ borderRadius: '50%' }} />
              <Flag
                countryCode={currencies['EUR'].countryCodeForFlag}
                square={true}
                sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
                style={{
                  borderRadius: '50%',
                  width: '12px',
                  height: '12px',
                  zIndex: 1,
                }}
              />
            </Flex>
          }
          styles={inputStyles}
        />

        <NumberInput
          hideControls
          label='Amount (EUR)'
          labelProps={{ c: 'secondary' }}
          description='Enter the amount to transfer'
          variant='filled'
          precision={2}
          {...form.getInputProps('amount')}
          icon={
            <Flex pos='relative' align='center' justify='center'>
              <Flag
                countryCode={currencies[Currency.EUR].countryCodeForFlag}
                square={true}
                sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
                style={{
                  borderRadius: '50%',
                  width: '1rem',
                  height: '1rem',
                  zIndex: 1,
                }}
              />
            </Flex>
          }
          styles={inputStyles}
        />

        <Select
          searchable
          labelProps={{ c: 'secondary' }}
          {...getSelectPropsForCurrency(supportedCurrencies, form.values.currency)}
          label='Currency'
          description='Select the currency to transfer to'
          variant='filled'
          {...form.getInputProps('currency')}
          styles={inputStyles}
        />

        {exchangeInfo && (
          <Box c='textMuted' fz='sm' fs='italic' lh={1.3}>
            <Box>
              1 EUR ≈ {exchangeInfo.exchangeRate} {exchangeInfo.toCurrency}
            </Box>
            <Box>
              {currencyFormat.format(form.values.amount || 0)} EUR ≈ {currencyFormat.format(exchangeInfo.toAmount)} {exchangeInfo.toCurrency}
            </Box>
          </Box>
        )}

        <Flex align='center' justify='right' gap='sm' mt='xs'>
          <Button
            w={125}
            rightIcon={<IconCloseCross style={{ height: '0.75em', width: '0.75rem' }} />}
            variant='outline'
            radius='xs'
            color={colorAliases.borderSubtle}
            c={colorAliases.textInvert}
            onClick={() => context.closeModal(id)}
          >
            Cancel
          </Button>

          <Button
            w={125}
            radius='xs'
            leftIcon={<IconDownArrow style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
            variant='filled'
            color='primary'
            onClick={sendAddCurrencyRequest}
          >
            Exchange
          </Button>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type openModalForCreateNewCurrencyAccount = {
  accountGroup: AccountGroup
  onClose?: () => void
}
export function openModalForCreateNewCurrencyAccount({ accountGroup, onClose }: openModalForCreateNewCurrencyAccount) {
  return () =>
    modals.openContextModal({
      modal: 'createNewCurrencyAccount',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'lg',
      innerProps: {
        accountGroup,
      },
    })
}

export default CreateNewCurrencyAccount
