import React, { useReducer } from 'react'
import { Session, SessionReducerAction } from '../utils/interfaces'
import sessionReducer from './sessionReducer'
import mockedData from '../backend/mockedData'

const initSession = mockedData

/** Hook that manages user session, and exposes current
 * session state, as well as function(s) to manage this
 * state. Typically used by top-level components that wish to
 * manage an session context - i.e. usually at the top-level
 * of the app. The return value is an object suitable to use as the
 * value for a `SessionContext.Provider`.
 */

export const useSessionManager = () => {
  const localStorageSession = localStorage.getItem('leikur-session')

  let leikurSession = localStorageSession !== null ? (JSON.parse(localStorageSession) as Session) : initSession

  if (leikurSession.version !== initSession.version) {
    /* reset session if a new version is needed - avoids unstable state. 
    shouldn't cause any issue if we are pulling info from API */
    leikurSession = initSession
    // localStorage.setItem('leikur-session', JSON.stringify(leikurSession))
  }
  localStorage.setItem('leikur-session', JSON.stringify(leikurSession))

  const [session, dispatch] = useReducer(sessionReducer, leikurSession)

  const clearSession = () => {
    localStorage.removeItem('leikur-session')
  }

  return [session, dispatch, clearSession] as [Session, React.Dispatch<SessionReducerAction>, () => void]
}
