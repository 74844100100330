import { Box, BoxProps, packSx } from '@mantine/core'
import BlueBackground from '../../assets/background/background-blue.jpg'
import LeikurBackground from '../../assets/background/leikur-background.jpg'
import BrownBackground from '../../assets/background/background-brown.jpg'
import GoldBackground from '../../assets/background/background-gold.jpg'
import PurpleBackground from '../../assets/background/background-purple.jpg'
import GreenBackground from '../../assets/background/background-green.jpg'
import SmallBlueBackground from '../../assets/background/background-blue-small.jpg'
import SmallBrownBackground from '../../assets/background/background-brown-small.jpg'
import SmallGoldBackground from '../../assets/background/background-gold-small.jpg'
import SmallPurpleBackground from '../../assets/background/background-purple-small.jpg'
import SmallGreenBackground from '../../assets/background/background-green-small.jpg'
import { Theme } from '../../utils/interfaces'

const backgroundDetails = {
  purple: { url: PurpleBackground as string, alignment: 'right' },
  brown: { url: BrownBackground as string, alignment: 'left' },
  green: { url: GreenBackground as string, alignment: 'right' },
  gold: { url: GoldBackground as string, alignment: 'center' },
  blue: { url: BlueBackground as string, alignment: 'right' },
  leikur: { url: LeikurBackground as string, alignment: 'center' },
}

const smallBackgroundDetails = {
  purple: { url: SmallPurpleBackground as string },
  brown: { url: SmallBrownBackground as string },
  green: { url: SmallGreenBackground as string },
  gold: { url: SmallGoldBackground as string },
  blue: { url: SmallBlueBackground as string },
}

const getBackgroundDetailsByTheme = (theme: Theme | 'leikur') => backgroundDetails[theme]

const ThemedBackgroundImage: React.FC<BoxProps & { theme?: Theme | 'leikur' }> = ({ theme = 'blue', sx, ...props }) => {
  const selectedBackground = getBackgroundDetailsByTheme(theme)
  return (
    <Box
      className='background-image'
      inset={0}
      pos='absolute'
      {...props}
      sx={[
        (mantineTheme) => ({
          borderRadius: 'inherit',
          backgroundColor: mantineTheme.fn.themeColor(`${theme}GroupThemeAccent`),
        }),
        ...packSx(sx),
      ]}
      bg={`url(${selectedBackground.url}) ${selectedBackground.alignment}/cover`}
    />
  )
}

const getSmallBackgroundDetailsByTheme = (theme: Theme) => smallBackgroundDetails[theme]

export const ThemedBackgroundSmallImage: React.FC<BoxProps & { theme?: Theme }> = ({
  theme = 'blue',
  sx,
  ...props
}) => {
  const selectedBackground = getSmallBackgroundDetailsByTheme(theme)
  return (
    <Box
      className='background-image'
      h={64}
      {...props}
      sx={[
        (mantineTheme) => ({
          aspectRatio: '1 / 1',
          borderRadius: mantineTheme.radius.xs,
          backgroundColor: mantineTheme.fn.themeColor(`${theme}GroupThemeAccent`),
        }),
        ...packSx(sx),
      ]}
      bg={`url(${selectedBackground.url})`}
    />
  )
}

export default ThemedBackgroundImage
