import { Flag } from '@innostream/stream-ui'
import { Anchor, Box, Button, Collapse, Container, Divider, Flex, Loader, Paper, PaperProps, Text, Title } from '@mantine/core'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import {
  IconCash,
  IconDocChecked,
  IconDocCrossed,
  IconDownArrow,
  IconMoneySign,
  IconTemplate,
  IconTickMarkYes,
} from '../../../../components/icons'
import currencies, { currencyFormat } from '../../../../utils/currencies'
import { PaymentRequest, PaymentRequestTypes } from '../../../../utils/interfaces'
import { colorAliases } from '../../../../theme/getMantineTheme'

const getOutlineButtonProps = () => ({
  h: '2.1rem',
  size: 'xs',
})

const ExistingTemplate: React.FC<{ isLastInGroup: boolean; data: PaymentRequest }> = ({ isLastInGroup, data }) => {
  const navigate = useNavigate()
  const [isOpened, setIsOpened] = useState(false)

  return (
    <Box
      mb={isLastInGroup ? '3xs' : 0}
      py='md'
      px='sm'
      sx={(theme) => ({
        cursor: 'pointer',
        borderColor: theme.fn.themeColor('muted'),
        borderWidth: '1pt',
        borderTopStyle: 'solid',
        borderBottomStyle: isLastInGroup ? 'solid' : 'none',
        '.outline-button': {
          background: theme.fn.themeColor(colorAliases.light700),
        },
        [':nth-of-type(odd)']: {
          background: theme.fn.themeColor(colorAliases.light700),
          '.outline-button': {
            background: theme.fn.themeColor('white.6'),
          },
        },
      })}
    >
      <CSSGrid
        key={data.id}
        templateAreas={{
          base: '"name amount" "last_modified last_modified" "buttons buttons"',
          desktop: '"name amount buttons" "last_modified last_modified buttons"',
        }}
        templateColumns={{ base: '1fr auto', desktop: '1fr auto auto' }}
        gap='md'
        onClick={() => setIsOpened((v) => !v)}
      >
        <Flex align='center' sx={{ gridArea: 'name' }} gap='2xs'>
          <Box component={IconCash} h='xl' c='secondary' />
          {data.internal_reference}
        </Flex>
        <Flex align='center' sx={{ gridArea: 'amount' }} gap='3xs'>
          <Box component='span' c='secondary' fz='lg' fw='bold'>
            {currencyFormat.format(data.beneficiaries?.reduce((total, b) => b.amount + total, 0) || 0)}
          </Box>
          <Box component='span' c='secondary' fz='sm' mr='3xs'>
            {data.currency}
          </Box>
          <Flag
            countryCode={currencies[data.currency]?.countryCodeForFlag}
            square={true}
            style={{
              borderRadius: '10px',
              width: '1rem',
              height: '1rem',
            }}
          />
        </Flex>
        <Flex align='center' justify='space-between' sx={{ gridArea: 'last_modified' }}>
          <Flex justify='center' direction='column'>
            <Box component='span' fz='xs' c={colorAliases.textInvert} lh={1.5} mb='3xs'>
              Date last modified
            </Box>
            <Box component='span' fz='xs' c='secondary'>
              {dayjs(data.last_modified_date).format('DD MMMM YYYY, [at] h:mm A (Z)')}
            </Box>
          </Flex>
          <Flex justify='center' direction='column' ta='right'>
            <Box component='span' fz='xs' c={colorAliases.textInvert} lh={1.5} mb='3xs'>
              Last modified by
            </Box>
            <Box component='span' fz='xs' c='secondary'>
              {data.last_modified_by}
            </Box>
          </Flex>
        </Flex>
        <CSSGrid gap='xs' w={{ desktop: '180px' }} sx={{ gridArea: 'buttons' }} alignContent='start'>
          <Button
            {...getOutlineButtonProps()}
            leftIcon={<IconMoneySign style={{ width: '2em', color: 'white' }} />}
            onClick={() => navigate(`/transaction-centre/manage/new-request/${data.id}`)}
            color='secondary'
          >
            Pay from template
          </Button>
          <Button
            {...getOutlineButtonProps()}
            variant={isOpened ? 'filled' : 'outline'}
            c={colorAliases.textInvert}
            color='white'
            sx={(theme) => ({
              border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`,
            })}
            styles={{
              root: {
                '&:hover': {
                  backgroundColor: colorAliases.textInvert,
                }
              },
            }}
            leftIcon={
              <IconDownArrow
                style={{
                  height: '0.4rem',
                  transform: isOpened ? 'rotate(180deg)' : '',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />
            }
          >
            {isOpened ? 'Close Details' : 'View Details'}
          </Button>
        </CSSGrid>
      </CSSGrid>
      <Collapse in={isOpened} mt='md' c={colorAliases.textInvert} transitionDuration={500}>
        <Box
          p='md'
          fz='xs'
          bg='white'
          sx={(theme) => ({
            border: `1pt dashed ${theme.fn.themeColor('secondary')}`,
          })}
        >
          <Text fz='sm' fw={700} pb='3xs'>
            Template details
          </Text>
          <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gap={{ base: 'md', desktop: '2xl' }}>
            <Flex direction='column' gap='3xs'>
              <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5}>
                Internal Reference <b style={{ textTransform: 'capitalize' }}>{data.internal_reference}</b>
              </Text>
              <Text fw='bold' display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5} mt='xs'>
                Beneficiary Details
              </Text>
              <Divider />
              <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5} mt='xs'>
                Beneficiary <b>{data.beneficiaries[0]?.name}</b>
              </Text>
              <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5}>
                Amount
                <b>{data.beneficiaries[0]?.amount}</b>
              </Text>
              <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5}>
                Currency
                <Flex gap='xs'>
                  <Flag
                    countryCode={currencies[data.currency]?.countryCodeForFlag}
                    square={true}
                    style={{
                      borderRadius: '10px',
                      width: '1rem',
                      height: '1rem',
                    }}
                  />
                  <b>{data.currency}</b>
                </Flex>
              </Text>
              <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5}>
                Beneficiary Reference <b style={{ textTransform: 'capitalize' }}>{data.beneficiaries[0].reference}</b>
              </Text>
              <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5}>
                Proof Of Payment - Mobile<b>{data.beneficiaries[0].popMobileNumber?.formattedPhoneNumber ?? '-'}</b>
              </Text>
              <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.5}>
                Proof Of Payment - Email<b>{data.beneficiaries[0].popEmail}</b>
              </Text>
            </Flex>
            <Box>
              <Flex align='center' mb='xs'>
                <Box component={IconDocChecked} h='xl' c='secondary' />
                <Text fz='xs' color={colorAliases.textInvertSecondary} ml='2xs'>
                  Supporting documentation justifying payment (contract, receipt, invoice)
                </Text>
              </Flex>
              <Divider size='xs' color='gray.2' w='12rem' mb='sm' />
              {data.beneficiaries[0]?.documents && data.beneficiaries[0]?.documents.length > 0 ? (
                data.beneficiaries[0]?.documents?.map((document) => (
                  <React.Fragment key={document.document_id}>
                    <CSSGrid templateColumns='auto 2fr auto auto' key={document.document_id} mb='2xs' gap='2xs' mt='sm' alignItems='center'>
                      <Box component={IconTickMarkYes} h='sm' c='success' ml={6}></Box>
                      <Flex fz='xs' c='primary' align='center'>
                        <Anchor href={document.document_url} target='_blank' ml='sm'>
                          {document.document_name}
                        </Anchor>
                      </Flex>
                    </CSSGrid>
                  </React.Fragment>
                ))
              ) : (
                <>
                  <Flex align='center'>
                    <Box component={IconDocCrossed} h='xl' c='muted' />
                    <Box fz='xs' color='muted' ml='2xs'>
                      No supporting documentation
                    </Box>
                  </Flex>
                </>
              )}
            </Box>
          </CSSGrid>
        </Box>
      </Collapse>
    </Box>
  )
}

const ExistingTemplatesArea = ({
  isSuccess,
  isLoading,
  paymentTemplates,
  ...props
}: PaperProps & { isLoading: boolean; isSuccess: boolean; paymentTemplates: PaymentRequest[] }) => {
  const navigate = useNavigate()
  return (
    <Paper p='xl' {...props}>
      <Flex justify='space-between' align='center'>
        <Title order={3} fw={300} fz={{ base: 'xl' }}>
          Payment Templates
        </Title>
        <Button
          display={{ desktop: 'none' }}
          variant='subtle'
          leftIcon={<IconTemplate style={{ height: '1.5em', aspectRatio: '1' }} />}
          onClick={() => navigate(`/transaction-centre/manage/${PaymentRequestTypes.NEW_TEMPLATE}`)}
        >
          Add&nbsp;new
        </Button>
      </Flex>
      <Divider color={colorAliases.borderHighlight} size='xs' mt='2xs' />
      <Box>
        {isLoading || !paymentTemplates ? (
          <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }} py='md'>
            <Loader />
          </Container>
        ) : isSuccess && paymentTemplates.length === 0 ? (
          <Flex justify='center' align='center' p='xl' pb={0}>
            <Text fz={{ base: 'xl', desktop: 'xl' }} fw='bold' color='secondary' ta='center'>
              No templates found. Try clearing the filters or adding a new template.
            </Text>
          </Flex>
        ) : (
          <>
            {paymentTemplates &&
              paymentTemplates.map((paymentTemplate, index) => (
                <ExistingTemplate key={`${paymentTemplate.id}${index}`} data={paymentTemplate} isLastInGroup={index === paymentTemplates.length - 1} />
              ))}
          </>
        )}
      </Box>
    </Paper>
  )
}

export default ExistingTemplatesArea
