import IconAttachFile from '../../assets/icons/attach-file.svg'
import IconAccountDetails from '../../assets/icons/icon-account-details.svg'
import IconAccountProfile from '../../assets/icons/icon-account-profile.svg'
import IconAccountView from '../../assets/icons/icon-account-view.svg'
import IconAddOne from '../../assets/icons/icon-add-1.svg'
import IconAddTwo from '../../assets/icons/icon-add-2.svg'
import IconAddPaymentAccount from '../../assets/icons/icon-add-new-account.svg'
import IconAnalytics from '../../assets/icons/icon-analytics.svg'
import IconArchive from '../../assets/icons/icon-archive.svg'
import IconArrangeIconsController from '../../assets/icons/icon-arrange-icons-controller.svg'
import IconBackCancel from '../../assets/icons/icon-back-cancel.svg'
import IconBeneficiaryAddNew from '../../assets/icons/icon-beneficiary-add-new.svg'
import IconBeneficiaryGroupAdd from '../../assets/icons/icon-beneficiary-group-add.svg'
import IconBeneficiaryGroupModify from '../../assets/icons/icon-beneficiary-group-modify.svg'
import IconBeneficiaryLeikur from '../../assets/icons/icon-beneficiary-leikur.svg'
import IconBeneficiaryNameBookmark from '../../assets/icons/icon-beneficiary-name-bookmark.svg'
import IconBeneficiarySepa from '../../assets/icons/icon-beneficiary-sepa-iban-eu.svg'
import IconBeneficiarySwift from '../../assets/icons/icon-beneficiary-swift-international.svg'
import IconBeneficiaryViewSupportingDocs from '../../assets/icons/icon-beneficiary-view-supporting-docs.svg'
import IconDocClean from '../../assets/icons/icon-doc-clean.svg'
import IconDocChecked from '../../assets/icons/icon-doc-checked.svg'
import IconArrowLeft from '../../assets/icons/icon-arrow-left.svg'
import IconArrowRight from '../../assets/icons/icon-arrow-right.svg'
import IconChecked from '../../assets/icons/icon-check.svg'
import IconDocCrossed from '../../assets/icons/icon-doc-crossed.svg'
import IconChat from '../../assets/icons/icon-chat.svg'
import IconCloseCross from '../../assets/icons/icon-close-cross.svg'
import IconCross from '../../assets/icons/icon-cross.svg'
import IconCrossCircle from '../../assets/icons/icon-cross-circle.svg'
import IconDownArrow from '../../assets/icons/icon-down-arrow.svg'
import IconDownloadProofPayment from '../../assets/icons/icon-download-proof-payment.svg'
import IconEditModifyPen from '../../assets/icons/icon-edit-modify-pen.svg'
import IconFundsCard from '../../assets/icons/icon-funds-card.svg'
import IconFundsConvertForex from '../../assets/icons/icon-funds-convert-forex.svg'
import IconPeople from '../../assets/icons/icon-people.svg'
import IconFundsReceive from '../../assets/icons/icon-funds-receive.svg'
import IconFundsSend from '../../assets/icons/icon-funds-send.svg'
import IconFundsTransactMenu from '../../assets/icons/icon-funds-transact-menu.svg'
import IconFundsTransfer from '../../assets/icons/icon-funds-transfer.svg'
import IconHelpQuestion from '../../assets/icons/icon-help-question.svg'
import IconLimits from '../../assets/icons/icon-limits.svg'
import IconLogOut from '../../assets/icons/icon-log-out.svg'
import IconMenuMain from '../../assets/icons/icon-menu-main.svg'
import IconMessageContact from '../../assets/icons/icon-message-contact.svg'
import IconPaymentTemplateCompleted from '../../assets/icons/icon-payment-template-completed.svg'
import IconRightArrow from '../../assets/icons/icon-right-arrow.svg'
import IconSaveExport from '../../assets/icons/icon-save-export.svg'
import IconSave from '../../assets/icons/icon-save.svg'
import IconSearch from '../../assets/icons/icon-search.svg'
import IconSocialBlog from '../../assets/icons/icon-social-blog.svg'
import IconSocialFacebook from '../../assets/icons/icon-social-facebook.svg'
import IconSocialInstagram from '../../assets/icons/icon-social-instagram.svg'
import IconSocialLinkedIn from '../../assets/icons/icon-social-linkedin.svg'
import IconSocialTwitter from '../../assets/icons/icon-social-twitter.svg'
import IconStatementCentre from '../../assets/icons/icon-statement-centre.svg'
import IconTickMarkYes from '../../assets/icons/icon-tick-mark-yes.svg'
import IconAdjustCircle from '../../assets/icons/icon-adjust-circle.svg'
import IconTickCircle from '../../assets/icons/icon-tick-circle.svg'
import IconViewInfo from '../../assets/icons/icon-info.svg'
import IconViewInfoEye from '../../assets/icons/icon-view-info-eye.svg'
import IconViewInfoEyeOff from '../../assets/icons/icon-view-info-eye-off.svg'
import IconAuthorise from '../../assets/icons/icon-signature-authorise.svg'
import IconWalletForex from '../../assets/icons/icon-wallet-forex.svg'
import IconPayment from '../../assets/icons/icon-payment.svg'
import IconCash from '../../assets/icons/icon-cash.svg'
import IconMoneySign from '../../assets/icons/icon-money-sign.svg'
import IconSendMoney from '../../assets/icons/icon-send-money.svg'
import IconTemplate from '../../assets/icons/icon-template.svg'
import IconPlusClear from '../../assets/icons/icon-plus-clear.svg'
import IconPlusCircle from '../../assets/icons/icon-plus-circle.svg'
import IconArrowPushBack from '../../assets/icons/icon-arrow-push-back.svg'
import IconCampaign from '../../assets/icons/campaign.svg'
import IconLogIn from '../../assets/icons/icon-log-in.svg'
import IconNotifications from '../../assets/icons/icon-notifications.svg'
import IconHome from '../../assets/icons/icon-home.svg'
import IconPending from '../../assets/icons/icon-pending.svg'
import IconPreliminary from '../../assets/icons/icon-preliminary.svg'

// NEW ICONS
import IconAccount from '../../assets/icons/icon_account.svg'
import IconBell from '../../assets/icons/icon_bell.svg'
import IconBeneficiary from '../../assets/icons/icon_beneficiary.svg'
import IconChevron from '../../assets/icons/icon_chevron.svg'
import IconCopy from '../../assets/icons/icon_copy.svg'
import IconEdit from '../../assets/icons/icon_edit.svg'
import IconExchange from '../../assets/icons/icon_exchange.svg'
import IconLogout from '../../assets/icons/icon_logout.svg'
import IconMenu from '../../assets/icons/icon_menu.svg'
import IconMessage from '../../assets/icons/icon_message.svg'
import IconPaymentProcessing from '../../assets/icons/icon_payment_processing.svg'
import IconPlus from '../../assets/icons/icon_plus.svg'
import IconReceive from '../../assets/icons/icon_receive.svg'
import IconSend from '../../assets/icons/icon_send.svg'
import IconStatement from '../../assets/icons/icon_statement.svg'

export {
  IconAccount,
  IconBell,
  IconBeneficiary,
  IconChevron,
  IconCopy,
  IconEdit,
  IconExchange,
  IconLogout,
  IconMenu,
  IconMessage,
  IconPaymentProcessing,
  IconPlus,
  IconReceive,
  IconSend,
  IconStatement,

  // OLD ICONS
  IconAttachFile,
  IconAccountDetails,
  IconAccountProfile,
  IconAccountView,
  IconAddOne,
  IconAddTwo,
  IconAddPaymentAccount,
  IconAnalytics,
  IconArchive,
  IconArrangeIconsController,
  IconArrowPushBack,
  IconBackCancel,
  IconBeneficiaryAddNew,
  IconBeneficiaryGroupAdd,
  IconBeneficiaryGroupModify,
  IconBeneficiaryLeikur,
  IconBeneficiaryNameBookmark,
  IconBeneficiarySepa,
  IconBeneficiarySwift,
  IconBeneficiaryViewSupportingDocs,
  IconDocClean,
  IconDocCrossed,
  IconDocChecked,
  IconArrowLeft,
  IconArrowRight,
  IconChat,
  IconCloseCross,
  IconCross,
  IconCrossCircle,
  IconChecked,
  IconDownArrow,
  IconDownloadProofPayment,
  IconEditModifyPen,
  IconFundsCard,
  IconFundsConvertForex,
  IconPeople,
  IconFundsReceive,
  IconFundsSend,
  IconFundsTransactMenu,
  IconFundsTransfer,
  IconHelpQuestion,
  IconLimits,
  IconLogOut,
  IconMenuMain,
  IconMessageContact,
  IconPaymentTemplateCompleted,
  IconRightArrow,
  IconSaveExport,
  IconSave,
  IconSearch,
  IconSocialBlog,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialLinkedIn,
  IconSocialTwitter,
  IconStatementCentre,
  IconTickMarkYes,
  IconTickCircle,
  IconAdjustCircle,
  IconViewInfoEye,
  IconViewInfoEyeOff,
  IconViewInfo,
  IconWalletForex,
  IconPayment,
  IconSendMoney,
  IconMoneySign,
  IconCash,
  IconTemplate,
  IconPlusClear,
  IconPlusCircle,
  IconAuthorise,
  IconCampaign,
  IconLogIn,
  IconNotifications,
  IconHome,
  IconPending,
  IconPreliminary,
}
