import { NumberInput as MantineNumberInput, NumberInputProps as MantineNumberInputProps } from '@mantine/core';
import React, { forwardRef } from 'react';

import useStyles from '../Input.styles';
import { SharedInputProps } from '../SharedInputProps';

export interface NumberInputProps extends MantineNumberInputProps, SharedInputProps {}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      classNames,
      size = 'md',
      variant = 'default',
      inputWrapperOrder = ['label', 'input', 'description', 'error'],
      hideControls = true,
      ...props
    }: NumberInputProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        variant,
        size,
        hideControls,
      },
      { classNames, name: 'custom' }
    );

    return (
      <MantineNumberInput
        ref={ref}
        variant={variant}
        size={size}
        hideControls={hideControls}
        inputWrapperOrder={inputWrapperOrder}
        classNames={classes}
        {...props}
      />
    );
  }
);
