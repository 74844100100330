import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { AddCurrencyRequest, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const addCurrency = async (dispatch: React.Dispatch<SessionReducerAction>, request: AddCurrencyRequest) => {
  return new Promise<void>((resolve) => {
    dispatch({
      type: SessionReducerActionType.ADD_CURRENCY,
      payload: { ...request },
    })
    resolve()
  })
}

export default function useAddCurrency(onSuccess?: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: AddCurrencyRequest) => addCurrency(dispatch, request),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['accounts'] })
      await queryClient.invalidateQueries({ queryKey: ['account-groups'] })
      await queryClient.invalidateQueries({ queryKey: ['trasactions'] })
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}
