import { ActionIcon, Flex, Paper, Text } from '@mantine/core'
import { StepBox } from './StepBox'
import { CSSGrid } from '../CSSGrid'
import { IconCloseCross } from '../../icons'
import { colorAliases } from '../../../theme/getMantineTheme'

const Instructions: React.FC<{
  dismissHelp: () => void
  stepThreeColor?: string
  instructions: string | React.ReactFragment
  stepOne: string
  stepTwo: string
  stepThree: string
}> = ({ dismissHelp, instructions, stepOne, stepTwo, stepThree }) => {
  return (
    <Paper px='lg' py={{ base: 'xl', desktop: 'sm' }} mb={{ base: 'xl', desktop: '3xl' }} bg='light.7'>
      <CSSGrid
        gap='1rem'
        templateColumns={{ base: '1fr', desktop: '6fr 5fr 5fr 5fr auto' }}
        alignItems='center'
      >
        <Flex justify='space-between'>
          <Text fw={900} c='black' fz='lg' lh='1.25'>
            {instructions}
          </Text>
          <ActionIcon color={colorAliases.borderHighlight} size='xs' variant='transparent' display={{ base: 'block', desktop: 'none' }} onClick={dismissHelp}>
            <IconCloseCross />
          </ActionIcon>
        </Flex>
        <StepBox step='1'>{stepOne}</StepBox>
        <StepBox step='2'>{stepTwo}</StepBox>
        <StepBox step='3' bg={colorAliases.brandSecondary}>
          {stepThree}
        </StepBox>
        <ActionIcon color={colorAliases.borderHighlight} size='xs' variant='transparent' display={{ base: 'none', desktop: 'block' }} onClick={dismissHelp}>
          <IconCloseCross />
        </ActionIcon>
      </CSSGrid>
    </Paper>
  )
}

export default Instructions
