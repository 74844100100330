import { createStyles } from '@mantine/core'
import { MenuItemProps } from './Menu'

export default createStyles((theme, params: Partial<MenuItemProps>) => {
  const borderSizes: Record<string, string> = {
    xs: '1pt',
    sm: '2pt',
    md: '4pt',
    lg: '8pt',
    xl: '16pt',
  }

  const color = theme.fn.themeColor(params.textColor as string, undefined, true, true)
  const descriptionColor = params.descriptionColor
    ? theme.fn.themeColor(params.descriptionColor as string, undefined, false, true)
    : theme.colorScheme === 'dark'
    ? '#c0c0c0'
    : '#606060'
  const borderWidth = params.borderWidth || 'xs'
  const borderColor = theme.fn.themeColor(params.borderColor as string, undefined, true, true)
  const onHoverBackgroundColor = params.onHoverBackgroundColor
    ? theme.fn.themeColor(params.onHoverBackgroundColor, undefined, false, true)
    : theme.fn.themeColor('background', theme.colorScheme === 'dark' ? 5 : 7, false)

  return {
    menuItem: {
      borderTop:
        params.borderPosition === 'top' || params.borderPosition === 'top and bottom'
          ? `${typeof borderWidth === 'number' ? borderWidth : borderSizes[borderWidth]} solid ${borderColor}`
          : '',
      borderBottom:
        params.borderPosition === 'bottom' || params.borderPosition === 'top and bottom'
          ? `${typeof borderWidth === 'number' ? borderWidth : borderSizes[borderWidth]} solid ${borderColor}`
          : '',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      fontSize: '1.5625rem',
      fontFamily: theme.fontFamily,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: onHoverBackgroundColor,
      },
    },
    menuIcon: {
      width: '10%',
    },
    itemWrapper: {
      borderBottom: `2pt solid ${color}`,
    },
    itemHeader: {
      lineHeight: 1.3,
      fontSize: '1.25rem',
      fontFamily: theme.fontFamily,
      color,
    },
    itemDescription: {
      lineHeight: 1.3,
      fontSize: '0.875rem',
      color: descriptionColor,
    },
    rightAlign: {
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
  }
})
