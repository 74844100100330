import { createStyles, getSize, rem } from '@mantine/core';
import { ChipProps } from './Chip';

const padding = {
  xs: rem(16),
  sm: rem(20),
  md: rem(24),
  lg: rem(28),
  xl: rem(32),
};

export default createStyles((theme, params: ChipProps) => (
  {
    iconWrapper: {
      display: 'none',
    },
    label: {
      display: 'inline-grid',
      alignContent: 'center',
      color: params.inactiveColor
        ? theme.fn.themeColor(params.inactiveColor as string)
        : 'inherit',

      fontWeight: 500,
      '&[data-checked]': {
        color: params.activeColor
          ? theme.fn.themeColor(params.activeColor as string)
          : '#ffffff',
        paddingLeft: getSize({
          size: params.size || 'md',
          sizes: padding,
        }),
        paddingRight: getSize({
          size: params.size || 'md',
          sizes: padding,
        }),
      },

      // FIXME: data-variant filled no longer set
      '&[data-variant="filled"]': {
        border: `2pt solid ${theme.fn.themeColor(params.inactiveBorderColor || params.inactiveBackgroundColor || 'gray.6')}`,
        backgroundColor: params.inactiveBackgroundColor
          ? theme.fn.themeColor(params.inactiveBackgroundColor)
          : 'transparent',
        ':hover': {
          border: `2pt solid ${theme.fn.themeColor(params.activeBorderColor || params.activeBackgroundColor || params.color as string)}`,
          backgroundColor: params.inactiveBackgroundColor
            ? theme.fn.lighten(theme.fn.themeColor(params.inactiveBackgroundColor), 0.1)
            : undefined,
        },
        '&[data-checked]': {
          border: `2pt solid ${theme.fn.themeColor(params.activeBorderColor || params.activeBackgroundColor || params.color as string)}`,
          backgroundColor: theme.fn.themeColor(params.activeBackgroundColor || params.color as string),
          ':hover': {
            backgroundColor: theme.fn.lighten(theme.fn.themeColor(params.activeBackgroundColor || params.color as string), 0.1),
          },
          ':active': {
            backgroundColor: theme.fn.darken(theme.fn.themeColor(params.activeBackgroundColor || params.color as string), 0.1),
          },
        },
      },
    },
  }
)
);
