import { createStyles, getSize } from '@mantine/core';
import { sizes } from '../../Inputs/Input.styles';

export default createStyles(
  (
    theme,
    {
      variant,
      size,
      rightSectionWidth,
      withRightSection,
      radius,
      color,
      borderColor,
      inputColor,
      labelColor,
      labelFontSize,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
      labelFontWeight,
    }: any
  ) => {
    const sizeStyles: any =
      variant === 'default' || variant === 'filled'
        ? {
          minHeight: getSize({ size, sizes }),
          paddingLeft: `calc(${getSize({ size, sizes })} / 3)`,
          paddingRight: withRightSection
            ? rightSectionWidth
            : `calc(${getSize({ size, sizes })} / 3)`,
        }
        : null;
    return {
      dropdown: {
        backgroundColor:
          theme.colors.background ?
            theme.fn.themeColor('background')
            :
            (theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white),
        '*': {
          boxSizing: 'border-box',
        },
      },
      item: {
        padding: `${getSize({ size, sizes: theme.spacing }) / 1.5} ${getSize({ size, sizes: theme.spacing })}`,

        '&[data-hovered]': {
          backgroundColor: theme.colors.background
            ? theme.fn.darken(theme.fn.themeColor('background'), 0.1)
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[4]
              : theme.colors.gray[1],
        },

        '&[data-selected]': {
          backgroundColor: theme.fn.variant({ variant: 'filled', color, primaryFallback: true }).background,
          color: theme.colors.background ?
            theme.fn.themeColor('background')
            : theme.white,
          ...theme.fn.hover({ backgroundColor: theme.fn.variant({ variant: 'filled', color, primaryFallback: true }).hover }),
        },
      },
      input: {
        height: getSize({ size, sizes }),
        ...sizeStyles,
        color: inputColor
          ? theme.fn.themeColor(inputColor)
          : theme.other?.defaultInputProps?.inputColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.inputColor)
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[0]
              : theme.black,
        backgroundColor: variant === 'default' ? 'transparent' : undefined,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderRadius: variant === 'filled' ? theme.fn.radius(radius) : 0,
        borderColor: borderColor
          ? theme.fn.themeColor(borderColor)
          : theme.other?.defaultInputProps?.borderColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.borderColor)
            : variant === 'default'
              ? theme.colors.gray[5]
              : undefined,
        ':focus, :focus-within': {
          boxShadow: `0 0 0 1pt ${borderColor
            ? theme.fn.themeColor(borderColor)
            : theme.other?.defaultInputProps?.borderColor
              ? theme.fn.themeColor(theme.other.defaultInputProps.borderColor)
              : theme.fn.themeColor(color)}`,
          borderRadius: theme.fn.radius(radius),
          borderBottomColor: borderColor
            ? theme.fn.themeColor(borderColor)
            : theme.other?.defaultInputProps?.borderColor
              ? theme.fn.themeColor(theme.other.defaultInputProps.borderColor)
              : theme.fn.themeColor(color),
        },
        '&::placeholder': {
          color: theme.colors.muted ? theme.colors.muted[6] : theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
          fontSize: `calc(${getSize({ size: size, sizes: theme.fontSizes, })} * 0.875)`,
          fontStyle: 'italic',
        },
      },
      label: {
        fontSize: getSize({
          size:
            labelFontSize ||
            theme.other?.defaultInputProps?.labelFontSize ||
            size,
          sizes: theme.fontSizes,
        }),
        fontWeight:
          labelFontWeight ||
          theme.other?.defaultInputProps?.labelFontWeight ||
          500,
        color: labelColor
          ? theme.fn.themeColor(labelColor)
          : theme.other?.defaultInputProps?.labelColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.labelColor)
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[0]
              : theme.colors.gray[9],
      },
      description: {
        fontSize: otherFontSize
          ? getSize({
            size: otherFontSize,
            sizes: theme.fontSizes,
          })
          : theme.other?.defaultInputProps?.otherFontSize
            ? getSize({
              size: theme.other.defaultInputProps.otherFontSize,
              sizes: theme.fontSizes,
            })
            : `calc(${getSize({ size, sizes: theme.fontSizes })} - 0.25rem)`,
        fontWeight:
          otherFontWeight ||
          theme.other?.defaultInputProps?.otherFontWeight ||
          400,
        color: descriptionColor
          ? theme.fn.themeColor(descriptionColor)
          : theme.other?.defaultInputProps?.descriptionColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.descriptionColor)
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[2]
              : theme.colors.gray[7],
      },
      error: {
        paddingLeft: 16,
        paddingTop: 2,
        fontSize: otherFontSize
          ? getSize({ size: otherFontSize, sizes: theme.fontSizes, })
          : theme.other?.defaultInputProps?.otherFontSize
            ? getSize({ size: theme.other.defaultInputProps.otherFontSize, sizes: theme.fontSizes, })
            : `calc(${getSize({ size, sizes: theme.fontSizes })} - 0.25rem)`,
        fontWeight:
          otherFontWeight ||
          theme.other?.defaultInputProps?.otherFontWeight ||
          400,
        color: errorColor
          ? theme.fn.themeColor(errorColor)
          : theme.other?.defaultInputProps?.errorColor
            ? theme.fn.themeColor(theme.other.defaultInputProps.errorColor)
            : theme.fn.variant({ variant: 'filled', color: 'red' }).background,
      },
    };
  }
);
