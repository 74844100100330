import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Beneficiary, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const editBeneficiary = (dispatch: React.Dispatch<SessionReducerAction>, beneficiary: Beneficiary) => {
  return new Promise<Beneficiary>((resolve) => {
    dispatch({
      type: SessionReducerActionType.EDIT_BENEFICIARY,
      payload: beneficiary,
    })
    resolve(beneficiary)
  })
}

export default function useEditBeneficiary(onSuccess: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (beneficiary: Beneficiary) => editBeneficiary(dispatch, beneficiary),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['beneficiaries'] })
      onSuccess()
    },
  })
}
