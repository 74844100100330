import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequest, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const updatePaymentRequest = async (dispatch: React.Dispatch<SessionReducerAction>, paymentRequest: PaymentRequest) => {
  return new Promise((resolve) => {
    dispatch({
      type: SessionReducerActionType.EDIT_PAYMENT_REQUEST,
      payload: paymentRequest,
    })
    resolve(paymentRequest)
  })
}

export default function useUpdatePaymentRequest(onSuccess?: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (paymentRequest: PaymentRequest) => updatePaymentRequest(dispatch, paymentRequest),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['payment-requests'] })
      onSuccess && onSuccess()
    },
  })
}
