import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { InternalTransferRequest, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const internalTransfer = async (
  dispatch: React.Dispatch<SessionReducerAction>,
  internalTransferRequest: InternalTransferRequest
) => {
  return new Promise((resolve) => {
    dispatch({
      type: SessionReducerActionType.MAKE_INTERNAL_TRANSFER,
      payload: internalTransferRequest,
    })
    resolve(internalTransferRequest)
  })
}

export default function useInternalTransfer() {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (internalTransferRequest: InternalTransferRequest) =>
      internalTransfer(dispatch, internalTransferRequest),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['accounts'] })
      await queryClient.invalidateQueries({ queryKey: ['account-groups'] })
      await queryClient.invalidateQueries({ queryKey: ['transactions'] })
    },
  })
}
