import { createStyles, getSize } from "@mantine/core";


export interface PhoneNumberInputStylesParams {
  labelFontSize: string;
  size: string;
  labelFontWeight: string;
  labelColor: string;
  otherFontSize: string;
  otherFontWeight: string;
  descriptionColor: string;
  errorColor: string;
}


export default createStyles((theme, { labelFontSize, size, labelFontWeight, labelColor, otherFontSize, otherFontWeight, descriptionColor, errorColor }: PhoneNumberInputStylesParams) => ({
  label: {
    fontSize: getSize({
      size:
        labelFontSize ||
        theme.other?.defaultInputProps?.labelFontSize ||
        size,
      sizes: theme.fontSizes,
    }),
    fontWeight:
      labelFontWeight ||
      theme.other?.defaultInputProps?.labelFontWeight ||
      500,
    color: labelColor
      ? theme.fn.themeColor(labelColor)
      : theme.other?.defaultInputProps?.labelColor
        ? theme.fn.themeColor(theme.other.defaultInputProps.labelColor)
        : theme.fn.primaryColor(),
  },
  description: {
    fontSize: otherFontSize
      ? getSize({
        size: otherFontSize,
        sizes: theme.fontSizes,
      })
      : theme.other?.defaultInputProps?.otherFontSize
        ? getSize({
          size: theme.other.defaultInputProps.otherFontSize,
          sizes: theme.fontSizes,
        })
        : `calc(${getSize({ size, sizes: theme.fontSizes })} - 0.25rem)`,
    fontWeight:
      otherFontWeight ||
      theme.other?.defaultInputProps?.otherFontWeight ||
      400,
    color: descriptionColor
      ? theme.fn.themeColor(descriptionColor)
      : theme.other?.defaultInputProps?.descriptionColor
        ? theme.fn.themeColor(theme.other.defaultInputProps.descriptionColor)
        : theme.colorScheme === 'dark'
          ? theme.colors.dark[2]
          : theme.colors.gray[7],
  },
  error: {
    paddingLeft: 16,
    paddingTop: 2,
    fontSize: otherFontSize
      ? getSize({
        size: otherFontSize,
        sizes: theme.fontSizes,
      })
      : theme.other?.defaultInputProps?.otherFontSize
        ? getSize({
          size: theme.other.defaultInputProps.otherFontSize,
          sizes: theme.fontSizes,
        })
        : `calc(${getSize({ size, sizes: theme.fontSizes })} - 0.25rem)`,
    fontWeight:
      otherFontWeight ||
      theme.other?.defaultInputProps?.otherFontWeight ||
      400,
    color: errorColor
      ? theme.fn.themeColor(errorColor)
      : theme.other?.defaultInputProps?.errorColor
        ? theme.fn.themeColor(theme.other.defaultInputProps.errorColor)
        : theme.fn.variant({ variant: 'filled', color: 'red' }).background,
  },
}));