import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { AccountGroupInfo } from '../../utils/interfaces'
import { delayEmulation } from '../config'

export const fetchAccountGroupInfoList = async (accountGroupInfoList: AccountGroupInfo[]) => {
  return new Promise<AccountGroupInfo[]>((resolve, reject) => {
    delayEmulation()
      .then(() => resolve(accountGroupInfoList ?? []))
      .catch(() => reject())
  })
}

export default function useFetchAccountGroupInfoList() {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['account-groups', session.accountGroupInfoList],
    queryFn: () => fetchAccountGroupInfoList(session.accountGroupInfoList),
  })
}
