import React from 'react'
import { Helmet } from 'react-helmet'
import { CSSGrid } from '../common/CSSGrid'
import AppFooter from './AppFooter'
import OnboardingAppNavbar from './OnboardingAppNavbar'

export const OnboardingAppWrapper: React.FC<{ title: string; forSignup?: boolean }> = ({
  children,
  title,
  forSignup = false,
}) => {
  const navbarHeight = forSignup ? '5rem' : '3.25rem'
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <OnboardingAppNavbar forSignup={forSignup} />
      <CSSGrid mih={`calc(100vh - ${navbarHeight})`} templateRows='1fr auto'>
        {/* make scrollbar appear only for main content & footer? */}
        {/* <CSSGrid h='calc(100vh - 8rem)' templateRows='1fr auto' sx={{ overflow: 'auto' }}> */}
        <CSSGrid sx={{ zIndex: 1 }}>{children}</CSSGrid>
        <AppFooter />
        {/* </CSSGrid> */}
      </CSSGrid>
    </>
  )
}

export default OnboardingAppWrapper
