import { Box, BoxProps, Flex, SelectProps, Text } from '@mantine/core'
import React from 'react'
import { AccountGroup, Theme } from '../../utils/interfaces'
import { ThemedBackgroundSmallImage } from './ThemedBackgroundImage'

type SelectItemProps = BoxProps & {
  value: string
  label: string
  theme: Theme
  selected: boolean
  variant?: string
}

const SelectItem: React.FC<SelectItemProps> = React.forwardRef(function SelectItem(
  { value, label, theme, ...others },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  delete others.variant
  return (
    <Box key={value} ref={ref} {...others}>
      <Flex align='center' gap='sm'>
        <Flex pos='relative' align='center' justify='center'>
          <ThemedBackgroundSmallImage h='md' w='md' theme={theme || 'blue'} sx={{ borderRadius: '50%' }} />
        </Flex>
        <Text size='sm'>{label}</Text>
      </Flex>
    </Box>
  )
})

export const getSelectPropsForAccountGroups = (data: AccountGroup[], currentVal: string | null) =>
  ({
    variant: 'filled',
    size: 'sm',
    filter: (value: string, item: SelectItemProps) => item.label.toLowerCase().includes(value.toLowerCase().trim()),
    w: '100%',
    data: [
      ...data.map((accountGroup) => ({
        value: accountGroup.iban,
        label: accountGroup.alias,
        theme: accountGroup.theme as never,
      })),
    ],
    styles: { input: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' } },
    itemComponent: SelectItem,
    dropdownPosition: 'bottom',
    icon:
      currentVal !== undefined && currentVal !== null ? (
        <Flex pos='relative' align='center' justify='center'>
          <ThemedBackgroundSmallImage
            h='md'
            w='md'
            theme={data.find((a) => a.iban === currentVal)?.theme || 'blue'}
            sx={{ borderRadius: '50%' }}
          />
        </Flex>
      ) : undefined,
  } as SelectProps)
