import { Box, Button, Loader, UnstyledButton } from '@mantine/core'
import dayjs from 'dayjs'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchAccountGroupsByIBAN from '../../../api/queries/useFetchAccountGroupsByIBAN'
import { IconBeneficiary, IconStatement } from '../../../components/icons'
import AppHeader from '../../../components/layout/AppHeader'
import AppWrapper from '../../../components/layout/AppWrapper'
import { Container } from '../../../components/layout/Container'
import { LogoCompanyPlaceholder } from '../../../components/logos'
import { colorAliases } from '../../../theme/getMantineTheme'
import DashboardGroup from './DashboardGroup'

const Header = () => {
  const navigate = useNavigate()
  return (
    <AppHeader
      title='Vertigo Limited'
      description={
        <Box>
          Last login was on{' '}
          <Box component='span' display={{ base: 'none', desktop: 'inline' }}>
            {dayjs().subtract(1, 'day').add(2, 'hour').format('DD MMMM YYYY, HH:mm (Z)')}
          </Box>
          <Box component='span' display={{ desktop: 'none' }}>
            {dayjs().subtract(1, 'day').add(2, 'hour').format('DD-MMM-YY, HH:mm (Z)')}
          </Box>
        </Box>
      }
      icon={
        <UnstyledButton onClick={() => navigate('/beneficiary-centre')}>
          <LogoCompanyPlaceholder style={{ width: '1.5em', height: '1.5em' }} />
        </UnstyledButton>}
      buttons={[
        <Button
          size='big'
          variant='leikurLight'
          color={colorAliases.textLink}
          key='BeneficiaryCentre'
          leftIcon={<IconBeneficiary />}
          onClick={() => navigate('/beneficiary-centre/')}
        >
          Beneficiary <br />
          Centre
        </Button>,

        <Button
          key='StatementCentre'
          size='big'
          variant='leikurLight'
          color={colorAliases.textLink}
          leftIcon={<IconStatement />}
          onClick={() => navigate('/statement-centre/')}
        >
          Statement
          <br />
          Centre
        </Button>,
      ]}
    />
  )
}

const Dashboard = () => {
  const [overlay, _setOverlay] = useState<ReactNode>()
  const { isLoading, data: accountGroupsByIBAN } = useFetchAccountGroupsByIBAN()

  const setOverlay = (val: ReactNode) => {
    _setOverlay(val)
    const body = document.getElementById('body')
    if (body) body.style.overflow = val ? 'hidden' : 'auto'
  }

  if (isLoading) {
    return (
      <AppWrapper title='Leikur - Dashboard' appHeader={<Header />}>
        <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </Container>
      </AppWrapper>
    )
  }

  return (
    <>
      <AppWrapper title='Leikur - Dashboard' appHeader={<Header />}>
        <Container h='100%' pt='2.75rem' sx={{ isolation: 'isolate' }}>
          {accountGroupsByIBAN &&
            Object.values(accountGroupsByIBAN).map((accountGroup) => {
              return <DashboardGroup key={accountGroup.iban} group={accountGroup} setOverlay={setOverlay} />
            })}
        </Container>
      </AppWrapper>
      {overlay}
    </>
  )
}

export default Dashboard
