import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps as MantinePasswordInputProps,
} from '@mantine/core';
import React, { forwardRef } from 'react';

import useStyles from '../Input.styles';
import { SharedInputProps } from '../SharedInputProps';
import usePasswordStyles from './PasswordInput.styles';

export interface PasswordInputProps
  extends MantinePasswordInputProps,
    SharedInputProps {}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      classNames,
      size = 'md',
      variant = 'default',
      inputWrapperOrder = ['label', 'input', 'description', 'error'],
      wrapperProps,
      ...props
    }: PasswordInputProps,
    ref
  ) => {
    const passwordStyles = usePasswordStyles(
      {
        ...props,
        size,
      },
      { classNames: classNames, name: 'custompassword' }
    );
    const { classes } = useStyles(
      {
        ...props,
        wrapperProps,
        variant,
        size,
      },
      { classNames: classNames, name: 'custom-input' }
    );

    return (
      <MantinePasswordInput
        ref={ref}
        variant={variant}
        size={size}
        inputWrapperOrder={inputWrapperOrder}
        wrapperProps={{
          ...wrapperProps,
        }}
        classNames={{ ...classNames, ...passwordStyles.classes, ...classes }}
        {...props}
      />
    );
  }
);
