import { createStyles, getSize, rem } from '@mantine/core';
import { isDark } from '../../utils/theme';
import { CheckboxProps } from './Checkbox';

const sizes = {
  xs: rem(16),
  sm: rem(20),
  md: rem(24),
  lg: rem(30),
  xl: rem(36),
};

const iconSizes = {
  xs: rem(8),
  sm: rem(10),
  md: rem(14),
  lg: rem(16),
  xl: rem(20),
};

export default createStyles((theme, params: CheckboxProps) => {
  const _boxSize = getSize({
    size: params.boxSize || params.size || 'md',
    sizes,
  });
  const _size = getSize({ size: params.size || 'md', sizes });
  return {
    icon: {
      width: getSize({
        size: params.boxSize || params.size || 'md',
        sizes: iconSizes,
      }),
      // color:
      //   params.variant === 'outline'
      //     ? theme.fn.themeColor(params.color as string)
      //     : undefined,
    },
    inner: {
      width: _boxSize,
      height: _boxSize,
      // required to give enough specificity to override default color
      ['> svg.mantine-Checkbox-icon.mantine-custom-icon']: {
        color: params.variant === 'outline'
          ? theme.fn.themeColor(params.color as string)
          : undefined,
      }
    },
    input: {
      cursor: 'pointer',
      width: _boxSize,
      height: _boxSize,
      border: `1pt solid ${isDark(theme) ? theme.colors.dark[0] : theme.colors.gray[5]}`,
      backgroundColor: params.variant === 'outline' ? 'transparent' : undefined,

      ':checked': {
        backgroundColor: params.variant === 'outline' ? 'transparent' : undefined,
      },
    },
    label: {
      cursor: 'pointer',
      paddingLeft: getSize({
        size: params.spacing === undefined ? 'sm' : params.spacing,
        sizes: theme.spacing,
      }) as string,
      height: `max(${_boxSize}, ${_size})`,
      display: 'flex',
      alignItems: 'center',
    },
  };
});
