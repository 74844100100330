import { ActionIcon, Box, CopyButton, Divider, Flex, Paper, Title, Tooltip } from '@mantine/core'
import React, { ReactNode, useRef } from 'react'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconCopy, IconEditModifyPen, IconFundsTransactMenu } from '../../../../components/icons'
import { openModalForAccountGroupAliasUpdate } from '../../../../components/modals/AccountGroupAliasUpdate'
import { colorAliases } from '../../../../theme/getMantineTheme'
import formatBIC from '../../../../utils/formatBIC'
import formatIBAN from '../../../../utils/formatIBAN'
import { AccountGroup } from '../../../../utils/interfaces'
import AccountsBar from './AccountsBar'
import OptionButtons from './OptionButtons'
import OverlayedMenu from './OverlayedMenu'

const OVERLAY_OFFSET = 178
const CONTAINER_HEIGHT = '12.75rem'
const CONTAINER_MARGIN_BOTTOM = '6.5rem'
const ACCOUNTS_HEIGHT = '7.5rem'
const HALF_ACCOUNTS_HEIGHT = '3.75rem'

const DashboardGroup: React.FC<{ group: AccountGroup; setOverlay: (overlay: ReactNode) => void }> = ({ group, setOverlay }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const showOverlay = () => {
    if (wrapperRef?.current) {
      wrapperRef.current.scrollIntoView(true)
      setTimeout(() => {
        const top = (wrapperRef?.current?.getBoundingClientRect().top ?? 0) + OVERLAY_OFFSET
        setOverlay(<OverlayedMenu close={() => setOverlay(undefined)} group={group} top={top} />)
      }, 300)
    } else {
      setTimeout(() => setOverlay(<OverlayedMenu close={() => setOverlay(undefined)} group={group} top={OVERLAY_OFFSET} />), 300)
    }
  }

  return (
    <Box ref={wrapperRef} mt={OVERLAY_OFFSET * -1} pt={OVERLAY_OFFSET}>
      <Paper mb={CONTAINER_MARGIN_BOTTOM}>
        <Flex pos='relative' pt='lg' pl={{ desktop: '2xl' }} justify='space-between' h={CONTAINER_HEIGHT}>
          <Box mx={{ base: 'auto', desktop: 'initial' }}>
            <Box w={{ base: 350, desktop: 550 }} mb={12}>
              <Flex align='center' justify='space-between'>
                <Title order={2} size='h2' my='0'>
                  {group.alias}
                </Title>
                <ActionIcon
                  variant='transparent'
                  size='sm'
                  color={colorAliases.borderHighlight}
                  display={{ base: 'none', desktop: 'block' }}
                  onClick={openModalForAccountGroupAliasUpdate({
                    iban: group.iban,
                    currentAlias: group.alias,
                  })}
                >
                  <IconEditModifyPen />
                </ActionIcon>
                <ActionIcon
                  variant='transparent'
                  size='sm'
                  color={colorAliases.borderHighlight}
                  display={{ base: 'block', desktop: 'none' }}
                  onClick={showOverlay}
                >
                  <IconFundsTransactMenu />
                </ActionIcon>
              </Flex>
            </Box>
            <Divider size='sm' color={colorAliases.borderSubtle} mb='sm' />
            <CSSGrid w={350} alignItems='end' rowGap={12} columnGap={12} templateColumns='1fr auto' px={{ base: '3xs', desktop: '0' }}>
              <Flex align='center' columnGap={12}>
                <Box fw={600} fz='lg' w='3rem'>
                  IBAN
                </Box>
                <Box sx={{ maxWidth: '22ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatIBAN(group.iban)}</Box>
              </Flex>
              <CopyButton value={group.iban}>
                {({ copied, copy }) => (
                  <Tooltip color='secondary' label={'Copied'} withArrow position='right' opened={copied}>
                    <ActionIcon variant='transparent' size='md' color={copied ? 'secondary' : colorAliases.borderHighlight} onClick={copy}>
                      <IconCopy />
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
              <Flex align='center' columnGap={12}>
                <Box fw={600} fz='lg' w='3rem'>
                  BIC
                </Box>
                <Box>{formatBIC(group.bic)}</Box>
              </Flex>
              <CopyButton value={group.bic}>
                {({ copied, copy }) => (
                  <Tooltip color='secondary' label={'Copied'} withArrow position='right' opened={copied}>
                    <ActionIcon variant='transparent' size='md' color={copied ? 'secondary' : colorAliases.borderHighlight} onClick={copy}>
                      <IconCopy />
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </CSSGrid>
          </Box>
          {/* TODO: FIX SPACING ON TABLET */}
          <Box display={{ base: 'none', desktop: 'block' }} pt='md' pr='md'>
            <OptionButtons group={group} />
          </Box>
        </Flex>
        <Box h={82} bg={colorAliases.surfaceLightgray} pos='relative' sx={{ borderBottomLeftRadius: 'inherit', borderBottomRightRadius: 'inherit' }}>
          <Box pos='absolute' right='0' left='0' bottom={'-' + HALF_ACCOUNTS_HEIGHT} sx={{ zIndex: 1 }}>
            {/* <Box pos='absolute' ml='md' top={86}> */}
            {/* top - magic numbers: 86 = 70 (HALF_ACCOUNTS_HEIGHT) + 16 (half of IconArrangeIconsController height) */}
            {/* <ActionIcon variant='transparent' size='lg' color='muted'>
              <IconArrangeIconsController />
            </ActionIcon>
          </Box> */}
            {group.accounts && <AccountsBar group={group} height={ACCOUNTS_HEIGHT} />}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default DashboardGroup
