import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Beneficiary } from '../../utils/interfaces'
import { delayEmulation } from '../config'

const fetchBeneficiary = async (beneficiaries: Beneficiary[], id: string) => {
  await delayEmulation()
  return new Promise<Beneficiary | null>((resolve, reject) => {
    delayEmulation()
      .then(() => resolve(beneficiaries?.find((v) => v.id === id) ?? null))
      .catch(() => reject())
  })
}

export default function useFetchBeneficiary(id = '') {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['beneficiaries', session.beneficiaries, id],
    queryFn: () => fetchBeneficiary(session.beneficiaries, id),
  })
}
