import { MantineProvider, useMantineTheme } from '@mantine/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import { render } from 'react-dom'
import App from './App'
import { SessionProvider } from './contexts/SessionContext'
import MantineGlobal from './theme/MantineGlobal'
import getMantineTheme from './theme/getMantineTheme'

// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(advancedFormat)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
// dayjs.extend(isSameOrBefore)

const queryClient = new QueryClient()

const AppStyleWrapper = () => {
  const mantineTheme = getMantineTheme(useMantineTheme())

  return (
    <MantineProvider theme={mantineTheme} withNormalizeCSS>
      <SessionProvider>
        <QueryClientProvider client={queryClient}>
          <MantineGlobal />
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SessionProvider>
    </MantineProvider>
  )
}

// Mount the app

render(<AppStyleWrapper />, document.getElementById('root'))
