import { Chip } from '@innostream/stream-ui'
import { Button, Box, Flex, Paper, Select, Space, Text, TextInput, useMantineTheme, } from '@mantine/core'
import { BeneficiaryFilter, FILTER_ENTITY_TYPE } from '.'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { getSelectPropsForCurrency } from '../../../components/common/currencyDropdownHelper'
import { IconCloseCross, IconSearch } from '../../../components/icons'
import useBeneficiaryCurrencyList from '../../../utils/useBeneficiaryCurrencyOptions'
import { getSelectPropsForCountry } from '../../../components/inputs/helper'
import { colorAliases } from '../../../theme/getMantineTheme'

const BeneficiaryCentreSearchIndividual: React.FC<
  {
    filters: BeneficiaryFilter
    setFilters: (val: Partial<BeneficiaryFilter> | ((current: BeneficiaryFilter) => Partial<BeneficiaryFilter>)) => void
  } & React.ComponentPropsWithoutRef<'div'>
> = ({ filters, setFilters }) => {
  const beneficiaryCurrencyList = useBeneficiaryCurrencyList()
  const theme = useMantineTheme()

  // If all Chips are the same, we can move this to the theme. Doing this for now.
  const chipStyles = {
    label: {
      fontSize: '0.8rem',
      paddingTop: '1.3rem',
      paddingBottom: '1.3rem',
      height: '2.5rem',
      backgroundColor: 'transparent',
      width: '100%',
      border: `1px solid ${theme.fn.themeColor('transparentBlack.3')}`,
      '&[data-checked]:not([data-disabled])': {
        backgroundColor: `${theme.fn.themeColor('dark.0')}`,
        ':hover': {
          backgroundColor: theme.fn.lighten(theme.fn.themeColor('dark.0'), 0.1),
        },
        ':active': {
          backgroundColor: theme.fn.darken(theme.fn.themeColor('dark.0'), 0.1),
        },
      },
      '&[data-variant="filled"]': {
        backgroundColor: `${theme.fn.themeColor('transparentBlack.3')}`,
        ':hover': {
          backgroundColor: theme.fn.lighten(theme.fn.themeColor('dark.0'), 0.1),
        },
        ':active': {
          backgroundColor: theme.fn.lighten(theme.fn.themeColor('dark.0'), 0.1),
        },
      },
    },
  }

  return (
    <Paper p='lg' w={{ desktop: '390px' }} pos='relative' mb={{ desktop: '3xl' }} bg={colorAliases.surfaceLightgray}>
      <Box pos='relative'>
        <Text align='center' size='xl'>
          Search individual beneficiaries
        </Text>
        <Space h='lg' />
        <CSSGrid gap='lg'>
          <TextInput
            placeholder='Search by name, alias or reference...'
            rightSection={<Box component={IconSearch} h='1em' c='muted' />}
            value={filters.nameOrAliasOrReference}
            styles={{ input: { height: '2.6rem' } }}
            onChange={(n) => setFilters({ nameOrAliasOrReference: n.target.value })}
          />
          <Chip.Group multiple={false} value={filters.entity} onChange={(n: FILTER_ENTITY_TYPE) => setFilters({ entity: n })}>
            <Flex align='center' gap='sm' direction={{ base: 'column', desktop: 'row' }}>
              <Chip
                styles={chipStyles}
                radius='xs'
                ta='center'
                size='sm'
                style={{ flex: 1 }}
                value={FILTER_ENTITY_TYPE.All}
              >
                Show all
              </Chip>
              <Chip
                styles={chipStyles}
                radius='xs'
                size='sm'
                ta='center'
                style={{ flex: 1 }}
                value={FILTER_ENTITY_TYPE.Individual}
              >
                Individual
              </Chip>
              <Chip
                styles={chipStyles}
                radius='xs'
                size='sm'
                ta='center'
                style={{ flex: 1 }}
                value={FILTER_ENTITY_TYPE.Corporate}
              >
                Companies
              </Chip>
            </Flex>
          </Chip.Group>


          <Select
            searchable
            clearable
            styles={{ input: { height: '2.6rem', } }}
            placeholder='Filter by country'
            {...getSelectPropsForCountry(filters.country)}
            onChange={(v) => setFilters({ country: v })}
            value={filters.country}
          />
          <Select
            icon={<IconCloseCross style={{ height: '1em' }} />}
            searchable
            clearable
            styles={{ input: { height: '2.6rem', } }}
            placeholder='Filter by currency'
            {...getSelectPropsForCurrency(beneficiaryCurrencyList, filters.currency)}
            variant='default'
            onChange={(v) => setFilters({ currency: v })}
            value={filters.currency}
          />

          <Flex justify='center'>
            <Button
              leftIcon={<IconCloseCross style={{ height: '1em' }} />}
              onClick={() =>
                setFilters({
                  nameOrAliasOrReference: '',
                  entity: FILTER_ENTITY_TYPE.All,
                  country: null,
                  currency: null,
                  status: 'active',
                })
              }
              size='xs'
              bg='secondary'
              variant='filled'
              c='white'
              w='8rem'
              h='2.2rem'
            >
              Clear filters
            </Button>
          </Flex>
        </CSSGrid>
      </Box>
    </Paper >
  )
}

export default BeneficiaryCentreSearchIndividual
