import {
  createStyles,
  CSSObject,
  getStylesRef,
  MantineSize,
  MantineTheme
} from '@mantine/core';
import { isDark, setTransparency } from '../../../utils/theme';
import { ButtonProps } from './Button';

/** Returns the rgba value for the various colors. Default for textColor is undefined */
const getColors = (theme: MantineTheme, params: ButtonProps) => ({
  color: theme.fn.themeColor(params.color as string),
  textColor: params.textColor !== undefined ? theme.fn.themeColor(params.textColor as string) : undefined,
  leftIconColor: theme.fn.themeColor(params.leftIconColor || params.textColor || params.color as string),
  rightIconColor: theme.fn.themeColor(params.rightIconColor || params.textColor || params.color as string),
  borderColor: theme.fn.themeColor(params.borderColor || params.color as string),
});

const getVariantStyles = (
  theme: MantineTheme,
  params: ButtonProps,
  { color, borderColor, textColor, leftIconColor, rightIconColor }: any
) => {
  if (params.variant === 'subtle') {
    return {
      ':hover': {
        boxShadow: `0 0 0.25rem ${borderColor}`,
        backgroundColor: setTransparency(theme, params.color, isDark(theme) ? 0.45 : 0.65, isDark(theme) ? 7 : 1),
      },
      ':active': {
        boxShadow: `0 0 0.375rem transparent`,
        backgroundColor: setTransparency(theme, params.color, isDark(theme) ? 0.35 : 0.65, isDark(theme) ? 9 : 2),
        [`.${getStylesRef('label')}`]: {
          color: theme.fn.darken(theme.fn.themeColor(params.textColor || params.color as string, params.color === 'dark' ? isDark(theme) ? 0 : 9 : undefined, true, params.color !== 'dark'), 0.1),
        },
      },
    };
  }

  if (params.variant === 'light') {
    return {
      ':hover': {
        boxShadow: `0 0 0.25rem ${borderColor}`,
        backgroundColor: setTransparency(theme, params.color, isDark(theme) ? 0.35 : 0.65, isDark(theme) ? 7 : 1)
      },
      ':active': {
        boxShadow: `0 0 0.375rem transparent`,
        backgroundColor: setTransparency(theme, params.color, isDark(theme) ? 0.35 : 0.65, isDark(theme) ? 9 : 2),
        [`.${getStylesRef('label')}`]: {
          color: theme.fn.darken(theme.fn.themeColor(params.textColor || params.color as string, params.color === 'dark' ? isDark(theme) ? 0 : 9 : isDark(theme) ? 4 : 8, true, false), 0.1)
        },
      },
    };
  }

  if (params.variant === 'filled') {
    return {
      ':hover': {
        boxShadow: `0 0 0.375rem ${borderColor}`,
        backgroundColor: color,
      },
      ':active': {
        boxShadow: `0 0 0.375rem transparent`,
        backgroundColor: theme.fn.darken(color, 0.1),
        [`.${getStylesRef('label')}`]: {
          color: theme.fn.darken(theme.fn.themeColor(params.textColor || params.color as string, params.textColor !== 'dark' && params.color !== 'dark' ? 0 : 9, true,
            false), 0.1)
        },
      },
    };
  }

  if (params.variant === 'outline' || params.variant === 'borderless') {
    return {
      color: color,
      aspectRatio: params.variant === 'borderless' && params.children === undefined ? '1 / 1' : undefined,
      borderWidth: params.variant === 'borderless' ? '1pt' : '2pt',
      borderColor: params.variant === 'borderless' ? 'transparent' : `${borderColor}${params.loading ? '80' : ''}`,
      ':hover': {
        borderColor: `${color}${params.loading ? '80' : ''}`,
        borderWidth: params.variant === 'borderless' ? '1pt' : '2pt',
        boxShadow: `0 0 0.375rem ${color}`,
        backgroundColor: 'transparent',

        [`.${getStylesRef('label')}`]: {
          color: theme.fn.lighten(textColor || color, 0.1),
        },
        [`.${getStylesRef('leftIcon')}`]: {
          color: theme.fn.lighten(leftIconColor, 0.1),
        },
        [`.${getStylesRef('rightIcon')}`]: {
          color: theme.fn.lighten(rightIconColor, 0.1),
        },
      },
      ':active': {
        boxShadow: `0 0 0.375rem transparent`,
        borderColor: theme.fn.darken(color, 0.1),
        [`.${getStylesRef('label')}`]: {
          color: theme.fn.darken(textColor || color, 0.1),
        },
        [`.${getStylesRef('leftIcon')}`]: {
          color: theme.fn.darken(leftIconColor, 0.1),
        },
        [`.${getStylesRef('rightIcon')}`]: {
          color: theme.fn.darken(rightIconColor, 0.1),
        },
      },
    };
  }

  return {};
};

const paddingSizes: any = {
  'xs': { paddingLeft: 14, paddingRight: 14 },
  'sm': { paddingLeft: 18, paddingRight: 18 },
  'md': { paddingLeft: 22, paddingRight: 22 },
  'lg': { paddingLeft: 26, paddingRight: 26 },
  'xl': { paddingLeft: 32, paddingRight: 32 },
  'compact-xs': { paddingLeft: 7, paddingRight: 7 },
  'compact-sm': { paddingLeft: 8, paddingRight: 8 },
  'compact-md': { paddingLeft: 10, paddingRight: 10 },
  'compact-lg': { paddingLeft: 12, paddingRight: 12 },
  'compact-xl': { paddingLeft: 14, paddingRight: 14 },
};

const getPaddingStyles = (compact: boolean = false, size: MantineSize = 'sm', hasChildren: boolean = true): CSSObject =>
  hasChildren ? compact ? paddingSizes[`compact-${size}`] : paddingSizes[size] : { paddingLeft: 0, paddingRight: 0 };

export default createStyles((theme, params: ButtonProps) => {
  const colors = getColors(theme, params);
  return {
    root: {
      '*,*::before': {
        boxSizing: 'border-box',
      },
      transition: 'box-shadow 0.1s ease 0s',
      ...getVariantStyles(theme, params, colors),
      '&:disabled, &[data-disabled]': {
        boxShadow: `0 0 0.375rem transparent`,
        color: params.variant === 'outline' ? theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5] : theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[5],
        borderColor: params.variant === 'outline' ? theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5] : undefined,
        backgroundColor: params.variant === 'outline' ? 'transparent' : theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
        [`.${getStylesRef('label')}`]: {
          color: params.variant === 'outline' ? theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5] : theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[5],
        },
        [`.${getStylesRef('leftIcon')}, .${getStylesRef('rightIcon')}`]: {
          color: params.variant === 'outline' ? theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5] : theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[5],
        },
      },
      ...getPaddingStyles(
        params.compact,
        params.size,
        params.children !== undefined
      ),
    },
    label: {
      ref: getStylesRef('label'),
      padding: params.textPadding,
      letterSpacing: params.uppercase ? '2px' : '0px',
      color: colors.textColor,
    },

    leftIcon: {
      ref: getStylesRef('leftIcon'),
      marginRight: params.children ? undefined : 0,
      color: colors.leftIconColor,
      svg: {
        fill: 'currentcolor',
      },
    },
    rightIcon: {
      ref: getStylesRef('rightIcon'),
      marginLeft: params.children ? undefined : 0,
      color: colors.rightIconColor,
      svg: {
        fill: 'currentcolor',
      },
    },
  };
});
