import { mkConfig, generateCsv, download, ConfigOptions } from 'export-to-csv'


export function generateCSV<T extends {
  [k: string]: unknown;
  [k: number]: unknown;
}>(data: T[]) {
  const csvConfig = mkConfig({ useKeysAsHeaders: true })
  return generateCsv(csvConfig)(data)
}

export function downloadCSV<T extends {
  [k: string]: unknown;
  [k: number]: unknown;
}>(data: T[], configOptions?: ConfigOptions) {
  const csvConfig = mkConfig({ useKeysAsHeaders: true, ...configOptions })
  return download(csvConfig)(generateCsv({ ...csvConfig })(data))
}