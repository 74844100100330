import { Select as MantineSelect, SelectProps as MantineSelectProps } from '@mantine/core';
import React, { forwardRef } from 'react';
import { SharedInputProps } from '../../Inputs';

import useStyles from './Select.styles';

export interface SelectProps extends MantineSelectProps, SharedInputProps {}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      classNames,
      size = 'md',
      variant = 'default',
      inputWrapperOrder = ['label', 'input', 'description', 'error'],
      searchable = true,
      borderColor,
      inputColor,
      labelColor,
      labelFontSize,
      labelFontWeight,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
      ...props
    }: SelectProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        variant,
        size,
        borderColor,
        inputColor,
        labelColor,
        labelFontSize,
        labelFontWeight,
        errorColor,
        descriptionColor,
        otherFontSize,
        otherFontWeight,
      },
      { classNames, name: 'custom' }
    );

    return (
      <MantineSelect
        ref={ref}
        searchable={searchable}
        variant={variant}
        size={size}
        inputWrapperOrder={inputWrapperOrder}
        classNames={classes}
        {...props}
      />
    );
  }
);
