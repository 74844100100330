import { CSSObject, Global } from '@mantine/core'

const ObjectSansRegular = new URL('../assets/fonts/PPObjectSans/PPObjectSans-Regular.ttf', import.meta.url)
const ObjectSansHeavy = new URL('../assets/fonts/PPObjectSans/PPObjectSans-Heavy.ttf', import.meta.url)
const RobotoRegular = new URL('../assets/fonts/Roboto/Roboto-Regular.ttf', import.meta.url)
// const WorkSansRegular = new URL('../assets/fonts/Work_Sans/static/WorkSans-Regular.ttf', import.meta.url)
// const WorkSansMedium = new URL('../assets/fonts/Work_Sans/static/WorkSans-Medium.ttf', import.meta.url)

const MantineGlobal = () => (
  <Global
    styles={(theme) => [
      {
        '@font-face': {
          fontFamily: 'Object Sans',
          src: `local('Object Sans'), url('${ObjectSansRegular.toString()}') format('truetype')`,
          fontWeight: 400,
          fontStyle: 'normal',
          fontDisplay: 'swap',
        },
      },
      {
        '@font-face': {
          fontFamily: 'Object Sans',
          src: `local('Object Sans Heavy'), url('${ObjectSansHeavy.toString()}') format('truetype')`,
          fontWeight: 900,
          fontStyle: 'normal',
          fontDisplay: 'swap',
        },
      },
      {
        '@font-face': {
          fontFamily: 'Roboto',
          src: `local('Roboto'), url('${RobotoRegular.toString()}') format('truetype')`,
          fontWeight: 400,
          fontStyle: 'normal',
          fontDisplay: 'swap',
        },
      },
      // {
      //   '@font-face': {
      //     fontFamily: 'Work Sans',
      //     src: `local('Work Sans'), url('${WorkSansRegular.toString()}') format('truetype')`,
      //     fontWeight: 400,
      //     fontStyle: 'normal',
      //     fontDisplay: 'swap',
      //   },
      // },
      // {
      //   '@font-face': {
      //     fontFamily: 'Work Sans',
      //     src: `local('Work Sans Medium'), url('${WorkSansMedium.toString()}') format('truetype')`,
      //     fontWeight: 500,
      //     fontStyle: 'normal',
      //     fontDisplay: 'swap',
      //   },
      // },
      {
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
      },
      {
        '#root': {
          minHeight: '100vh',
        },
      },
      {
        'body, input, select, textarea, button': {
          ...(theme.fn.fontStyles() as CSSObject),
          fontOpticalSizing: 'auto',
        },
      },
      {
        'html,body': {
          backgroundColor: theme.colors.light[4],
          color: theme.colors.dark[4],
          margin: 0,
          scrollBehavior: 'smooth',
        },
      },
    ]}
  />
)

export default MantineGlobal
