import { Box, Flex, Sx, useMantineTheme, Text } from '@mantine/core'
import dayjs from 'dayjs'
import { Transaction } from '../../../../utils/interfaces'
import TransactionItem from './TransactionItem'
import { colorAliases } from '../../../../theme/getMantineTheme'

const formatDate = (dateString: string) => {
  return dayjs(dateString).format('dddd Do MMMM, YYYY')
}

const getTransactionsByDate = (transactions: Transaction[]) => {
  const groupedTransactions: { [key: string]: Transaction[] } = {}
  transactions.sort((f, s) => (dayjs(f.created_at).isBefore(dayjs(s.created_at)) ? 1 : -1))

  for (const tx of transactions) {
    groupedTransactions[formatDate(tx.created_at)] = [...(groupedTransactions[formatDate(tx.created_at)] || []), tx]
  }
  return groupedTransactions
}

const Timeline: React.FC<{ transactions: Transaction[]; groupThemeAccent: string }> = ({
  transactions,
  groupThemeAccent,
}) => {
  const mantineTheme = useMantineTheme()

  const displayedTransactions = getTransactionsByDate(transactions)

  const timelinePointStyles: Sx = {
    '::before': {
      content: '""',
      width: 16,
      height: 16,
      backgroundColor: '#fff',
      border: `1pt solid ${mantineTheme.fn.themeColor('secondary')}`,
      borderRadius: '50%',
      position: 'absolute',
      left: 'calc(-0.625rem + 0.5pt)',
      top: 0
    },
  }
  return (
    <Box pl='xs'>
      <Box sx={{ borderLeft: `1pt solid ${mantineTheme.fn.themeColor('secondary')}` }}>
        {Object.keys(displayedTransactions).map((date, i) => (
          <Box key={`${date}${i}}`}>
            <Flex
              mb='2xs'
              pl='md'
              align='center'
              pos='relative'
              c={colorAliases.textInvert}
              sx={timelinePointStyles}
            >
              <Text fz='sm' fw='200'>{date}</Text>
            </Flex>
            <Box pl='md' mb='2xs'>
              {displayedTransactions[date].map((transaction, i) => (
                <TransactionItem
                  key={i}
                  groupThemeAccent={groupThemeAccent}
                  transaction={transaction}
                // isLastInGroup={i === displayedTransactions[date].length - 1}
                >
                  {transaction.id}
                </TransactionItem>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Timeline
