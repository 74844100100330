import { Navigate, RouteObject } from 'react-router-dom'
// import Dashboard from './dashboard/Dashboard'
// import BasicDetails from './step1/BasicDetails'
// import ContactDetails from './step2/ContactDetails'
// import AdvancedDetails from './step3/AdvancedDetails'
// import RepresentativeDetails from './step4/RepresentativeDetails'
// import FinancialDetails from './step5/FinancialDetails'
// import TaxRegulations from './step6/TaxRegulations'
// import BusinessActivity from './step7/BusinessActivity'
// import DataProtection from './step8/DataProtection'
// import Documents from './step9/Documents'
// import IDVerification from './step10/IDVerification'
// import ReviewApplication from './step11/ReviewApplication'

// Only the authenticated routes are here. I.e. routes for all dashboard + all onboarding forms apart from the first one.
// Route for first form (SignUp) is defined in main router directly
// Depending on the size of this file, we can choose to include there routes directly in the main router later
const onboardingRoutes: RouteObject[] = [
  { index: true, element: <Navigate to='/onboarding/dashboard' /> },
  { path: 'dashboard', lazy: () => import('./dashboard/Dashboard') },
  { path: 'step/1', lazy: () => import('./step1/BasicDetails') },
  { path: 'step/2', lazy: () => import('./step2/ContactDetails') },
  { path: 'step/3', lazy: () => import('./step3/AdvancedDetails') },
  { path: 'step/4', lazy: () => import('./step4/RepresentativeDetails') },
  { path: 'step/5', lazy: () => import('./step5/FinancialDetails') },
  { path: 'step/6', lazy: () => import('./step6/TaxRegulations') },
  { path: 'step/7', lazy: () => import('./step7/BusinessActivity') },
  { path: 'step/8', lazy: () => import('./step8/DataProtection') },
  { path: 'step/9', lazy: () => import('./step9/Documents') },
  { path: 'step/10', lazy: () => import('./step10/IDVerification') },
  { path: 'step/11', lazy: () => import('./step11/ReviewApplication') },

  // (
  // <React.Fragment>
  //   <Route index element={<Navigate to='/onboarding/dashboard' />} />
  //   <Route path='/onboarding/dashboard' element={<Dashboard />} />
  //   <Route path='/onboarding/step/1' element={<BasicDetails />} />
  //   <Route path='/onboarding/step/2' element={<ContactDetails />} />
  //   <Route path='/onboarding/step/3' element={<AdvancedDetails />} />
  //   <Route path='/onboarding/step/4' element={<RepresentativeDetails />} />
  //   <Route path='/onboarding/step/5' element={<FinancialDetails />} />
  //   <Route path='/onboarding/step/6' element={<TaxRegulations />} />
  //   <Route path='/onboarding/step/7' element={<BusinessActivity />} />
  //   <Route path='/onboarding/step/8' element={<DataProtection />} />
  //   <Route path='/onboarding/step/9' element={<Documents />} />
  //   <Route path='/onboarding/step/10' element={<IDVerification />} />
  //   <Route path='/onboarding/step/11' element={<ReviewApplication />} />
  // </React.Fragment>
]

export default onboardingRoutes
