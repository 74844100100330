import { ModalProps } from '@mantine/core'
import ConfirmModal from './ConfirmModal'
import InternalAccountTransfer from './InternalAccountTransfer'
import AccountGroupAliasUpdate from './AccountGroupAliasUpdate'
import BankAccountDetails from './BankAccountDetails'
import PaymentRejectionModal from './PaymentRejectionModal'
import PaymentPushBackModal from './PaymentPushBackModal'
import CreateNewCurrencyAccount from './CreateNewCurrencyAccount'
import RemoveRepresentativeModal from './RemoveRepresentativeModal'

const modals = {
  confirmationModal: ConfirmModal,
  internalAccountTransfer: InternalAccountTransfer,
  accountGroupAliasUpdate: AccountGroupAliasUpdate,
  bankAccountDetails: BankAccountDetails,
  paymentRejectionModal: PaymentRejectionModal,
  paymentPushBackModal: PaymentPushBackModal,
  createNewCurrencyAccount: CreateNewCurrencyAccount,
  removeRepresentative: RemoveRepresentativeModal,
}

export default modals

export const modalProps: Partial<Omit<ModalProps, 'opened'>> & {
  modalId?: string
} = {
  centered: true,
  padding: '0 0.25rem', // works with margin/padding in the ModalWrapper to have the scrollbar appear nicely when needed
  radius: 'lg',
  shadow: 'lg',
  overlayProps: { blur: 5, opacity: 0.2 },
}
