import { Flag, getCountry } from '@innostream/stream-ui'
import { ActionIcon, Anchor, Box, Button, Center, Collapse, Flex, Loader, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import useFetchBeneficiary from '../../../api/queries/useFetchBeneficiary'
import { CSSGrid } from '../../../components/common/CSSGrid'
import BeneficiaryDetails from './BeneficiaryDetails'
import PaymentDetailsForm from './PaymentDetailsForm'
import {
  IconBeneficiaryLeikur,
  IconBeneficiarySepa,
  IconBeneficiarySwift,
  IconCloseCross,
  IconDocChecked,
  IconDownArrow,
  IconViewInfoEye,
} from '../../../components/icons'
import { PAYMENT_TYPE_DESCRIPTION } from '../../../utils/constants'
import currencies, { currencyFormat } from '../../../utils/currencies'
import formatIBAN from '../../../utils/formatIBAN'
import { PaymentBeneficiary, PaymentRequest, PaymentRequestStatus } from '../../../utils/interfaces'
import { colorAliases } from '../../../theme/getMantineTheme'

const PaymentBeneficiarySummary: React.FC<{
  isLastInGroup: boolean
  paymentBeneficiary: PaymentBeneficiary
  paymentRequestStatus: PaymentRequestStatus
  paymentRequestForm?: UseFormReturnType<PaymentRequest>
  updatePaymentRequest: (updatedRequest: PaymentRequest) => void
  forApproval?: boolean
}> = ({ paymentBeneficiary, paymentRequestStatus, paymentRequestForm, updatePaymentRequest, forApproval = false }) => {
  const largeScreen = useMediaQuery('(min-width: 62em)') // 62em => desktop breakpoint

  const { isLoading: isBeneficiaryLoading, data: beneficiaryDetails } = useFetchBeneficiary(paymentBeneficiary.id)

  const [openedPaymentDetails, _paymentDetailsHandlers] = useDisclosure(false)
  const paymentDetailsHandlers = {
    ..._paymentDetailsHandlers,
    toggle: () => {
      if (openedPaymentDetails) {
        paymentRequestForm?.reset()
      }
      _paymentDetailsHandlers.toggle()
    },
  }

  const [openedBeneficiaryDetails, beneficiaryDetailsHandlers] = useDisclosure(largeScreen)

  const sendUpdatedPaymentRequest = () => {
    if (paymentRequestForm) {
      paymentRequestForm.validate()
      if (paymentRequestForm.isValid()) {
        updatePaymentRequest({
          ...paymentRequestForm.values,
          status: PaymentRequestStatus.PENDING_APPROVAL,
        })
      }
    }
  }

  const cancelPaymentRequest = () => {
    if (paymentRequestForm) {
      modals.openConfirmModal({
        radius: 'md',
        padding: 'md',
        title: 'Cancel payment request',
        children: <Text size='sm'>Are you sure you want to cancel this payment request?</Text>,
        labels: { confirm: 'Yes', cancel: 'No' },
        confirmProps: {
          color: colorAliases.textInvertSecondary,
          radius: 'lg',
          rightIcon: <IconDownArrow style={{ height: '0.55em', transform: 'rotate(270deg)' }} />,
        },
        cancelProps: { radius: 'lg', color: 'error', variant: 'outline', rightIcon: <IconCloseCross style={{ width: '0.75em', marginTop: '1px' }} /> },
        onConfirm: () => {
          updatePaymentRequest({
            ...paymentRequestForm.values,
            status: PaymentRequestStatus.CANCELLED,
          })
        },
      })
    }
  }

  const getButtonProps = () => ({
    radius: 'xs',
    size: 'xs',
    fz: 'sm',
    px: 'md',
    h: '2.3rem',
    w: '10rem',
    color: 'transparentBlack.3',
    c: 'dark',
    variant: 'outline',
  })

  return (
    <Box
      p='md'
      sx={(theme) => ({
        borderTopColor: !forApproval ? theme.fn.themeColor(colorAliases.surfaceDark) : theme.fn.themeColor('muted'),
        borderWidth: '1pt',
        borderTopStyle: 'solid',
        // commenting while we're not currently catering for multi-beneficiaries, looks odd
        // borderBottomColor: theme.fn.themeColor('muted'),
        // borderBottomStyle: isLastInGroup ? 'solid' : 'none', 
        [':nth-of-type(even)']: {
          background: theme.fn.themeColor('background.5'),
        },
      })}
    >
      {isBeneficiaryLoading || !beneficiaryDetails ? (
        <Center py='lg'>
          <Loader color='secondary' />
        </Center>
      ) : (
        <CSSGrid>
          <CSSGrid
            templateAreas={{ base: '"name amount"', desktop: '"icon name reference amount"' }}
            templateColumns={{ base: '1fr auto', desktop: 'auto 1fr auto auto' }}
            alignItems='center'
            gap='sm'
            c='muted'
            pb='sm'
            sx={{ borderBottom: '1pt solid currentcolor' }}
          >
            <Center display={{ base: 'none', desktop: 'flex' }} c='primary' sx={{ gridArea: 'icon', width: '1.5rem' }}>
              {beneficiaryDetails.payment_type === 'leikur' ? (
                <IconBeneficiaryLeikur style={{ height: '1.5rem' }} />
              ) : beneficiaryDetails.payment_type === 'sepa' ? (
                <IconBeneficiarySepa style={{ height: '1.5rem', color: colorAliases.warning }} />
              ) : (
                <IconBeneficiarySwift style={{ height: '1.5rem', }} />
              )}
            </Center>

            <CSSGrid gap='3xs' sx={{ gridArea: 'name' }}>
              <Flex align='baseline' columnGap='2xs' rowGap='3xs' fz='lg' direction={{ base: 'column', desktop: 'row' }} c={colorAliases.textInvert}>
                <Text fw='bold'>{beneficiaryDetails.alias}</Text>
                {/* <Text component={IconBeneficiaryNameBookmark} c='primary' h='1em' /> */}
                <Text c='textPrimary' fz='md' display={{ base: 'none', desktop: 'block' }} >
                  ({beneficiaryDetails.name})
                </Text>
                <Text fz='md' display={{ base: 'block', desktop: 'none' }}>
                  {beneficiaryDetails.name}
                </Text>
              </Flex>
              <Text fz={{ base: 'xs', desktop: 'sm' }} display={{ base: 'none', desktop: 'block' }} c={colorAliases.textInvert}>
                {formatIBAN(beneficiaryDetails.account_details.iban)}
              </Text>
            </CSSGrid>

            <Box fz='md' display={{ base: 'none', desktop: 'block' }} ta='right' sx={{ gridArea: 'reference' }}>
              <Text fz='sm' fw='bold' lh='1.2' c={colorAliases.textInvert} mb='3xs'>
                Reference
              </Text>
              <Text c={colorAliases.textInvert}>{paymentBeneficiary.reference}</Text>
            </Box>

            <CSSGrid autoFlow='column' alignItems='center' gap='sm' c='primary' fw='bold' sx={{ gridArea: 'amount' }}>
              <Flag
                countryCode={currencies[beneficiaryDetails.currency]?.countryCodeForFlag}
                square={true}
                style={{
                  border: '1pt solid #f5f5f5',
                  borderRadius: '50%',
                  width: '2.25rem',
                  height: '2.25rem',
                }}
              />
              <CSSGrid ta='right' alignItems='center'>
                <Text c='secondary' fw={700} fz={{ base: 'lg', desktop: 'xl' }} lh={1} mb='2xs'>
                  {currencies[beneficiaryDetails.currency].symbol} {currencyFormat.format(paymentBeneficiary.amount)}
                </Text>

                <Text c={colorAliases.textInvert} fw={500} fz={{ base: 'xs', desktop: 'sm' }} lh={1}>
                  {beneficiaryDetails.currency} / {currencies[beneficiaryDetails.currency].regionName}
                </Text>
              </CSSGrid>
            </CSSGrid>
          </CSSGrid>

          <CSSGrid
            pt='sm'
            templateAreas={{
              base: '"reference" "payment_type" "proof_email" "proof_number" "docs" "buttons" "content"',
              desktop: '"payment_type proof_email proof_number buttons"  "docs docs docs buttons" "content content content content"',
            }}
            templateColumns={{ base: '1fr', desktop: '1fr 1fr 1fr auto' }}
            rowGap='sm'
            columnGap='md'
          >
            <CSSGrid
              templateColumns='1fr auto'
              justifyContent='space-between'
              fz='sm'
              display={{ desktop: 'none' }}
              sx={{ gridArea: 'reference' }}
            >
              <Box fw='bold' mb='3xs'>
                Reference
              </Box>
              <Box>{paymentBeneficiary.reference}</Box>
            </CSSGrid>

            <CSSGrid
              sx={{ gridArea: 'payment_type' }}
              fz='sm'
              templateColumns={{ base: '1fr auto', desktop: '1fr' }}
              justifyContent={{ base: 'space-between', desktop: 'start' }}
              gap='xs'
            >
              <Box fw='bold'>{PAYMENT_TYPE_DESCRIPTION[beneficiaryDetails.payment_type]}</Box>
              <Flex align='center' gap='3xs' justify='start'>
                <Flag countryCode={beneficiaryDetails.country} square={false} />
                <Flex align='center'>
                  <Box fw={700}>{beneficiaryDetails.country}</Box>
                  &nbsp;{getCountry(beneficiaryDetails.country)?.name}
                </Flex>
              </Flex>
            </CSSGrid>

            <CSSGrid
              sx={{ gridArea: 'proof_email' }}
              fz='sm'
              templateColumns={{ base: '1fr auto', desktop: '1fr' }}
              justifyContent={{ base: 'space-between', desktop: 'start' }}
              gap='3xs'
            >
              <Box fw='bold'>
                Email address
              </Box>
              <Box>{paymentBeneficiary.popEmail ? paymentBeneficiary.popEmail : 'N/A'}</Box>
            </CSSGrid>

            <CSSGrid
              sx={{ gridArea: 'proof_number' }}
              fz='sm'
              templateColumns={{ base: '1fr auto', desktop: '1fr' }}
              justifyContent={{ base: 'space-between', desktop: 'start' }}
              gap='3xs'
            >
              <Box fw='bold'>
                Mobile number
              </Box>

              <Box>{paymentBeneficiary.popMobileNumber ? paymentBeneficiary.popMobileNumber.formattedPhoneNumber : 'N/A'}</Box>
            </CSSGrid>

            <Box sx={{ gridArea: 'docs', justifySelf: 'start' }}>
              <Flex align='center' gap='3xs' mb='3xs'>
                <Box component={IconDocChecked} h='lg' ml='-4px' />
                <Box fz='sm' color='textPrimary' fw='bold'>
                  Supporting documents
                </Box>
              </Flex>
              <CSSGrid gap='3xs' alignItems='center'>
                {paymentBeneficiary?.documents?.map((d) => (
                  <Anchor href={d.document_url} target='_blank' display='flex' sx={{ alignItems: 'center', gap: '0.4rem' }} key={d.document_id}>
                    <Box fz='xs' c='primary'>
                      {d.document_name}
                    </Box>
                    <ActionIcon size='sm' variant='transparent' color='primary'>
                      <IconViewInfoEye />
                    </ActionIcon>
                  </Anchor>
                ))}
              </CSSGrid>
            </Box>

            <CSSGrid sx={{ gridArea: 'buttons' }} ml='3xs' rowGap='2xs' alignContent='end' justifyContent={{ base: 'center', desktop: 'end' }}>
              {!forApproval && (
                <Button
                  {...getButtonProps()}
                  leftIcon={<Box component={IconCloseCross} h='0.7em' />}
                  onClick={cancelPaymentRequest}
                  disabled={paymentRequestStatus === PaymentRequestStatus.REJECTED}
                >
                  Cancel request
                </Button>
              )}
              {!forApproval && paymentRequestStatus !== PaymentRequestStatus.REJECTED && (
                <Button
                  {...getButtonProps()}
                  variant='outline'
                  sx={(theme) => ({
                    border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`,
                  })}
                  styles={{
                    root: {
                      '&:hover': {
                        backgroundColor: 'inherit',
                      }
                    },
                  }}
                  leftIcon={
                    <IconDownArrow
                      style={{
                        height: '0.4em',
                        transform: openedPaymentDetails ? 'rotate(180deg)' : '',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  }
                  onClick={paymentDetailsHandlers.toggle}
                  color='primary'
                >
                  {openedPaymentDetails ? 'Cancel edits' : 'Edit'}
                </Button>
              )}

              <Button
                {...getButtonProps()}
                sx={(theme) => ({
                  border: `1px solid ${theme.fn.themeColor(colorAliases.borderSubtle)}`,
                })}
                styles={{
                  root: {
                    '&:hover': {
                      backgroundColor: 'inherit',
                    }
                  },
                }}
                leftIcon={
                  <IconDownArrow
                    style={{
                      height: '0.4em',
                      transform: openedBeneficiaryDetails ? 'rotate(180deg)' : '',
                      transition: 'transform 0.3s ease-in-out',
                    }}
                  />
                }
                onClick={beneficiaryDetailsHandlers.toggle}
              >
                {openedBeneficiaryDetails ? 'Show Less' : 'Show more'}
              </Button>
            </CSSGrid>

            <Box sx={{ gridArea: 'content' }}>
              {!forApproval && (
                <Collapse in={openedPaymentDetails} c='muted' transitionDuration={500}>
                  <Box
                    p='md'
                    bg='white'
                    sx={(theme) => ({
                      border: `1pt dashed ${theme.fn.themeColor('secondary')}`,
                    })}
                  >
                    {paymentRequestForm && <PaymentDetailsForm beneficiary={{ ...beneficiaryDetails, ...paymentBeneficiary }} form={paymentRequestForm} />}
                    <Flex justify={{ base: 'center', desktop: 'end' }} mt='sm'>
                      <CSSGrid gap='sm' templateColumns='1fr 1fr'>
                        <Button
                          {...getButtonProps()}
                          leftIcon={<IconCloseCross style={{ height: '0.8em' }} />}
                          onClick={paymentDetailsHandlers.toggle}
                        >
                          Cancel edits
                        </Button>

                        <Button
                          radius='xs'
                          size='xs'
                          fz='sm'
                          w='10rem'
                          h='2.3rem'
                          color='secondary'
                          leftIcon={<IconDownArrow style={{ height: '0.4em', transform: 'rotate(270deg)' }} />}
                          variant='filled'
                          onClick={sendUpdatedPaymentRequest}
                        >
                          Save
                        </Button>
                      </CSSGrid>
                    </Flex>
                  </Box>
                </Collapse>
              )}

              <Collapse in={openedBeneficiaryDetails} c='muted' sx={{ borderTop: openedPaymentDetails ? undefined : '1pt solid currentcolor' }}>
                <BeneficiaryDetails beneficiary={{ ...beneficiaryDetails, ...paymentBeneficiary }} />
              </Collapse>
            </Box>
          </CSSGrid>
        </CSSGrid>
      )}
    </Box>
  )
}

export default PaymentBeneficiarySummary
