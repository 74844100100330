import { Anchor, Box, Button, Flex, Paper, PasswordInput, Text, TextInput } from '@mantine/core'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { CSSGrid } from '../components/common/CSSGrid'
import { PageEncryptedReminder } from '../components/common/PageEncryptionReminder'
import ThemedBackgroundImage from '../components/common/ThemedBackgroundImage'
import Container from '../components/layout/Container'
import { LogoLeikur } from '../components/logos'
import { AuthContext } from '../contexts'

const SignIn = () => {
  const navigate = useNavigate()
  const [loginError] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { token, setToken } = useContext(AuthContext)

  const tryLogin = () => {
    setToken('a test')
  }

  useEffect(() => {
    if (token && token !== '') {
      navigate('/dashboard')
    }
  }, [token, navigate])

  return (
    <Box pos='relative' mih='100vh'>
      <ThemedBackgroundImage theme='leikur' />
      <Helmet>
        <title>Leikur - Sign In</title>
      </Helmet>
      <Container style={{ zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} pos='relative' mih='100vh'>
        <Paper miw={390}>
          <Flex py='xl' fz={{ base: '2.25rem', desktop: '3rem' }} justify='center'>
            <LogoLeikur style={{ height: '1.25em' }} />
          </Flex>
          <CSSGrid px='lg' gap='xl'>
            <TextInput
              type='email'
              label='Email address'
              id='email'
              description='The email address linked to your profile'
              onChange={(e) => setEmail(e.currentTarget.value)}
              value={email}
              autoFocus={true}
              onKeyDown={(e) => e.key === 'Enter' && tryLogin()}
            />
            <PasswordInput
              label='Password'
              description='Enter your profile password to log in'
              id='password'
              onChange={(e) => setPassword(e.currentTarget.value)}
              value={password}
              onKeyDown={(e) => e.key === 'Enter' && tryLogin()}
            />
            {loginError && (
              <Text color='error' size='xs'>
                {loginError}
              </Text>
            )}
            <Text fz='sm' c='textPrimary'>
              Forgot your password? Click <Anchor>here</Anchor> to reset.
            </Text>
            <Flex justify='space-between'>
              <Button variant='outline' onClick={() => navigate('/onboarding/sign-up')}>
                Create account
              </Button>
              <Button disabled={email === '' || password === ''} onClick={tryLogin}>
                Log in
              </Button>
            </Flex>
          </CSSGrid>
          <PageEncryptedReminder sx={{ justifyContent: 'center' }} mt='xl' />
        </Paper>
      </Container>
    </Box>
  )
}

export default SignIn
