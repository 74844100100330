import { Badge, Box, Button, Center, Flex, Loader, Select, Text, useMantineTheme } from '@mantine/core'
import { modals } from '@mantine/modals'
import { useEffect, useState } from 'react'
import useFetchAccountGroupsByIBAN from '../../../../api/queries/useFetchAccountGroupsByIBAN'
import useUpdatePaymentRequest from '../../../../api/queries/useUpdatePaymentRequest'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { getSelectPropsForAccounts } from '../../../../components/common/accountDropdownHelper'
import { IconArrowPushBack, IconChecked, IconCloseCross, IconDownArrow } from '../../../../components/icons'
import { openModalForPaymentPushBack } from '../../../../components/modals/PaymentPushBackModal'
import { openModalForPaymentRejection } from '../../../../components/modals/PaymentRejectionModal'
import currencies, { currencyFormat } from '../../../../utils/currencies'
import { Account, PaymentRequest, PaymentRequestStatus, Theme } from '../../../../utils/interfaces'
import PaymentBeneficiarySummary from '../PaymentBeneficiarySummary'
import { getStatusColor } from './ApprovalRequestDetailsArea'
import { colorAliases } from '../../../../theme/getMantineTheme'

const ApprovalRequestDetails: React.FC<{ request: PaymentRequest; readyPaymentRequests: PaymentRequest[] }> = ({ request, readyPaymentRequests }) => {
  const { isLoading: isUpdatingPaymentRequest, mutate: updatePaymentRequest } = useUpdatePaymentRequest()

  const { isLoading: isLoadingAccountGroupsByIban, data: accountGroupByIBAN } = useFetchAccountGroupsByIBAN()
  const [accountsWithTheme, setAccountsWithTheme] = useState<(Account & { theme: Theme })[]>([])

  const [selectedAccount, setSelectedAccount] = useState<string | null>(null)
  const theme = useMantineTheme()

  useEffect(() => {
    if (!isLoadingAccountGroupsByIban && accountGroupByIBAN) {
      const accountsWithTheme = Object.keys(accountGroupByIBAN).flatMap((key) =>
        accountGroupByIBAN[key].accounts.map((account) => ({
          ...account,
          theme: accountGroupByIBAN[key].theme,
        }))
      )
      const accountsOfSameRequestCurrency = accountsWithTheme.filter((account) => account.currency === request.currency)

      setAccountsWithTheme(accountsOfSameRequestCurrency)

      if (accountsOfSameRequestCurrency.length > 0) {
        setSelectedAccount(request.approval_details?.account_id ?? accountsOfSameRequestCurrency[0].id ?? null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAccountGroupsByIban, accountGroupByIBAN, request.currency])

  const markAsReady = () => {
    if (selectedAccount) {
      const similarRequests = readyPaymentRequests.filter((req) => req.approval_details?.account_id === selectedAccount)
      const newTotal = similarRequests.reduce((sum, req) => sum + req.beneficiaries[0].amount, 0) + request.beneficiaries[0].amount
      if ((accountsWithTheme.find((account) => account.id === selectedAccount)?.balance ?? 0) >= newTotal) {
        modals.openConfirmModal({
          radius: 'md',
          padding: 'md',
          title: 'Confirm payment request status',
          children: <Text size='sm'>Are you sure you want to mark this payment request as ready?</Text>,
          labels: { confirm: 'Yes', cancel: 'No' },
          confirmProps: {
            color: 'primary',
            radius: 'xs',
            leftIcon: <IconDownArrow style={{ height: '0.55em', transform: 'rotate(270deg)' }} />,
          },
          cancelProps: {
            radius: 'xs',
            c: colorAliases.textInvert,
            color: colorAliases.borderSubtle,
            variant: 'outline',
            rightIcon: <IconCloseCross style={{ width: '0.75em', marginTop: '1px' }} />
          },
          onConfirm: () => {
            updatePaymentRequest({
              ...request,
              statusNote: `Ready. Pay from: ${selectedAccount}`,
              status: PaymentRequestStatus.READY,
              approval_details: {
                approved_by: 'admin',
                account_id: selectedAccount,
              },
            })
          },
        })
      } else {
        modals.open({
          radius: 'md',
          padding: 'lg',
          title: 'Insufficient funds',
          children: (
            <Box c='textPrimary'>
              The selected account does not have enough funds to cover the payment amount. <br />
              <br />{' '}
              <Box component='span' fz='xs' fs='italic'>
                We are also considering other pending approval requests that are set as ready.
              </Box>
            </Box>
          ),
          size: 'sm',
        })
      }
    }
  }

  const markAsPending = () => {
    modals.openConfirmModal({
      radius: 'md',
      padding: 'md',
      title: 'Confirm payment request status',
      children: <Text size='sm'>Are you sure you want to mark this payment request as pending?</Text>,
      labels: { confirm: 'Yes', cancel: 'No' },
      confirmProps: {
        color: 'primary',
        radius: 'xs',
        leftIcon: <IconDownArrow style={{ height: '0.55em', transform: 'rotate(270deg)' }} />,
      },
      cancelProps: { radius: 'xs', color: 'error', variant: 'outline', leftIcon: <IconCloseCross style={{ width: '0.75em', marginTop: '1px' }} /> },
      onConfirm: () => {
        updatePaymentRequest({
          ...request,
          statusNote: '',
          status: PaymentRequestStatus.PENDING_APPROVAL,
          approval_details: undefined,
        })
      },
    })
  }

  return isUpdatingPaymentRequest ? (
    <Center py='lg'>
      <Loader color='secondary' />
    </Center>
  ) : (
    <Box>
      <CSSGrid
        templateColumns={{ desktop: '1fr auto' }}
        pt='md'
        gap='sm'
        alignItems='start'
        bg='white'
        px={{ base: 'md', desktop: 0 }}
        sx={(theme) => ({ borderTop: `1pt solid ${theme.fn.themeColor(colorAliases.surfaceDark)}` })}
      >
        <Flex gap='3xs' align={{ desktop: 'center' }} justify='start' direction={{ base: 'column', desktop: 'row' }}>
          <Flex justify='space-between' align='center' my='xs'>
            <Text fw='900' fz='sm'>
              Pay from:&nbsp;
            </Text>
            <Badge tt='capitalize' display={{ base: 'block', desktop: 'none' }} py={{ base: '3xs' }} h={{ base: 'inherit', desktop: 'xl' }} variant='outline' color={getStatusColor(request.status)}>
              {request.status}
            </Badge>
          </Flex>
          <Select
            {...getSelectPropsForAccounts(accountsWithTheme ?? [], selectedAccount)}
            w={{ desktop: 'auto' }}
            miw='17.5rem'
            data={[
              ...(accountsWithTheme ?? []).map((account) => ({
                value: account.id,
                label: `${account.alias} (${currencies[account.currency].symbol} ${currencyFormat.format(account.balance)})`,
                currency: account.currency,
                theme: account.theme as never,
              })),
            ]}
            size='sm'
            value={selectedAccount}
            onChange={(newVal) => setSelectedAccount(newVal ?? accountsWithTheme[0].id ?? null)}
            disabled={request.status === PaymentRequestStatus.PUSHED_BACK}
          />
        </Flex>
        <CSSGrid alignItems='center' gap='sm' templateColumns='repeat(3, 1fr)' pb='md'>
          <Button
            leftIcon={<IconChecked style={{ color: request.status !== PaymentRequestStatus.READY ? 'white' : theme.fn.themeColor('primary') }} />}
            h='3.5em'
            size='xs'
            radius='xs'
            color={request.status === PaymentRequestStatus.PUSHED_BACK ? 'warning' : 'primary'}
            variant={request.status === PaymentRequestStatus.READY ? 'outline' : 'filled'}
            onClick={request.status === PaymentRequestStatus.READY || request.status === PaymentRequestStatus.PUSHED_BACK ? markAsPending : markAsReady}
          >
            {request.status === PaymentRequestStatus.READY ? 'Not ready?' : request.status === PaymentRequestStatus.PUSHED_BACK ? 'Pending' : 'Ready'}
          </Button>
          <Button
            leftIcon={<IconArrowPushBack style={{ width: '1.6em', transform: 'rotateX(180deg)' }} />}
            h='3.5em'
            size='xs'
            radius='xs'
            color='secondary'
            variant='filled'
            disabled={request.status === PaymentRequestStatus.PUSHED_BACK}
            onClick={() => openModalForPaymentPushBack({ paymentRequest: request })()}
          >
            Push Back
          </Button>
          <Button
            leftIcon={<IconCloseCross style={{ width: '0.7em' }} />}
            h='3.5em'
            size='xs'
            radius='xs'
            color='dark.0'
            variant='filled'
            onClick={() => openModalForPaymentRejection({ paymentRequest: request })()}
          >
            Reject
          </Button>
        </CSSGrid>
      </CSSGrid>

      {(request.status === PaymentRequestStatus.PUSHED_BACK || request.status === PaymentRequestStatus.REJECTED) && (
        <Box py='xs'>
          <Text fz='md' fw={600} lh={1.5} color='secondary'>
            {request.status === PaymentRequestStatus.PUSHED_BACK ? 'Required amendments' : 'Rejection reason'}
          </Text>
          <Text fz='sm' lh={1.5} c='textPrimary'>
            {request.statusNote}
          </Text>
        </Box>
      )}
      <Box>
        {request.beneficiaries.map((beneficiary, index, beneficiaries) => (
          <PaymentBeneficiarySummary
            paymentRequestStatus={request.status}
            key={beneficiary.id}
            isLastInGroup={index === beneficiaries.length - 1}
            paymentBeneficiary={beneficiary}
            updatePaymentRequest={updatePaymentRequest}
            forApproval={true}
          />
        ))}
      </Box>
    </Box>
  )
}

export default ApprovalRequestDetails
