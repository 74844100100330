import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Beneficiary, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const addBeneficiary = async (dispatch: React.Dispatch<SessionReducerAction>, beneficiary: Beneficiary) => {
  return new Promise<Beneficiary>((resolve) => {
    const newBeneficiary = { ...beneficiary, id: crypto.randomUUID() }
    dispatch({
      type: SessionReducerActionType.ADD_BENEFICIARY,
      payload: newBeneficiary,
    })
    resolve(newBeneficiary)
  })
}

export default function useAddBeneficiary(onSuccess: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (beneficiary: Beneficiary) => addBeneficiary(dispatch, beneficiary),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['beneficiaries'] })
      onSuccess()
    },
  })
}
