import { Button, ButtonProps, MantineColor, MantineSize, Variants } from '@mantine/core';
import React, { CSSProperties, forwardRef } from 'react';
import useStyles from './VerticalButton.styles';

export interface VerticalButtonProps extends Omit<ButtonProps & React.ComponentPropsWithoutRef<'button'>, 'variant' | 'leftIcon' | 'rightIcon'> {
  variant?: Variants<'filled' | 'outline' | 'light' | 'white' | 'default' | 'subtle' | 'gradient' | 'borderless'>;
  size?: MantineSize;
  iconPadding?: CSSProperties['padding'];
  textPadding?: CSSProperties['padding'];
  textColor?: MantineColor;
  iconColor?: MantineColor;
  borderColor?: MantineColor;
  reverse?: boolean;
  animation?: 'simple' | 'fancy';

  icon: React.ReactNode;
  isSquare?: boolean;
}

export const VerticalButton = forwardRef<HTMLButtonElement, VerticalButtonProps>(
  (
    {
      children,
      classNames,
      icon,
      isSquare,
      iconPadding,
      textPadding,
      variant = 'outline',
      size = 'md',
      textColor,
      iconColor,
      borderColor,
      reverse,
      animation,
      ...props
    }: VerticalButtonProps,
    ref
  ) => {
    const { classes } = useStyles(
      {
        ...props,
        icon,
        isSquare,
        iconPadding,
        textPadding,
        variant,
        size,
        children,
        textColor,
        iconColor,
        borderColor,
        animation,
        reverse,
      },
      { classNames, name: 'custom' }
    );
    const mantineVariant = variant === 'borderless' ? 'outline' : variant;

    return (
      <Button classNames={classes} leftIcon={icon} variant={mantineVariant} size={size} {...props} ref={ref}>
        {children}
      </Button>
    );
  }
);
