import { Box, BoxProps, packSx } from '@mantine/core';
import React, { forwardRef } from 'react';
import ReactCountryFlag, { ReactCountryFlagProps } from 'react-country-flag';

export type FlagProps = Omit<ReactCountryFlagProps, 'cdnUrl' | 'svg'> & {
  /** denotes where the flag should be a square or not */
  square?: boolean;
} & BoxProps;

export const Flag = forwardRef<HTMLSpanElement, FlagProps>(({ countryCode, square = false, style, ...props }, ref) => {
  // console.log(props.sx);
  return (
    <Box<'span'> display="grid" component="span" {...props} sx={[{ overflow: 'hidden' }, ...packSx(props.sx)]} ref={ref}>
      <ReactCountryFlag
        countryCode={countryCode}
        svg={true}
        style={{
          width: 'unset',
          height: '1rem',
          ...style,
        }}
        cdnUrl={'https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/' + (square ? '1x1/' : '4x3/')}
      />
    </Box>
  );
});
