import { Box, Button } from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSGrid, CSSGridProps } from '../../../../components/common/CSSGrid'
import { IconAccount, IconExchange, IconReceive, IconSend } from '../../../../components/icons'
import { openModalForBankAccountDetails } from '../../../../components/modals/BankAccountDetails'
import { openModalForInternalAccountTransfer } from '../../../../components/modals/InternalAccountTransfer'
import { colorAliases } from '../../../../theme/getMantineTheme'
import { AccountGroup } from '../../../../utils/interfaces'

const OptionButtons: React.FC<CSSGridProps & { group: AccountGroup; withoutAccounts?: boolean }> = ({ group, withoutAccounts = false, ...props }) => {
  const navigate = useNavigate()

  return (
    <CSSGrid rowGap='lg' templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} {...props}>
      <Button style={{ border: 'none' }} p='0' size='big' variant='leikurDark' leftIcon={<IconSend />} color='secondary' onClick={() => navigate('/transaction-centre/')}>
        <Box c={colorAliases.textLink}>
          Send
          <br />
          <Box component='span' c={colorAliases.light50}>
            pay beneficiary
          </Box>
        </Box>
      </Button>

      {!withoutAccounts && (
        <Button style={{ border: 'none' }} p='0' size='big' variant='leikurDark' leftIcon={<IconAccount />} color='secondary' onClick={() => navigate('/statement-centre/' + group.iban)}>
          <Box c={colorAliases.textLink}>
            Account
            <br />
            <Box component='span' c={colorAliases.light50}>
              information & statement
            </Box>
          </Box>
        </Button>
      )}

      <Button
        size='big'
        p='0'
        variant='leikurDark'
        leftIcon={<IconExchange />}
        color='secondary'
        onClick={openModalForInternalAccountTransfer({ selectedIban: group.iban })}
      >
        <Box c={colorAliases.textLink}>
          Transfer
          <br />
          <Box component='span' c={colorAliases.light50}>
            move internally
          </Box>
        </Box>
      </Button>

      <Button
        size='big'
        p='0'
        variant='leikurDark'
        leftIcon={<IconReceive />}
        color='secondary'
        onClick={openModalForBankAccountDetails({
          bank_account_details: group.accounts[0]?.account_addresses[0].bank_account_details,
        })}
      >
        <Box c={colorAliases.textLink}>
          Receive
          <br />
          <Box component='span' c={colorAliases.light50}>
            payment instructions
          </Box>
        </Box>
      </Button>

    </CSSGrid>
  )
}

export default OptionButtons
