import { Flex, Button, Textarea } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { ContextModalProps, modals } from '@mantine/modals'
import { PaymentRequestStatus, PaymentRequest } from '../../utils/interfaces'
import { CSSGrid } from '../common/CSSGrid'
import { IconCloseCross, IconDownArrow } from '../icons'
import ModalWrapper from './ModalWrapper'
import useUpdatePaymentRequest from '../../api/queries/useUpdatePaymentRequest'
import { colorAliases } from '../../theme/getMantineTheme'

const PaymentPushBackModal = ({ context, id, innerProps: { paymentRequest } }: ContextModalProps<{ paymentRequest: PaymentRequest }>) => {
  const { mutate: updatePaymentRequest, isSuccess } = useUpdatePaymentRequest(() => context.closeModal(id))

  const form = useForm<{
    reason: string
    description: string
  }>({
    initialValues: {
      reason: '',
      description: '',
    },
    validate: {
      reason: isNotEmpty('Please enter a push back reason for this payment request'),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  const sendPaymentPushback = () => {
    form.validate()
    if (form.isValid()) {
      updatePaymentRequest({
        ...paymentRequest,
        status: PaymentRequestStatus.PUSHED_BACK,
        statusNote: form.values.reason || '',
      })
      if (isSuccess) context.closeModal(id)
    }
  }

  return (
    <ModalWrapper title='Push-back payment request' onClose={() => context.closeModal(id)}>
      <CSSGrid gap='md' px='xs'>
        <Textarea label='Reason' description='Enter a reason for pushing back this request' variant='filled' labelProps={{ c: 'dark' }} {...form.getInputProps('reason')} />
        <Flex align='center' justify='right' gap='sm' mt='md'>
          <Button
            w={125}
            rightIcon={<IconCloseCross style={{ height: '0.75em', width: '0.75rem' }} />}
            variant='outline'
            radius='xs'
            color={colorAliases.borderSubtle}
            c={colorAliases.textInvert}
            onClick={() => context.closeModal(id)}
          >
            Cancel
          </Button>

          <Button
            radius='xs'
            leftIcon={<IconDownArrow style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
            variant='filled'
            color='primary'
            onClick={sendPaymentPushback}
          >
            Send
          </Button>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type OpenModalForPaymentPushBackModalProps = {
  paymentRequest?: PaymentRequest
  onClose?: () => void
}
export function openModalForPaymentPushBack({ paymentRequest, onClose }: OpenModalForPaymentPushBackModalProps = {}) {
  return () =>
    modals.openContextModal({
      modal: 'paymentPushBackModal',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'lg',
      innerProps: {
        paymentRequest: paymentRequest,
      },
    })
}

export default PaymentPushBackModal
