import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Beneficiary, Transaction } from '../../utils/interfaces'
import { delayEmulation } from '../config'

const fetchTransactionsByBeneficiary = async (
  beneficiaries: Beneficiary[],
  transactions: Transaction[],
  id: string
) => {
  return new Promise<Transaction[]>((resolve, reject) => {
    delayEmulation()
      .then(() => {
        const beneficiary = beneficiaries.find((b) => b.id === id)
        if (beneficiary) {
          resolve(
            (transactions ?? []).filter(
              (transaction) => transaction.counterparty.iban === beneficiary.account_details.iban
            )
          )
        }
        reject('Beneficiary not found')
      })
      .catch(() => reject())
  })
}

export default function useFetchTransactionsByBeneficiary(id = '', enabled = true) {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['transactions-beneficiary', session.beneficiaries, session.transactions, id],
    queryFn: () => fetchTransactionsByBeneficiary(session.beneficiaries, session.transactions, id),
    enabled,
  })
}
