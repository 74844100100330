import React, { createContext, useState } from 'react'
import { Beneficiary } from '../utils/interfaces'

interface BeneficiaryContextProps {
  selectedBeneficiary?: Beneficiary
  setSelectedBeneficiary: React.Dispatch<React.SetStateAction<Beneficiary | undefined>>
}

const defaultContext: BeneficiaryContextProps = {
  selectedBeneficiary: {} as Beneficiary,
  setSelectedBeneficiary: () => null,
}

const BeneficiaryContext = createContext<BeneficiaryContextProps>(defaultContext)

export const BeneficiaryProvider: React.FC = ({ children }) => {
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<Beneficiary>()

  return (
    <BeneficiaryContext.Provider value={{ selectedBeneficiary, setSelectedBeneficiary }}>
      {children}
    </BeneficiaryContext.Provider>
  )
}

export default BeneficiaryContext