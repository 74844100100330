import { Flex, Text } from '@mantine/core'

export const StepBox: React.FunctionComponent<{
  bg?: string
  step: string
}> = ({ bg = 'transparent', step, children }) => (
  <Flex gap='1rem' align='center'>
    <Flex
      bg={bg}
      c={step !== '3' ? 'dark.2' : 'white'}
      direction='column'
      justify='center'
      pos='relative'
      sx={(theme) => ({
        borderRadius: '6px',
        flexShrink: 0,
        padding: '0.5rem 0.8rem',
        border: `1px solid ${step === '3' ? 'transparent' : theme.fn.themeColor('transparentBlack.3')}`,
      })}
    >
      <Text fz='sm' ta='center' lh='1.3'>
        STEP
      </Text>
      <Text fz='sm' ta='center' lh='1.3'>
        #{step}
      </Text>
    </Flex>
    <Text fz='sm' lh={1.25}>
      {children}
    </Text>
  </Flex>
)
