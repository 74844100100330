import {
  formatValidPhoneNumber,
  getCountry,
  getSelectPropsForCountryDiallingCode,
  isValidPhoneNumber,
} from '@innostream/stream-ui'
import {
  CSSObject,
  DefaultProps,
  Group,
  GroupProps,
  Input,
  InputBaseProps,
  InputWrapperBaseProps,
  Select,
  SelectProps,
  TextInput,
  TextInputProps,
  rem,
} from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import React, { ComponentProps, forwardRef, useState } from 'react'

export type InternationalPhoneNumber = {
  countryCode: string | null
  localPhoneNumber: string
  diallingCode: string
  isValid: boolean
  formattedPhoneNumber: string
}

export interface PhoneNumberInputProps extends DefaultProps, InputWrapperBaseProps, Pick<InputBaseProps, 'disabled'> {
  /** Controlled input value */
  value?: Partial<InternationalPhoneNumber>
  /** Controlled input onChange handler */
  onChange?: (value: InternationalPhoneNumber) => void
  inputRef?: React.ForwardedRef<HTMLInputElement>
  selectProps?: Partial<SelectProps>
  textInputProps?: Partial<TextInputProps>
  groupProps?: Partial<GroupProps>
  form?: ComponentProps<'input'>['form']
  onBlur?: (value: Partial<InternationalPhoneNumber>) => void
}

export const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  (
    {
      classNames,
      value,
      onChange,
      inputRef,
      selectProps,
      textInputProps,
      groupProps,
      disabled,
      form,
      onBlur,
      styles,
      ...props
    }: PhoneNumberInputProps,
    ref
  ) => {
    const [_value, handleChange] = useUncontrolled({
      value,
      defaultValue: {
        countryCode: null,
        localPhoneNumber: '',
        isValid: false,
      },
      onChange,
    })

    const _handleChange = ({ localPhoneNumber, countryCode }: Partial<InternationalPhoneNumber>) => {
      const lpn = localPhoneNumber !== undefined ? localPhoneNumber : _value.localPhoneNumber
      const cc = countryCode !== undefined ? countryCode : _value.countryCode
      const isValid = isValidPhoneNumber({ localPhoneNumber: lpn, countryCode: cc })
      handleChange({
        isValid,
        localPhoneNumber: lpn,
        countryCode: cc,
        diallingCode: cc !== undefined && cc !== null ? getCountry(cc).phoneCode : undefined,
        formattedPhoneNumber: isValid ? formatValidPhoneNumber({ localPhoneNumber: lpn, countryCode: cc }) : undefined,
      })
    }

    const [blurredInput, _setBlurredInput] = useState({ countryCode: false, phoneNumber: false })

    const setBlurredInput = (key: string) => {
      const newState = { ...blurredInput, [key]: true }
      if (newState.phoneNumber && newState.countryCode && onBlur !== undefined) {
        onBlur(_value)
      }
      _setBlurredInput(newState)
    }

    return (
      <Input.Wrapper
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        classNames={classNames}
        styles={{ ...styles }}
        {...props}
        ref={ref}
      >
        <Group mb={props.description ? 5 : undefined} noWrap={true} {...groupProps}>
          <input
            ref={inputRef}
            type='hidden'
            value={_value?.formattedPhoneNumber || ''}
            form={form}
            disabled={disabled}
          />
          <Select
            searchable
            sx={{ width: 128, flexShrink: 0 }}
            {...getSelectPropsForCountryDiallingCode(_value?.countryCode)}
            value={_value?.countryCode}
            onChange={(countryCode) => _handleChange({ countryCode })}
            disabled={disabled}
            // {...sharedInnerInputProps}
            {...selectProps}
            // FIXME: styles may be a function that expects MantineTheme object
            styles={{
              ...selectProps?.styles,
              input: { paddingRight: '1.25rem' },
              dropdown: {
                minWidth: '13.75rem',
                transform: `translate(${rem((220 - 128) / 2)})`,
                ...(selectProps?.styles as { dropdown?: CSSObject })?.dropdown,
              },
            }}
            onBlur={() => setBlurredInput('countryCode')}
            error={props.error !== undefined}
          />
          <TextInput
            type='tel'
            value={_value?.localPhoneNumber}
            onChange={(e) => _handleChange({ localPhoneNumber: e.currentTarget.value })}
            disabled={disabled}
            // {...sharedInnerInputProps}
            {...textInputProps}
            style={{ flexGrow: 1, ...textInputProps?.style }}
            onBlur={() => setBlurredInput('phoneNumber')}
            error={props.error !== undefined}
          />
        </Group>
      </Input.Wrapper>
    )
  }
)

PhoneNumberInput.displayName = 'PhoneNumberInput'

/**
 * Enhanced an `InternationalPhoneNumber` with default calculated values.
 * @param value
 * @returns
 */
export const getInternationalPhoneNumberWithDefaults = (value: Partial<InternationalPhoneNumber>) => {
  const isValid = isValidPhoneNumber(value)
  return {
    countryCode: value.countryCode !== undefined ? value.countryCode : null,
    localPhoneNumber: value.localPhoneNumber !== undefined ? value.localPhoneNumber : '',
    isValid,
    diallingCode:
      value.countryCode !== undefined && value.countryCode !== null
        ? getCountry(value.countryCode).phoneCode
        : undefined,
    formattedPhoneNumber: isValid ? formatValidPhoneNumber(value) : undefined,
  } as InternationalPhoneNumber
}
