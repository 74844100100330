import { MantineColor, MantineTheme } from '@mantine/core';

export const setTransparency = (theme: MantineTheme, color: MantineColor | undefined, alpha: number, shade?: number | undefined) =>
  theme.fn.rgba(theme.fn.themeColor(color as string, shade, true, shade === undefined), alpha)

export const isDark = (theme: MantineTheme) => theme.colorScheme === 'dark'

export const generateShades = (
  theme: MantineTheme,
  colorAsHex: string
) => {
  const primaryShade = theme.fn.primaryShade();

  let shades = ['', '', '', '', '', '', '', '', '', ''];

  for (let i = 0; i < 10; i++) {
    shades[i] =
      primaryShade === i
        ? theme.fn.rgba(colorAsHex, 1)
        : primaryShade > i
          ? theme.fn.lighten(colorAsHex, (primaryShade - i) * 0.1)
          : theme.fn.darken(colorAsHex, (i - primaryShade) * 0.1);
  }

  return shades as [string, string, string, string, string, string, string, string, string, string];
};
