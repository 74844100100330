import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequest } from '../../utils/interfaces'
import { delayEmulation } from '../config'
import { PaymentTemplateFilters } from '../../routes/authenticated/TransactionCentre/InitiatePayments'

const fetchPaymentTemplates = async (paymentTemplates: PaymentRequest[], filters?: PaymentTemplateFilters) => {
  return new Promise<PaymentRequest[]>((resolve, reject) => {
    delayEmulation()
      .then(() => {
        let filteredPaymentTemplates = paymentTemplates ?? []

        if (filters) {
          filteredPaymentTemplates = paymentTemplates.filter((template) => {
            const lowerSearch = filters.nameOrAliasOrReference.toLowerCase()
            const lowerInternalReference = template.internal_reference.toLowerCase()
            return (
              (lowerSearch === '' ||
                template.beneficiaries.some((beneficiary) => {
                  const lowerBeneficiaryName = beneficiary.name.toLowerCase()
                  const lowerBeneficiaryReference = beneficiary.reference?.toLowerCase() || ''

                  return lowerBeneficiaryName.includes(lowerSearch) || lowerBeneficiaryReference.includes(lowerSearch)
                }) ||
                lowerInternalReference.includes(lowerSearch)) &&
              (filters.currency == null || template.currency === filters.currency)
            )
          })
        }

        resolve(filteredPaymentTemplates)
      })
      .catch(() => reject())
  })
}

export default function useFetchPaymentTemplates(filters?: PaymentTemplateFilters) {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['payment-templates', session.paymentTemplates, filters],
    queryFn: () => fetchPaymentTemplates(session.paymentTemplates, filters),
  })
}
