import {
  Box,
  BoxProps,
  DefaultProps,
  Input,
  InputBaseProps,
  InputWrapperBaseProps,
  MantineNumberSize,
  Selectors,
  getSize,
  packSx,
  useMantineTheme,
} from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { ComponentProps, forwardRef, useRef, useState } from 'react';
import { SharedInputProps, TextInput, TextInputProps } from '../../simple/Inputs';
import useStyles, { SimpleDatePickerStylesParams } from './SimpleDatePicker.styles';
dayjs.extend(customParseFormat);
type SimleDatePickerInputStylesNames = Selectors<typeof useStyles>;
export type SimpleDate = {
  year: string;
  month: string;
  day: string;
  formattedDate: string;
  dayJsDate: dayjs.Dayjs | '';
  isValid: boolean;
};

export interface SimpleDatePickerProps
  extends DefaultProps<SimleDatePickerInputStylesNames, SimpleDatePickerStylesParams>,
    SharedInputProps,
    InputWrapperBaseProps,
    Pick<InputBaseProps, 'disabled'> {
  /** Controlled input value */
  value?: Partial<SimpleDate>;
  /** Controlled input onChange handler */
  onChange?(value: SimpleDate): void;

  inputRef?: React.ForwardedRef<HTMLInputElement>;
  textInputProps?: Partial<TextInputProps>;
  layoutProps?: Partial<BoxProps>;

  form?: ComponentProps<'input'>['form'];
  onBlur?: (value: Partial<SimpleDate>) => {};

  gap?: MantineNumberSize;
}

export const SimpleDatePicker = forwardRef<HTMLInputElement, SimpleDatePickerProps>(
  (
    {
      classNames,
      value,
      onChange,
      inputRef,
      textInputProps,
      layoutProps,
      borderColor,
      inputColor,
      labelColor,
      labelFontSize,
      labelFontWeight,
      errorColor,
      descriptionColor,
      otherFontSize,
      otherFontWeight,
      disabled,
      form,
      onBlur,
      styles,
      gap,
      ...props
    }: SimpleDatePickerProps,
    ref
  ) => {
    let yearRef = useRef<HTMLInputElement>(null);
    let monthRef = useRef<HTMLInputElement>(null);
    let dayRef = useRef<HTMLInputElement>(null);

    const { classes } = useStyles(
      {
        ...props,
        labelColor,
        labelFontSize,
        labelFontWeight,
        errorColor,
        descriptionColor,
        otherFontSize,
        otherFontWeight,
      } as SimpleDatePickerStylesParams,
      { classNames, name: 'custom' }
    );

    const sharedProps = {
      borderColor,
      inputColor,
    };

    const [_value, handleChange] = useUncontrolled({
      value,
      defaultValue: {
        year: '',
        month: '',
        day: '',
        formattedDate: undefined,
        isValid: false,
      },
      onChange,
    });

    const _handleChange = ({ year, month, day }: Partial<SimpleDate>) => {
      if (year !== undefined) {
        year = year.replace(/[^0-9]/g, '');
        _value.year = year;
      }
      if (month !== undefined) {
        month = month.replace(/[^0-9]/g, '');
      }
      if (day !== undefined) {
        day = day.replace(/[^0-9]/g, '');
      }

      const yearToTest = year !== undefined ? year : _value.year || '';
      const monthToTest = month !== undefined ? month : _value.month || '';
      const dayToTest = day !== undefined ? day : _value.day || '';
      const merged = `${yearToTest.padStart(4, '0')}-${monthToTest.padStart(2, '0')}-${dayToTest.padStart(2, '0')}`;
      const dayJsDate = dayjs(merged, 'YYYY-MM-DD', true);

      handleChange({
        year: yearToTest,
        month: monthToTest,
        day: dayToTest,
        formattedDate: dayJsDate.isValid() ? dayJsDate.format('YYYY-MM-DD') : '',
        isValid: dayJsDate.isValid(),
      });

      if (year?.toString().length === 4) {
        monthRef.current?.select();
      }
      if (month?.toString().length === 2) {
        dayRef.current?.select();
      }
    };

    const [blurredInput, _setBlurredInput] = useState<{ [key: string]: boolean }>({
      year: false,
      month: false,
      day: false,
    });

    const setBlurredInput = (key: string) => {
      const newState = { ...blurredInput, [key]: true };
      if (newState.year && newState.month && newState.day && onBlur !== undefined) {
        onBlur(_value);
      }
      _setBlurredInput(newState);
    };
    const theme = useMantineTheme();

    return (
      <Input.Wrapper inputWrapperOrder={['label', 'input', 'description', 'error']} classNames={classes} styles={{ ...styles }} {...props} ref={ref}>
        <Box
          mb={5}
          {...layoutProps}
          sx={[
            { display: 'grid', gridTemplateColumns: '4fr 3fr 3fr', gap: getSize({ size: gap || 0, sizes: theme.spacing }) },
            ...packSx(layoutProps?.sx),
          ]}
        >
          <TextInput
            {...sharedProps}
            {...textInputProps}
            type={'tel'}
            value={_value?.year}
            onChange={(e) => _handleChange({ year: e.currentTarget.value })}
            placeholder="YYYY"
            maxLength={4}
            disabled={disabled}
            onBlur={() => setBlurredInput('year')}
            ref={yearRef}
            error={props.error !== undefined}
          />
          <TextInput
            {...sharedProps}
            {...textInputProps}
            type={'tel'}
            value={_value?.month}
            onChange={(e) => _handleChange({ month: e.currentTarget.value })}
            placeholder="MM"
            maxLength={2}
            disabled={disabled}
            onBlur={() => setBlurredInput('month')}
            ref={monthRef}
            error={props.error !== undefined}
          />
          <TextInput
            {...sharedProps}
            {...textInputProps}
            type={'tel'}
            value={_value?.day}
            onChange={(e) => _handleChange({ day: e.currentTarget.value })}
            placeholder="DD"
            maxLength={2}
            disabled={disabled}
            onBlur={() => setBlurredInput('day')}
            ref={dayRef}
            error={props.error !== undefined}
          />
        </Box>
      </Input.Wrapper>
    );
  }
);
