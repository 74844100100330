import React, { createContext } from 'react'
import { OnboardingSession, OnboardingSessionReducerAction } from '../../types/onboarding'
import { useOnboardingSessionManager } from './useOnboardingSessionManager'

interface OnboardingContextProps {
  onboardingSession: OnboardingSession
  onboardingDispatch: React.Dispatch<OnboardingSessionReducerAction>
  clearOnboardingSession: () => void
}

//  Will only ever be {} if the context is read from somewhere that's not a child of OnboardingSessionProvider
export const OnboardingSessionContext = createContext<OnboardingContextProps>({} as OnboardingContextProps)

export const OnboardingSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [onboardingSession, onboardingDispatch, clearOnboardingSession] = useOnboardingSessionManager()

  return (
    <OnboardingSessionContext.Provider value={{ onboardingSession, clearOnboardingSession, onboardingDispatch }}>
      {children}
    </OnboardingSessionContext.Provider>
  )
}
