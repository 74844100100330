import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { PaymentRequest, SessionReducerAction, SessionReducerActionType } from '../../utils/interfaces'

const addPaymentTemplate = async (dispatch: React.Dispatch<SessionReducerAction>, template: PaymentRequest) => {
  return new Promise((resolve) => {
    const newTemplate = {
      ...template,
      id: `template-${dayjs().valueOf()}`,
      last_modified_date: dayjs().valueOf(),
      last_modified_by: 'admin',
      created_by: 'admin',
    }
    dispatch({
      type: SessionReducerActionType.ADD_PAYMENT_TEMPLATE,
      payload: newTemplate,
    })
    resolve(newTemplate)
  })
}

export default function useAddPaymentTemplate(onSuccess: () => void) {
  const { dispatch } = useContext(SessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (template: PaymentRequest) => addPaymentTemplate(dispatch, template),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['payment-templates'] })
      onSuccess()
    },
  })
}
