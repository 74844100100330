import { createStyles, getSize, getStylesRef } from '@mantine/core';
import { setTransparency } from '../../../utils/theme';
import { IconButtonProps } from './IconButton';
import { BUTTON_INPUT_SIZES } from '../buttonSizes';

export default createStyles((theme, params: IconButtonProps) => {
  const color = theme.fn.themeColor(params.color as string)
  const textColor = theme.fn.themeColor(params.textColor || params.color as string)
  const leftIconColor = theme.fn.themeColor(params.leftIconColor || params.textColor || params.color as string)
  const rightIconColor = theme.fn.themeColor(params.rightIconColor || params.textColor || params.color as string)

  const iconWidth = `calc(${(BUTTON_INPUT_SIZES as Record<string, string>)[params.size || 'md']} + 0.125rem)`;

  return {
    root: {
      '*,*::before': {
        boxSizing: 'border-box',
      },

      height: iconWidth,
      border: 'none',
      paddingLeft: params.leftIcon || (params.rightIcon && !params.children) ? '0' : '5pt',
      paddingRight: params.rightIcon || (params.leftIcon && !params.children) ? '0' : '5pt',

      color: textColor,

      ':hover': {
        backgroundColor: 'transparent',
        // boxShadow: `0 0 0.375rem ${color}`,

        [`.${getStylesRef('label')}`]: {
          color: theme.fn.lighten(textColor, 0.1),
          '.stream-icon-button-description': {
            color: theme.fn.darken(textColor, 0.1),
          },
        },
        [`.${getStylesRef('leftIcon')}`]: {
          color: theme.fn.lighten(leftIconColor, 0.1),
        },
        [`.${getStylesRef('rightIcon')}`]: {
          color: theme.fn.lighten(rightIconColor, 0.1),
        },
      },
      ':active': {
        // boxShadow: `0 0 0.375rem transparent`,

        [`.${getStylesRef('label')}`]: {
          color: theme.fn.darken(textColor, 0.1),
          '.stream-icon-button-description': {
            color: theme.fn.darken(textColor, 0.3),
          },
        },
        [`.${getStylesRef('leftIcon')}`]: {
          color: theme.fn.darken(leftIconColor, 0.1),
        },
        [`.${getStylesRef('rightIcon')}`]: {
          color: theme.fn.darken(rightIconColor, 0.1),
        },
      },

      /* Border Animation */
      [`.${getStylesRef('icon')}`]: {
        transition: 'box-shadow 0.1s ease 0s',
        '::before': {
          transition: params.animation === 'fancy' ? 'width 0.25s ease-in-out, box-shadow 0.1s ease 0.2s, ' + params.animation
            : 'box-shadow 0.1s ease 0s',
          content: '""',
          position: 'absolute',
          top: 0,
          left: params.leftIcon ? 0 : undefined,
          right: params.rightIcon ? 0 : undefined,
          bottom: 0,
          width: params.variant === 'borderless' ? 0 : iconWidth,
          borderWidth: params.variant === 'borderless' ? '0 0 2pt 0' : '2pt',
          borderStyle: 'solid',
          borderRadius: params.variant === 'borderless' ? 0
            : theme.fn.radius(params.radius || 'sm'),
          borderColor: setTransparency(theme, params.color, params.loading ? 0.5 : 1)
        },
      },
      [`:hover .${getStylesRef('icon')}`]: {
        '::before': {
          boxShadow: params.variant === 'borderless' ? undefined
            : params.animation === 'fancy' ? `0 0 0.375rem ${color}`
              : `0 0 0.25rem ${color}`,
          borderColor: setTransparency(theme, params.color, params.loading ? 0.5 : 1),
        },
      },
      [`:active .${getStylesRef('icon')}`]: {
        '::before': {
          transitionDelay: '0s',
          boxShadow: params.variant === 'borderless' ? undefined : `0 0 0.375rem transparent`,
          borderColor: theme.fn.darken(color, 0.1)
        },
      },
      [`:where(:hover, :active) .${getStylesRef('icon')}`]: {
        '::before': {
          width: params.animation === 'fancy' || params.variant === 'borderless' ? '100%'
            : undefined,
          borderWidth: params.variant === 'borderless' ? '0 0 2pt 0' : '2pt',
        },
      },
      ':where(&:disabled, &[data-disabled])': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
        [`.${getStylesRef('label')}, .${getStylesRef('label')} .stream-icon-button-description, .${getStylesRef('leftIcon')}, .${getStylesRef('rightIcon')}`]: {
          color: theme.colors.gray[5],
        },
        [`.${getStylesRef('icon')}`]: {
          transition: 'none',
          boxShadow: `0 0 0.375rem transparent`,
          // color: params.variant === 'outline' ? theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4] : theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[5],
          // borderColor: params.variant === 'outline' ? theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4] : undefined,

          '::before': {
            transition: 'none',
            borderColor: 'transparent',
          }
        },
        ':hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
          [`.${getStylesRef('label')}, .${getStylesRef('label')} .stream-icon-button-description, .${getStylesRef('leftIcon')}, .${getStylesRef('rightIcon')}`]: {
            color: theme.colors.gray[5],
          },
          [`.${getStylesRef('icon')}`]: {
            '::before': {
              boxShadow: `0 0 0.375rem transparent`,
              borderColor: 'transparent',
            }
          },
        },
      },
    },
    icon: {
      ref: getStylesRef('icon'),
      padding: '5pt',
      aspectRatio: '1/1',
      height: '100%',
      justifyContent: 'center',
    },
    label: {
      ref: getStylesRef('label'),
      display: params.children !== undefined && params.children !== '' ? 'grid' : 'none',
      justifyItems: params.leftIcon ? 'start' : 'end',
      alignContent: 'center',
      color: params.textColor !== undefined ? textColor : undefined, // do not set params.color as default here
      fontWeight: 500,
      lineHeight: 1.25,
      '.stream-icon-button-title': {
        lineHeight: 1.25,
      },
      '.stream-icon-button-description': {
        lineHeight: 1.25,
        fontWeight: 400,
        fontSize: `calc(${getSize({
          size: params.size || 'md',
          sizes: theme.fontSizes,
        })} * 0.75)`,
        color: theme.fn.darken(
          textColor,
          0.2
        ),
      },
    },
    inner: {
      justifyContent: params.leftIcon ? 'start' : 'end'
    },
    leftIcon: {
      ref: getStylesRef('leftIcon'),
      marginRight: params.children ? params.variant === 'borderless' ? 0 : '5pt' : 0,
      color: leftIconColor,
      svg: {
        fill: 'currentcolor',
      },
    },
    rightIcon: {
      ref: getStylesRef('rightIcon'),
      marginLeft: params.children ? params.variant === 'borderless' ? 0 : '5pt' : 0,
      color: rightIconColor,
      svg: {
        fill: 'currentcolor',
      },
    },
  };
});
