import { Box, Flex, MediaQuery, Paper, Title } from '@mantine/core'
import { ReactElement, ReactNode } from 'react'
import { IconPeople } from '../icons'
import { Container } from './Container'
import { colorAliases } from '../../theme/getMantineTheme'

type AppHeaderProps = {
  title?: ReactNode
  description: ReactNode
  icon?: ReactElement
  iconOnClick?: () => void
  buttons?: ReactElement | ReactElement[]
}

const getIconStyles = () => ({
  c: 'white',
  bg: 'secondary',
  w: '2.875rem',
  h: '2.875rem',
})

const AppHeader: React.FC<AppHeaderProps> = ({ title, description, icon, iconOnClick, buttons = [] }) => {
  return (
    <Box pos='relative' h='5.5rem' sx={{ zIndex: 2 }}>
      <Paper pos='fixed' left={0} right={0} h='5.5rem' radius={0}>
        <Container display='flex' h='100%' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Flex gap='sm' justify='flex-start' align='center' direction='row' w='100%'>
            {icon ? (
              <Flex
                justify='center'
                align='center'
                {...getIconStyles()}
                sx={{ borderRadius: '50%' }}
                pt='0.15rem'
                onClick={iconOnClick}
              >
                {icon}
              </Flex>
            ) : (
              <Flex
                {...getIconStyles()}
                p='0.65rem 0.2rem 0.65rem 0.75rem'
                sx={{ borderRadius: '50%' }}
                onClick={iconOnClick}
              >
                <IconPeople style={{ height: '100%' }} />
              </Flex>
            )}
            <Box>
              <Title order={2} color='dark' ff='Roboto' weight={600} fz='lg' lh={1.2} mt='0.2rem'>
                {title || ''}
              </Title>
              <Title order={2} color={colorAliases.textInvertSecondary} fw={400} fz='sm' lh={1.5} lineClamp={1}>
                {description}
              </Title>
            </Box>
          </Flex>
          <MediaQuery smallerThan='desktop' styles={{ display: 'none' }}>
            <Flex gap='2xs'>{buttons}</Flex>
          </MediaQuery>
        </Container>
      </Paper>
    </Box>
  )
}

export default AppHeader
